import { GEM_LACK_TRIGGER } from 'src/types/gemLack';
import { Platform } from 'src/types/Platform';
import { MakePropertyNonNullable } from 'src/types/utils';

export interface ShopEntryBanner {
  id: number;
  bannerText: string;
  exposureTimeSeconds: number;
  exposureRepeatCount: number;
  exposureLimitCount: number;
  iconUrl: string;
  backgroundColor: string;
  textColor: string;
  deeplink: string;
  excludeItemCategoryId: string | null;
  endAt: number | null;

  /**
   * 타입에 상응하는 특수한 로직을 필요로 하는 범용적이지 않은 유형의 배너에 적용
   */
  type: string | null;
}

export enum ShopEntryBannerType {
  StarterPackage = 'WEB_STARTER_PACKAGE',
}

export interface StarterPackageShopEntryBanner
  extends MakePropertyNonNullable<ShopEntryBanner, 'endAt'> {
  type: ShopEntryBannerType.StarterPackage;
}

export const isStarterPackageShopEntryBanner = (
  shopEntryBanner: ShopEntryBanner
): shopEntryBanner is StarterPackageShopEntryBanner => {
  return shopEntryBanner.type === ShopEntryBannerType.StarterPackage;
};

export const SHOP_ORIGIN = {
  ...GEM_LACK_TRIGGER,
  gemShop: 'GEM_SHOP',
  vipRating: 'VIP_RATING',
  starterPackageNudge: 'STARTER_PACKAGE_NUDGE',
} as const;

export type ShopOrigin = (typeof SHOP_ORIGIN)[keyof typeof SHOP_ORIGIN];

export enum ShopCategory {
  Normal = 'normal',
  Live = 'live',
}

export interface GetShopEntryBannerRequest {
  platform: Platform;
  localeLanguageCode?: string;
  localeCountryCode?: string;
}

export type GetShopEntryBannerResponse = ShopEntryBanner;
