import { useEffect, useState } from 'react';

import differenceInSeconds from 'date-fns/differenceInSeconds';

import { useSynchronizedInterval } from 'src/hooks/useSynchronizedInterval';
import { millisecondsToHHMMSS } from 'src/utils/date';

const useRemainingTimer = (endDate: Date) => {
  const getRemainingSeconds = () => {
    return Math.max(differenceInSeconds(endDate, Date.now(), { roundingMethod: 'ceil' }), 0);
  };

  const [remainingSeconds, setRemainingSeconds] = useState(getRemainingSeconds());
  const [isTimerOver, setIsTimeOver] = useState(false);

  const { start, stop } = useSynchronizedInterval(() => {
    const updatedRemainingSeconds = getRemainingSeconds();

    if (updatedRemainingSeconds === 0) {
      setIsTimeOver(true);
    }

    setRemainingSeconds(updatedRemainingSeconds);
  });

  useEffect(() => {
    if (isTimerOver) {
      stop();
    }
  }, [isTimerOver, stop]);

  return {
    start,
    stop,
    remainingTime: {
      seconds: remainingSeconds,
      text: millisecondsToHHMMSS(remainingSeconds * 1000),
    },
    isTimerOver,
  };
};

export default useRemainingTimer;
