import { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import useIsomorphicLayoutEffect from 'src/hooks/useIsomorphicLayoutEffect';
const Container = styled.div<{
  maxLines: number;
  lineHeight?: number;
  isShow: boolean;
}>`
  max-width: 100%;
  ${({
  maxLines,
  lineHeight
}) => !!lineHeight && `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${maxLines};
    max-height: ${lineHeight * maxLines}px;
  `}
  opacity: ${({
  isShow
}) => isShow ? 1 : 0};
`;
interface IResizingText extends React.HTMLAttributes<HTMLDivElement> {
  minFontSize: number;
  maxLines?: number;
}

// maxLines 초과했을 때 minFontSize보다 큰 범위 내에서 -1 해보면서 maxLines로 만들 수 있는지 체크하고 안되면 ellipsis 적용하는 컴포넌트
const ResizingText: FC<IResizingText> = ({
  minFontSize,
  maxLines = 1,
  children,
  ...props
}) => {
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [isShow, setIsShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useIsomorphicLayoutEffect(() => {
    const target = ref.current;
    if (!target) return;
    setFontSize(undefined);
    setLineHeight(undefined);
    try {
      const observerCallback: ResizeObserverCallback = entries => {
        // requestAnimationFrame: ResizeObserver - loop limit exceeded 피하기 위해
        // ref: https://stackoverflow.com/a/58701523
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          const entry = entries[0];
          const computedStyle = getComputedStyle(entry.target);
          const parsedFontSize = parseFloat(computedStyle.fontSize);
          const currentLineHeight = computedStyle.lineHeight;
          /**
           * 데스크톱 브라우저의 기본 line-height는 normal이고 normal은 1.2배 비율로 처리됨
           * Depends on the user agent. Desktop browsers (including Firefox)
           * use a default value of roughly 1.2, depending on the element's font-family.
           * https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#normal
           */
          const fontRatio = 1.2;
          let computedLineHeight = 0;
          if (currentLineHeight === 'normal') {
            computedLineHeight = parsedFontSize * fontRatio;
          } else if (currentLineHeight.endsWith('%')) {
            const percentage = parseFloat(currentLineHeight) / 100;
            computedLineHeight = parsedFontSize * percentage;
          } else {
            computedLineHeight = parseFloat(currentLineHeight);
          }
          if (entry.contentRect.height / computedLineHeight <= maxLines && entry.contentRect.width <= target.getBoundingClientRect().width) {
            // text가 container의 영역을 넘어가지 않는 경우
            return setIsShow(true);
          }
          if (parsedFontSize <= minFontSize) {
            // minFontSize와 같아지면 더이상 fontSize를 줄이지 않고 ellipsis으로 처리
            // resizing이 끝나면 렌더링
            setIsShow(true);
            return;
          }
          // minFontSize보다 아직 크면 -1씩 줄여보기
          setFontSize(parsedFontSize - 1);
          // 폰트 size가 바뀌어도 lineHeight가 변경이 안되기 때문에 resize가 발생하지 않음
          setLineHeight(computedLineHeight);
        });
      };
      const observer = new ResizeObserver(observerCallback);
      observer.observe(target);
      return () => {
        observer.disconnect();
      };
    } catch (e) {
      // sentry error 집계 무시
      // ref: https://stackoverflow.com/a/50387233
    }
  }, [minFontSize, maxLines, children]);
  return <Container ref={ref} lineHeight={lineHeight} maxLines={maxLines} style={{
    fontSize,
    lineHeight: `${lineHeight}px`
  }} isShow={isShow} {...props} data-sentry-element="Container" data-sentry-component="ResizingText" data-sentry-source-file="index.tsx">
      {children}
    </Container>;
};
export default ResizingText;