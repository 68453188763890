import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import LoginModal from 'src/components/AzarOnly/Login';
import Button from 'src/components/Button';
import RecentMatchCounter from 'src/components/Counter/RecentMatchCounter';
import Gap from 'src/components/Gap';
import ModalLayoutBase, { MobileModalType, ModalCloseButton, ModalContent as ModalContentBase, ModalHeader } from 'src/components/ModalLayout';
import OptimizeImage from 'src/components/OptimizeImage';
import ResizingText from 'src/components/ResizingText';
import useEvent from 'src/hooks/useEvent';
import useIsRtl from 'src/hooks/useIsRtl';
import { hasInduceRegisterBeenShownAtom } from 'src/stores/auth/atoms';
import { EventParams } from 'src/stores/event/atoms';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_LOGIN_MODAL_VISITOR_TRIGGER, EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalNavButtonType, ModalType } from 'src/types/Modal';
const ModalLayout = styled(ModalLayoutBase)`
  min-height: 593px;
  max-width: 400px;
  background-color: ${({
  theme
}) => theme.color.bg2};

  ${({
  theme
}) => theme.screenSize.mobile} {
    width: calc(100% - 60px);
    height: auto;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }
`;
const ModalContent = styled(ModalContentBase)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  padding: 209px 40px 32px 40px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    align-items: center;
    padding-top: 0px;
  }
`;
const CloseButton = styled(ModalCloseButton)`
  display: flex;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 16px;
  ${({
  theme
}) => theme.isRtl ? 'right' : 'left'}: auto;
`;
const ModalTitle = styled.p`
  color: ${({
  theme
}) => theme.color.contentDeep};
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 12px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    text-align: center;
  }
`;
const ModalDescription = styled.p`
  color: ${({
  theme
}) => theme.color.content11};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    display: none;
  }
`;
const MatchCounterWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    justify-content: center;
    margin-bottom: 20px;
  }
`;
const MatchCounterContainer = styled(ResizingText)`
  display: flex !important;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: ${({
  theme
}) => theme.color.contentDeep};

  ${({
  theme
}) => theme.screenSize.breakpointUp(360)} {
    justify-content: center;
    font-size: 15px !important;
  }
`;
const MatchCounter = styled(RecentMatchCounter)``;
const CounterDesc = styled.p`
  word-break: break-word;
`;
const CounterDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({
  theme
}) => theme.color.accentH1};
  border-radius: 4px;
`;
const DesktopImageWrap = styled.div`
  position: absolute;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 59px;
  transform: ${({
  theme
}) => theme.isRtl ? 'rotate(8deg)' : 'rotate(-8deg)'};
  transform-origin: ${({
  theme
}) => theme.isRtl ? 'right' : 'left'};

  ${({
  theme
}) => theme.screenSize.mobile} {
    display: none;
  }
`;
const MobileImageWrap = styled.div`
  flex: 1;
  display: none;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${({
  theme
}) => theme.screenSize.mobile} {
    display: flex;
  }
`;
const MobileImage = styled(OptimizeImage)`
  height: 100%;
  width: auto;
  transform: ${({
  theme
}) => theme.isRtl ? 'translateX(-6%)' : 'translateX(6%)'};
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${({
  theme
}) => theme.screenSize.mobile} {
    flex-direction: column-reverse;
  }
`;
interface Props {
  eventParams: EventParams['eventParams'];
}
const InduceRegisterModal = ({
  eventParams
}: Props) => {
  const {
    t
  } = useTranslation();
  const isRtl = useIsRtl();
  const closeModal = useSetAtom(closeModalAtom);
  const showModal = useSetAtom(showModalAtom);
  const pushEvent = useEvent();
  const setHasInduceRegisterBeenShown = useSetAtom(hasInduceRegisterBeenShownAtom);
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.POPUP,
      eventName: EVENT_NAME.ANYWHERE_IMPRESSION_BOUNCE_POPUP,
      eventParams: {
        ...eventParams,
        action_category: 'impression',
        tab: 'anywhere',
        page: 'anywhere',
        target: 'bounce_popup'
      }
    });
  }, [pushEvent, eventParams]);
  useEffect(() => {
    setHasInduceRegisterBeenShown(true);
  }, [setHasInduceRegisterBeenShown]);
  const handleClose = () => {
    closeModal(ModalType.INDUCE_REGISTER_MODAL);
  };
  const handleShowLoginModal = () => {
    handleClose();
    pushEvent({
      eventType: EVENT_TYPE.BUTTON,
      eventName: EVENT_NAME.ANYWHERE_CLICK_BOUNCE_POPUP_BUTTON,
      eventParams: {
        ...eventParams,
        action_category: 'click',
        tab: 'anywhere',
        page: 'anywhere',
        target: 'bounce_popup_button'
      }
    });
    showModal({
      key: ModalType.LOGIN,
      component: () => LoginModal({
        trigger: EVENT_LOGIN_MODAL_VISITOR_TRIGGER.BOUNCE_POPUP
      })
    });
  };
  return <ModalLayout isDim closeWhenClickOutside={false} mobileType={MobileModalType.SCREEN_MODAL} navButtonType={ModalNavButtonType.NONE} // 별도 디자인을 위해 직접 닫기 버튼 추가
  mobileNavButtonType={ModalNavButtonType.NONE} onClose={handleClose} data-sentry-element="ModalLayout" data-sentry-component="InduceRegisterModal" data-sentry-source-file="index.tsx">
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="index.tsx">
        <CloseButton onClick={handleClose} data-sentry-element="CloseButton" data-sentry-source-file="index.tsx" />
      </ModalHeader>
      <DesktopImageWrap data-sentry-element="DesktopImageWrap" data-sentry-source-file="index.tsx">
        <OptimizeImage width={400} height={255} alt='Start Free 1:1 Video Chat' src={`/images/modal/bounce-rate/bounce-rate-desktop${isRtl ? '-rtl' : ''}.png`} sizes='(max-width: 1280px) 400px, 100vw' data-sentry-element="OptimizeImage" data-sentry-source-file="index.tsx" />
      </DesktopImageWrap>
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="index.tsx">
        <ContentWrap data-sentry-element="ContentWrap" data-sentry-source-file="index.tsx">
          <MatchCounterWrap data-sentry-element="MatchCounterWrap" data-sentry-source-file="index.tsx">
            <CounterDot data-sentry-element="CounterDot" data-sentry-source-file="index.tsx" />
            <Gap width={6} data-sentry-element="Gap" data-sentry-source-file="index.tsx" />
            <MatchCounterContainer minFontSize={10} maxLines={1} data-sentry-element="MatchCounterContainer" data-sentry-source-file="index.tsx">
              <MatchCounter data-sentry-element="MatchCounter" data-sentry-source-file="index.tsx" />
              <Gap width={4} data-sentry-element="Gap" data-sentry-source-file="index.tsx" />
              <CounterDesc data-sentry-element="CounterDesc" data-sentry-source-file="index.tsx">{t('online__matching_now')}</CounterDesc>
            </MatchCounterContainer>
          </MatchCounterWrap>
          <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="index.tsx">{t('popup_join_now_title')}</ModalTitle>
        </ContentWrap>
        <ModalDescription data-sentry-element="ModalDescription" data-sentry-source-file="index.tsx">{t('popup_join_now_description')}</ModalDescription>
        <MobileImageWrap data-sentry-element="MobileImageWrap" data-sentry-source-file="index.tsx">
          <MobileImage width={157} height={284} alt='Start Free 1:1 Video Chat' src={`/images/modal/bounce-rate/bounce-rate-mobile${isRtl ? '-rtl' : ''}.png`} sizes='(max-width: 480px) 157px, 100vw' data-sentry-element="MobileImage" data-sentry-source-file="index.tsx" />
        </MobileImageWrap>
        <Button variant='contained' onClick={handleShowLoginModal} id='bounce-rate-modal-join-now' data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {t('popup_join_now_cta')}
        </Button>
      </ModalContent>
    </ModalLayout>;
};
export default InduceRegisterModal;