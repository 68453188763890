import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { v4 } from 'uuid';

import { isUseKeepAliveAtom } from 'src/network/atoms';
import { userDataAtom } from 'src/stores/auth/atoms';
import { UTMParamsAtom } from 'src/stores/utm/atoms';
import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import {
  EVENT_API_URL,
  EVENT_TYPE,
  EventName,
  EventOption,
  EventType,
  SERVICE_CODE,
} from 'src/types/Event';
import getDeviceId from 'src/utils/device/id';
import getDeviceInfo from 'src/utils/device/info';

export type EventParams<T = object> = {
  eventType: EventType;
  eventName: EventName | string;
  eventParams?: T;
  options?: EventOption;
};

export interface EventLog<T = object> extends EventParams<T> {
  date: string;
}

const EVENT_ENDPOINT_MAP: Partial<Record<EventType, string>> = {
  /** Payment 관련 이벤트는 전용 엔드포인트로 전송해야 함.
   * @see {@link https://www.notion.so/hpcnt/API-8b5dbd04bc2f4601863a40abb0c5e460?pvs=4#32cd2c7091014968983c9cf43bfc2185}
   */
  [EVENT_TYPE.PAYMENT]: '/payment',
};

let eventErrorCount = 0;

export const eventLogsAtom = atom<EventLog[]>([]);

export const isPwaAtom = atom(false);

export const eventMutateAtom = atom(null, async (get, set, params: EventParams) => {
  // TODO: isPayment 임시로 endpoint 분기 추후 통합예정
  const { eventType, eventName, eventParams, options } = params;
  const isPwa = get(isPwaAtom);
  const addedIsPwa = { ...eventParams, ...(isPwa ? { isPwa } : {}) };

  const { browserName, browserVersion, isMobile, os } = getDeviceInfo();

  const ABConfig = {
    ...(get(visitorConfigAtom) || {}),
    // enableWebCameraOff: get(remoteConfigAtom)?.enableWebCameraOff,
  };

  const endpoint = EVENT_ENDPOINT_MAP[eventType] ?? '';

  window.dataLayer?.push({
    event: eventName,
    eventType,
    eventParams: {
      ...addedIsPwa,
      device_id: getDeviceId(),
      os,
      isMobile,
      ABConfig,
    },
    options: { ...options, device_id: getDeviceId() },
    url: endpoint,
  });

  if (browserName === 'safari' && browserVersion === '14.0.3') {
    //https://hpcnt.slack.com/archives/C02PLFTA5DL/p1666321514312989
    return;
  }

  const payload = {
    event_type: eventType,
    event_name: eventName,
    event_timestamp: new Date().getTime(),
    event_uid: v4(),
    device_params: {
      os: typeof window !== 'undefined' && window.AzarJs ? 'WEB_VIEW' : 'WEB',
    },
    user_id: get(userDataAtom)?.userId || '',
    event_params: {
      path: window.location.pathname,
      unique_user_key: getDeviceId(),
      ABConfig,
      ...(typeof addedIsPwa === 'object' ? addedIsPwa : {}),
      ...getDeviceInfo(),
    },
    service_code: SERVICE_CODE.AZAR,
    unique_user_key: getDeviceId(),
    utm_params: { ...get(UTMParamsAtom) },
    ...options,
  } as const;

  return fetch(`${EVENT_API_URL}${endpoint}`, {
    method: 'post',
    keepalive: get(isUseKeepAliveAtom),
    credentials: 'omit',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'x.hpcnt.service.code': SERVICE_CODE.AZAR,
    },
  })
    .then((response) => {
      // cache hit 고려
      if (response.ok || response.status === 304) {
        set(
          eventLogsAtom,
          [{ ...params, date: new Date().toISOString() }].concat(get(eventLogsAtom)).slice(0, 100)
        );
        eventErrorCount = 0;

        return Promise.resolve();
      }

      return Promise.reject(response);
    })
    .catch((error: unknown) => {
      if (eventErrorCount < 3) {
        Sentry.captureException(error);
        eventErrorCount++;
      }
    });
});
