import React, { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
const TextLogoCss = ({
  theme,
  color
}: {
  theme: Theme;
  color?: string;
}) => css`
  fill: ${color || theme.color.contentDeep};
`;
const TextLogoNormal = styled(dynamic(() => import('src/assets/images/textLogo.svg')), {
  shouldForwardProp: prop => isPropValid(prop)
})<Pick<ITextLogo, 'width' | 'height' | 'color'>>`
  ${TextLogoCss}
  width: ${({
  width
}) => width}px;
  height: ${({
  height
}) => height}px;
`;
const TextLogoAr = styled(dynamic(() => import('src/assets/images/textLogoAr.svg')), {
  shouldForwardProp: prop => isPropValid(prop)
})<Pick<ITextLogo, 'arWidth' | 'arHeight' | 'color'>>`
  ${TextLogoCss}
  width: ${({
  arWidth
}) => arWidth}px;
  height: ${({
  arHeight
}) => arHeight}px;
`;
const TextLogoPt = styled(dynamic(() => import('src/assets/images/textLogoPt.svg')), {
  shouldForwardProp: prop => isPropValid(prop)
})<Pick<ITextLogo, 'ptWidth' | 'ptHeight' | 'color'>>`
  ${TextLogoCss}
  width: ${({
  ptWidth
}) => ptWidth}px;
  height: ${({
  ptHeight
}) => ptHeight}px;
`;
interface ITextLogo {
  width: number;
  height: number;
  arWidth: number;
  arHeight: number;
  ptWidth: number;
  ptHeight: number;
  color?: string;
  className?: string;
}
const TextLogo: FC<ITextLogo> = ({
  width,
  height,
  ...props
}) => {
  const {
    i18n: {
      language
    }
  } = useTranslation();
  switch (language) {
    case 'ar':
      return <TextLogoAr {...props} />;
    case 'pt':
      return <TextLogoPt {...props} />;
    default:
      return <TextLogoNormal width={width} height={height} {...props} />;
  }
};
export default React.memo(TextLogo);