import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import useLogin from 'src/hooks/useLogin';
import useQueryWithAuth from 'src/hooks/useQueryWithAuth';
import { useRestMutation } from 'src/hooks/useRestMutation';
import {
  GetPreferredLanguagesResponse,
  ProfileApiList,
  UpdatePreferredLanguagesRequest,
  UpdatePreferredLanguagesResponse,
} from 'src/stores/profile/apis';

export const PREFERRED_LANGUAGE_QUERY_KEYS = {
  getPreferredLanguages: () => {
    return [ProfileApiList.PreferredLanguages];
  },
};

export const useGetPreferredLanguagesQuery = () => {
  const { isLogin } = useLogin();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLogin) {
      queryClient.invalidateQueries(PREFERRED_LANGUAGE_QUERY_KEYS.getPreferredLanguages());
    }
  }, [isLogin, queryClient]);

  return useQueryWithAuth<void, GetPreferredLanguagesResponse, void>({
    path: ProfileApiList.PreferredLanguages,
    queryKey: PREFERRED_LANGUAGE_QUERY_KEYS.getPreferredLanguages(),
    options: {
      // 로딩 UI 최소화 및 데이터 보고 시점에 비어있는 경우 방지
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  });
};

export const useUpdatePreferredLanguagesQuery = () => {
  const queryClient = useQueryClient();

  return useRestMutation<UpdatePreferredLanguagesRequest, UpdatePreferredLanguagesResponse, void>({
    path: ProfileApiList.PreferredLanguages,
    method: 'put',
    options: {
      onSuccess: (data) => {
        queryClient.setQueryData(PREFERRED_LANGUAGE_QUERY_KEYS.getPreferredLanguages(), data);
      },
    },
  });
};
