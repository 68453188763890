const solid = {
  solidBlack: { light: '#000000', dark: '#FFFFFF' },
  solidWhite: { light: '#FFFFFF', dark: '#000000' },
  solidBlackBg: { light: '#111111', dark: '#FFFFFF' },
  solidWhiteBg: { light: '#FFFFFF', dark: '#111111' },
  solidBlackModal: { light: '#222222', dark: '#FFFFFF' },
  solidWhiteModal: { light: '#FFFFFF', dark: '#222222' },
  solidBlackFixed: { light: '#000000', dark: '#000000' },
  solidWhiteFixed: { light: '#FFFFFF', dark: '#FFFFFF' },
};

const gray = {
  gray30: { light: '#F8F8F8', dark: '#111111' },
  gray60: { light: '#F4F4F4', dark: '#222222' },
  gray80: { light: '#F9F9F9', dark: '#2A2A2A' },
  gray100: { light: '#EEEEEE', dark: '#333333' },
  gray200: { light: '#DDDDDD', dark: '#444444' },
  gray300: { light: '#CCCCCC', dark: '#555555' },
  gray300Fixed: { light: '#CCCCCC', dark: '#CCCCCC' },
  gray400: { light: '#BBBBBB', dark: '#666666' },
  gray500: { light: '#AAAAAA', dark: '#888888' },
  gray600: { light: '#888888', dark: '#AAAAAA' },
  gray700: { light: '#666666', dark: '#CCCCCC' },
  gray800: { light: '#444444', dark: '#EEEEEE' },
  gray900: { light: '#222222', dark: '#F8F8F8' },
  gray900Fixed: { light: '#222222', dark: '#222222' },
};

const green = {
  green50: '#E5FEF5',
  green100: '#B9FDE4',
  green200: '#89FCD0',
  green300: '#5EFCBD',
  green400: '#2EFCAC',
  green500: '#00F69D',
  green600: '#09D087',
  green700: '#10A971',
  green800: '#12835A',
  green850: '#175E45',
  green900: '#1E382E',
};

const red = {
  red50: '#FDEBED',
  red100: '#FBD7DC',
  red200: '#F7B0B9',
  red300: '#F48996',
  red400: '#F06272',
  red500: '#ED3B50',
  red600: '#BA3242',
  red700: '#8A2733',
  red800: '#5A1B23',
  red850: '#42161C',
  red900: '#291114',
};

const orange = {
  orange50: '#FEF1EC',
  orange100: '#FDE3DA',
  orange200: '#FCC7B5',
  orange300: '#FAAC90',
  orange400: '#F9906A',
  orange500: '#F87546',
  orange600: '#C45E3A',
  orange700: '#90482E',
  orange800: '#5E3021',
  orange850: '#44261B',
  orange900: '#2B1A14',
};

const yellow = {
  yellow50: '#FEF9E8',
  yellow100: '#FDF3D2',
  yellow200: '#FBE8A5',
  yellow300: '#F9DC78',
  yellow400: '#F7D14A',
  yellow500: '#F6C61E',
  yellow600: '#E3AA00',
  yellow700: '#B68000',
  yellow800: '#7F5400',
  yellow850: '#4C3300',
  yellow900: '#261E00',
};

const blue = {
  blue50: '#EBF2FD',
  blue100: '#D8E5FB',
  blue200: '#B2CCF7',
  blue300: '#8CB2F4',
  blue400: '#6699F0',
  blue500: '#4080ED',
  blue600: '#3566BB',
  blue700: '#294D8B',
  blue800: '#1E3459',
  blue850: '#182742',
  blue900: '#121B29',
};

const indigo = {
  indigo50: '#EDEEFC',
  indigo100: '#DCDDFA',
  indigo200: '#B9BBF6',
  indigo300: '#9799F1',
  indigo400: '#7477ED',
  indigo500: '#5256E9',
  indigo600: '#4447B7',
  indigo700: '#353687',
  indigo800: '#252758',
  indigo850: '#1F1E3F',
  indigo900: '#161728',
};

const purple = {
  purple50: '#F4EDFD',
  purple100: '#E9DBFB',
  purple200: '#D4B7F7',
  purple300: '#BF93F4',
  purple400: '#AA6EF0',
  purple500: '#954BED',
  purple600: '#773EBB',
  purple700: '#59318A',
  purple800: '#3B2359',
  purple850: '#2D1B42',
  purple900: '#1E1529',
};

const pink = {
  pink50: '#FEEDF3',
  pink100: '#FDDCE8',
  pink200: '#FBAD1',
  pink300: '#F998BB',
  pink400: '#F776A4',
  pink500: '#F5548E',
  pink600: '#C14672',
  pink700: '#8F3656',
  pink800: '#5D2639',
  pink850: '#431F2C',
  pink900: '#2B161E',
};

const azarReferenceColor = {
  ...solid,
  ...gray,
  ...green,
  ...red,
  ...orange,
  ...yellow,
  ...blue,
  ...indigo,
  ...purple,
  ...pink,
} as const;

export default azarReferenceColor;
