import { useSetAtom } from 'jotai';

import useGemAmount from 'src/hooks/useGemAmount';
import {
  getGemLackTriggersFromMatchFiltersAtom,
  getRequiredGemFromMatchFilterAtom,
} from 'src/stores/match/atoms';
import { GemLackTrigger } from 'src/types/gemLack';
import { MatchFilterOption, MatchFilterSelection } from 'src/types/Match';

const useMatchPreference = () => {
  const getRequiredGemFromMatchFilter = useSetAtom(getRequiredGemFromMatchFilterAtom);
  const getGemLackTriggersFromMatchFilters = useSetAtom(getGemLackTriggersFromMatchFiltersAtom);
  const gemAmount = useGemAmount() || 0;

  const tryApplyFilterOption = ({
    filterOption,
    targetMatchFilterSelections,
    callbackIfApplicable,
    callbackIfGemLack,
  }: {
    filterOption: MatchFilterOption;
    targetMatchFilterSelections: MatchFilterSelection[];
    callbackIfApplicable: () => void;
    callbackIfGemLack: (lackingAmount: number, gemLackTriggers: GemLackTrigger[]) => void;
  }) => {
    /**
     * 해당 옵션 자체가 무료인 경우 무조건 적용
     * 해당 로직 제거 시 기존에 적용한 다른 유료 옵션으로 인해 젬이 부족한 경우 무료 옵션임에도 선택 불가능한 이슈 발생
     */
    if (filterOption.gemCost === 0) {
      callbackIfApplicable();
      return;
    }

    const requiredGemsForMatch = getRequiredGemFromMatchFilter(targetMatchFilterSelections);
    const lackingAmount = requiredGemsForMatch - gemAmount;

    if (lackingAmount <= 0) {
      callbackIfApplicable();
    } else {
      const gemLackTriggers = getGemLackTriggersFromMatchFilters({
        matchFilterSelections: targetMatchFilterSelections,
      });
      callbackIfGemLack(lackingAmount, gemLackTriggers);
    }
  };

  return {
    tryApplyFilterOption,
  };
};

export default useMatchPreference;
