import { FC } from 'react';
import styled from '@emotion/styled';
import IcLoadingBase from 'src/assets/images/icLoadingBold.svg';
import { rotation } from 'src/styles/animation';
const IcLoading = styled(IcLoadingBase)`
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  animation: ${rotation} 1s ease-in-out infinite;
  color: white;
`;
const LoadingIndicator: FC<{
  size: number;
}> = ({
  size,
  ...props
}) => <IcLoading width={size} height={size} {...props} data-sentry-element="IcLoading" data-sentry-component="LoadingIndicator" data-sentry-source-file="index.tsx" />;
export default LoadingIndicator;