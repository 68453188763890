export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const getCanvasCaptureBlob = (canvas: HTMLCanvasElement) => {
  const imageDataUrl = canvas.toDataURL('image/png');
  return dataURItoBlob(imageDataUrl);
};

/**
 * 주어진 이미지 Blob이 비어있는지 여부를 판단
 * @returns True if image is all filled transparent or black/white pixels, otherwise false
 * @throws if given image load fails
 */
export const isImageFileEmpty = async (imageBlob: Blob) => {
  return new Promise<boolean>((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(imageBlob);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');

      if (!context) {
        URL.revokeObjectURL(url);
        reject(new Error('Image blob validation failed - no context'));
        return;
      }

      context.drawImage(img, 0, 0);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      let isEmpty = true;

      for (let i = 0; i < data.length; i += 4) {
        const [red, green, blue, alpha] = data.slice(i);

        const isBlackPixel = red === 0 && green === 0 && blue === 0;
        const isWhitePixel = red === 255 && green === 255 && blue === 255;

        if (alpha !== 0 && !isBlackPixel && !isWhitePixel) {
          isEmpty = false;
          break;
        }
      }

      URL.revokeObjectURL(url);
      resolve(isEmpty);
    };

    img.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('Image blob validation failed - failed to load image blob'));
    };

    img.src = url;
  });
};
