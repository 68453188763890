import { FC, memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { MobileModalType, ModalHeader, ModalHeaderTitle as ModalHeaderTitleBase } from 'src/components/ModalLayout';
import { loginModalHeaderAtom } from 'src/stores/phoneNumberLogin/atoms';
import { NonBinaryChildGender } from 'src/types/register';
import { hoverAndActiveCss } from 'src/utils/styled';
import Input from './Input';
const InputWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
`;
const FilteredList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
const ChildGenderElement = styled.li`
  ${({
  theme
}) => hoverAndActiveCss(`
    background-color: ${theme.color.surface4};
  `)}
`;
const ChildGenderButton = styled.button`
  display: flex;
  padding: 14px 24px;
  align-items: center;

  color: ${({
  theme
}) => theme.color.content11};
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
const ModalHeaderTitle = styled(ModalHeaderTitleBase)`
  ${({
  theme
}) => theme.isRtl ? 'margin-right' : 'margin-left'}: 32px;
`;
interface Props {
  list: Array<NonBinaryChildGender>;
  setSelectedChildGender: (childGender: NonBinaryChildGender) => void;
  close: () => void;
}
const ChildGenderFilterInput: FC<Props> = ({
  list,
  setSelectedChildGender,
  close
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState<Array<NonBinaryChildGender>>([]);
  const setLoginModalHeader = useSetAtom(loginModalHeaderAtom);
  const {
    t
  } = useTranslation();
  useEffect(() => {
    if (searchTerm === '') return setFilteredList([]);
    const _filteredList = list.filter(childGender => childGender.localizedText.toLowerCase().startsWith(searchTerm.toLowerCase()));
    setFilteredList(_filteredList);
  }, [list, searchTerm]);
  const onClickChildGender = (childGender: NonBinaryChildGender) => {
    setSelectedChildGender(childGender);
    close();
  };
  useEffect(() => {
    setLoginModalHeader({
      component: <ModalHeader>
          <ModalHeaderTitle mobileType={MobileModalType.SCREEN_MODAL}>
            {t('gender_nonbinary')}
          </ModalHeaderTitle>
        </ModalHeader>,
      onBack: close
    });
    return () => {
      setLoginModalHeader(undefined);
    };
  }, [close, setLoginModalHeader, t]);
  return <>
      <InputWrap data-sentry-element="InputWrap" data-sentry-source-file="index.tsx">
        <Input onChangeText={setSearchTerm} value={searchTerm} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
      </InputWrap>
      <FilteredList data-sentry-element="FilteredList" data-sentry-source-file="index.tsx">
        {filteredList.map(childGender => <ChildGenderElement key={childGender.key}>
            <ChildGenderButton onClick={() => onClickChildGender(childGender)}>
              {childGender.localizedText}
            </ChildGenderButton>
          </ChildGenderElement>)}
      </FilteredList>
    </>;
};
export default memo(ChildGenderFilterInput);