import { FC, ReactNode, useRef } from 'react';
import useBodyScrollLock from 'src/hooks/useBodyScrollLock';
interface IProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  children: ReactNode;
  lockScroll?: boolean;
  onClickOutside?: () => void;
  onAnimationEnd?: () => void;
}
export const ModalScrollRoot: FC<IProps> = ({
  children,
  className,
  lockScroll = true,
  onAnimationEnd
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  useBodyScrollLock(rootRef, !lockScroll);
  return <div ref={rootRef} className={className} data-scroll-id='modal-scroll-root' onAnimationEnd={onAnimationEnd} data-sentry-component="ModalScrollRoot" data-sentry-source-file="ModalScrollComponent.tsx">
      {children}
    </div>;
};