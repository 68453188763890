import { ShopCategory } from 'src/stores/shop/types';

import { MakeRequired } from './utils';

/**
 * 젬부족 현상을 일으킨 원인
 */
export const GEM_LACK_TRIGGER = {
  locationFilter: 'GUARANGEE_PREF',
  genderFilter: 'GENDER_PREF',
  /**
   * 라이브 내부에서 발생
   */
  live: 'LIVE_GIFT',
} as const;

export type GemLackTrigger = (typeof GEM_LACK_TRIGGER)[keyof typeof GEM_LACK_TRIGGER];

interface ModuleBundleItem {
  iconUrl?: string;
  itemName?: string;
  description?: string;
}

interface GemLackPopupModuleContent {
  backgroundColor?: string;
  gemIconUrl?: string;
  deeplink?: string;
  backgroundImageUrl?: string;
  guidePopupTitle?: string;
  guidePopupDescriptions?: string[];
  guidePopupButtonText?: string;
  description?: string;
  limitedTimeTextEnabled?: boolean;
  limitedTimeTextColor?: string;
  bundleItems?: ModuleBundleItem[];
}

interface GemLackPopupModule {
  moduleType: string;
  moduleId: string;
  productName?: string;
  baseGemAmount?: number;
  bonusGemAmount?: number;
  promoGemAmount?: number;
  membershipGemAmount?: number;
  iconUrl?: string;
  productType?: string;
  productId?: string;
  content?: GemLackPopupModuleContent;
  period?: number;
  periodUnit?: string;
  text?: string;
  moduleEndDate?: number;
  discountRate?: string;
  purchaseLimitExceeded?: boolean;
  purchaseLimitBehavior?: string;
  /**
   * 상품 모듈인 경우 웹에서는 항상 required지만 서버 DTO 정의상으로는 Nullable
   */
  pgPrice?: {
    price: number;
    currency: string;
  };
}

interface GemLackPopupSection {
  sectionType: string;
  modules?: GemLackPopupModule[];
}

export enum GemLackPopupPageType {
  GEM_LACK_POPUP = 'GEM_LACK_POPUP',
  STARTER_PACKAGE_POPUP = 'PACKAGE_POPUP_B', // A/B 테스트 잔재로 변수명에 플래그 남아있음
}

interface GemLackPopupPageBase {
  pageId: string;
  pageType: string;
  sections: GemLackPopupSection[];
  title: string;
  description: string;
}

interface FooterSection extends GemLackPopupSection {
  /**
   * 관련 규정을 표시할 필요가 없는 경우 필드 생략해서 내려옴
   */
  modules?: [RefundPolicyModule];
}

export interface RefundPolicyModule extends GemLackPopupModule {
  moduleType: GemLackPopupModuleType.REFUND_POLICY;
  text: string;
}

export enum GemLackPopupModuleType {
  SINGLE_PRODUCT = 'SINGLE_PRODUCT_B',
  STARTER_PACKAGE_PRODUCT = 'PACKAGE_PRODUCT_GEM_B',
  GEM_PRODUCT = 'GEM_PRODUCT',
  REFUND_POLICY = 'REFUND_POLICY',
}

export type ProductModule = MakeRequired<
  GemLackPopupModule,
  'productType' | 'productId' | 'productName'
>;

/**
 * 젬이 포함된 상품의 정보
 */
export type GemIncludedProductModule = MakeRequired<
  ProductModule,
  'baseGemAmount' | 'bonusGemAmount' | 'promoGemAmount' | 'membershipGemAmount' | 'iconUrl'
>;

/**
 * 일반 젬상품
 */
export interface GemProductModule extends GemIncludedProductModule {
  iconUrl: string;
  content: {
    /**
     * 상품의 배경으로 보여줄 생삭
     */
    backgroundColor: string;
  };
}

export interface GemProductsSection extends GemLackPopupSection {
  modules: [GemProductModule, GemProductModule];
}

/**
 * 일반적인 젬부족 팝업 UI (젬상품 2개 노출)
 */
export interface NormalGemLackPopupPage extends GemLackPopupPageBase {
  pageType: GemLackPopupPageType.GEM_LACK_POPUP;
  sections: [GemProductsSection, FooterSection];
}

/**
 * 넛징하려는 패키지와 비교군으로 제공하는 상품
 * 현재는 일반 젬상품만 내려올 예정이라 맞춰서 타입 지정
 */
export interface SingleProductModule extends GemIncludedProductModule {
  moduleType: GemLackPopupModuleType.SINGLE_PRODUCT;
}

/**
 * 스타터패키지 상품 모듈
 * 현재는 일반 젬상품만 내려올 예정이라 맞춰서 타입 지정
 */
export interface StarterPackageModule extends GemIncludedProductModule {
  moduleType: GemLackPopupModuleType.STARTER_PACKAGE_PRODUCT;
  content: {
    gemIconUrl: string;
    description: string;

    /**
     * 상품 아이콘 뒤에 배경으로 적용해줄 색상
     */
    iconBackgroundColor: string;
  };
}

export interface StarterPackageSection extends GemLackPopupSection {
  modules: [SingleProductModule, StarterPackageModule];
}

export interface StarterPackagePopupPage extends GemLackPopupPageBase {
  pageType: GemLackPopupPageType.STARTER_PACKAGE_POPUP;
  sections: [StarterPackageSection, FooterSection];
}

export interface GetGemLackPopupRequest {
  /**
   * 현 인벤토리 대비 상품 구매를 위해 부족한 젬의 수량
   */
  gemAmount: number;

  /**
   * 젬샵의 유형
   */
  shopCategory: ShopCategory;
}

export type GetGemLackPopupResponse = NormalGemLackPopupPage | StarterPackagePopupPage;
