import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';
interface IModalContentContext {
  isBottomReached: boolean;
  setIsBottomReached: Dispatch<SetStateAction<boolean>>;
  isTopReached: boolean;
  setIsTopReached: Dispatch<SetStateAction<boolean>>;
}
export const ModalContentContext = createContext<IModalContentContext>({
  isBottomReached: true,
  setIsBottomReached: () => true,
  isTopReached: true,
  setIsTopReached: () => true
});
type IModalContentProvider = {
  children: React.ReactNode;
};
export const ModalContentProvider: FC<IModalContentProvider> = ({
  children
}) => {
  const [isBottomReached, setIsBottomReached] = useState(true);
  const [isTopReached, setIsTopReached] = useState(true);
  return <ModalContentContext.Provider value={{
    isBottomReached,
    setIsBottomReached,
    isTopReached,
    setIsTopReached
  }} data-sentry-element="unknown" data-sentry-component="ModalContentProvider" data-sentry-source-file="ModalContentContext.tsx">
      {children}
    </ModalContentContext.Provider>;
};