import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue } from 'jotai';

import { azarTokenAtom } from 'src/stores/auth/atoms';
import { client } from 'src/utils/api';

const useQueryWithAuth = <TParams, TResponse, TError>({
  path,
  params,
  queryKey,
  options,
}: {
  path: string;
  params?: TParams;
  queryKey: QueryKey;
  options?: UseQueryOptions<AxiosResponse<TResponse>, AxiosError<TError>, AxiosResponse<TResponse>>;
}) => {
  const token = useAtomValue(azarTokenAtom);
  const { enabled = true, ...restOptions } = options ?? {};

  return useQuery<AxiosResponse<TResponse>, AxiosError<TError>, AxiosResponse<TResponse>>({
    queryKey,
    queryFn: async () =>
      client.get<TResponse>(path, {
        params: params ? { ...params } : null,
      }),
    enabled: !!token && enabled,
    ...restOptions,
  });
};

export default useQueryWithAuth;
