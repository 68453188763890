import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import { Wrap } from 'src/components/AzarOnly/Login/styles';
import Button from 'src/components/Button';
import { DatePicker } from 'src/components/Datepicker';
import { ModalFooter } from 'src/components/ModalLayout';
import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import { calculateAge } from 'src/utils/date';
import { BirthdayInputProps } from './type';
const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const TitleWrap = styled.div``;
const ModalTitle = styled.div`
  color: ${({
  theme
}) => theme.color.contentDeep};
  text-align: ${({
  theme
}) => theme.isRtl ? 'right' : 'left'};
  font-size: 30px;
  line-height: 40px;
  font-style: normal;
  font-weight: 700;
  padding: 24px 0;

  ${({
  theme
}) => theme.screenSize.mobile} {
    padding: 16px 0;
    font-size: 40px;
    line-height: 56px;
  }
`;
const AgeDescription = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({
  theme
}) => theme.color.content11};
  text-align: ${({
  theme
}) => theme.isRtl ? 'right' : 'left'};
`;
const ButtonTitle = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;
const ButtonDescription = styled.span`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
`;
const MobileDatePickerWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 12px 0;
  width: 100%;
  height: 100%;
`;
const StyledDatePicker = styled(DatePicker)`
  height: 100%;
  max-height: 270px;
`;
export const ScrollBirthdayInput = ({
  onSubmit
}: BirthdayInputProps) => {
  const signupDefaultBirthyearOffset = Number(useAtomValue(visitorConfigAtom)?.signupDefaultBirthyearOffset) || 18;
  const defaultBirth = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - signupDefaultBirthyearOffset);
    return date;
  }, [signupDefaultBirthyearOffset]);
  const [birth, setBirth] = useState<Date>(defaultBirth);
  const {
    t
  } = useTranslation();
  const age = useMemo(() => {
    if (!birth) return null;
    return Math.max(calculateAge(birth), 0);
  }, [birth]);
  const [isBirthChanged, setIsBirthChanged] = useState(false);
  const onChange = (date: Date) => {
    setBirth(date);
    setIsBirthChanged(true);
  };
  return <>
      <Wrap data-sentry-element="Wrap" data-sentry-source-file="ScrollBirthdayInput.tsx">
        <InnerWrap data-testid='LoginModal-BirthdayStep' data-sentry-element="InnerWrap" data-sentry-source-file="ScrollBirthdayInput.tsx">
          <TitleWrap data-sentry-element="TitleWrap" data-sentry-source-file="ScrollBirthdayInput.tsx">
            <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="ScrollBirthdayInput.tsx">{t('signup_extra_info_birthday')}</ModalTitle>
            <AgeDescription data-sentry-element="AgeDescription" data-sentry-source-file="ScrollBirthdayInput.tsx">{t('signup_extra_info_birthday_warning_2')}</AgeDescription>
          </TitleWrap>
          <MobileDatePickerWrap data-sentry-element="MobileDatePickerWrap" data-sentry-source-file="ScrollBirthdayInput.tsx">
            <StyledDatePicker value={birth} onChange={onChange} data-sentry-element="StyledDatePicker" data-sentry-source-file="ScrollBirthdayInput.tsx" />
          </MobileDatePickerWrap>
        </InnerWrap>
      </Wrap>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="ScrollBirthdayInput.tsx">
        <Button onClick={() => onSubmit(birth)} disabled={!isBirthChanged} id='SIGNUP__NEXT_REGISTER_BIRTHDAY_STEP' data-testid='RegisterBirthdayStep-button-next' data-sentry-element="Button" data-sentry-source-file="ScrollBirthdayInput.tsx">
          {!isBirthChanged ? <ButtonTitle>{t('signup_info_birth_title')}</ButtonTitle> : <>
              <ButtonTitle>{t('signup_extra_info_birthday_age', {
              age
            })}</ButtonTitle>
              <ButtonDescription>{t('signup_extra_info_birthday_warning_3')}</ButtonDescription>
            </>}
        </Button>
      </ModalFooter>
    </>;
};