import React, { useEffect, useMemo } from 'react';
import { ErrorBoundary } from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import ErrorRetryModal from 'src/components/ErrorRetryModal';
import ProductNudgingModalLayout, { ProductNudgingModalLoading } from 'src/components/ProductNudgingModal';
import StarterPackageNudgingModal from 'src/components/StarterPackageNudgingModal';
import useEvent from 'src/hooks/useEvent';
import useGemAmount from 'src/hooks/useGemAmount';
import { GemLackApiList, useGemLackPopupQuery } from 'src/hooks/useGemLackQuery';
import { getGemLackTriggersFromMatchFiltersAtom } from 'src/stores/match/atoms';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { ShopCategory } from 'src/stores/shop/types';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { GEM_LACK_TRIGGER, GemLackPopupModuleType, GemLackPopupPageType, GemLackTrigger, RefundPolicyModule } from 'src/types/gemLack';
import { ModalType } from 'src/types/Modal';
import NormalGemLackNudging from './NormalGemLackNudging';
interface GemLackModalProps {
  lackingGemAmount: number;
  gemLackTriggers: GemLackTrigger[];
}
const GemLackModalInner: React.FC<GemLackModalProps> = ({
  lackingGemAmount: lackingGemAmount,
  gemLackTriggers
}) => {
  const {
    t
  } = useTranslation();
  const showModal = useSetAtom(showModalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const getGemLackTriggersFromMatchFilters = useSetAtom(getGemLackTriggersFromMatchFiltersAtom);
  const currentGemAmount = useGemAmount() || 0;
  const desiredGemAmount = useMemo(() => {
    return currentGemAmount + lackingGemAmount;
  }, [currentGemAmount, lackingGemAmount]);
  const isTriggeredByLive = useMemo(() => {
    return gemLackTriggers.includes(GEM_LACK_TRIGGER.live);
  }, [gemLackTriggers]);
  const pushEvent = useEvent();
  const {
    data,
    isLoading
  } = useGemLackPopupQuery({
    gemAmount: lackingGemAmount,
    shopCategory: isTriggeredByLive ? ShopCategory.Live : ShopCategory.Normal
  }, {
    useErrorBoundary: true
  });
  const flattenModules = useMemo(() => {
    return data?.data.sections.map(section => section.modules).flat() ?? [];
  }, [data]);
  const refundPolicyModule = useMemo(() => {
    return flattenModules.find((module): module is RefundPolicyModule => module?.moduleType === GemLackPopupModuleType.REFUND_POLICY);
  }, [flattenModules]);

  /**
   * 보고용으로 전송할 젬부족 사유
   * 클라이언트 기준 젬부족 원인이 파악되지 않는 경우 인벤토리 부정확성을 고려하여 인벤토리 배제하고 원인 추정
   */
  const gemLackTriggersToReport = useMemo(() => {
    return gemLackTriggers.length ? gemLackTriggers : getGemLackTriggersFromMatchFilters({
      isBypassInventory: true
    });
  }, [gemLackTriggers, getGemLackTriggersFromMatchFilters]);

  /**
   * 일반 젬부족 모달의 경우 젬부족 원인에 따라 다르게 문구가 노출되어야 하는데 현 서버 구현은 이를 파악할 수 없음
   * 따라서 모달의 description은 일괄적으로 클라이언트에서 처리하기로 결정
   */
  const gemLackDescription = useMemo(() => {
    if (!data) return '';

    /**
     * 젬부족을 일으킨 예상 사유가 여러 개 존재하나 실제 원인이 되는 요소를 분간하기 어려운 경우가 존재
     * eg. 유료 성별 필터와 국가 필터를 동시 적용했는데 각각 적용은 가능하나 동시 적용하기에는 젬이 부족한 경우
     *
     * -> 따라서 젬부족 모달의 문구는 예상되는 사유 중 하나로 임의 선택해서 노출하기로 합의
     */
    const gemLackTrigger = gemLackTriggers[0];
    switch (gemLackTrigger) {
      case GEM_LACK_TRIGGER.locationFilter:
        return t('NOT_ENOUGH_GEM_POPUP_COUNTRY_FILTER', {
          gems: desiredGemAmount
        });
      case GEM_LACK_TRIGGER.genderFilter:
        return t('NOT_ENOUGH_GEM_POPUP_GENDER_DESCRIPTION', {
          gems: desiredGemAmount
        });
      case GEM_LACK_TRIGGER.live:
        return t('NOT_ENOUGH_GEM_POPUP_LIVE', {
          gems: lackingGemAmount // 라이브는 번역 문구가 달라서 부족한 젬 수량 기준으로 문구 처리
        });
      default:
        return t('NOT_ENOUGH_GEM_POPUP_DESCRIPTION');
    }
  }, [data, desiredGemAmount, gemLackTriggers, lackingGemAmount, t]);
  const handleClose = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.GEM_LACK_POPUP__CLICK_CLOSE
    });
  };
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.REPORT,
      eventName: EVENT_NAME.ANYWHERE__REPORT_GEMLACK_INSPECTION,
      eventParams: {
        action_category: 'report',
        tab: 'anywhere',
        page: 'anywhere',
        target: 'gemlack_inspection',
        initial_feature: gemLackTriggersToReport,
        /**
         * 클라이언트 dry-run 결과 젬 부족 원인이 파악된 경우
         * 만약 인벤토리 부정확 등으로 인해 원인이 파악되지 못하는 경우 false 리턴 가능
         */
        is_valid: gemLackTriggers.length > 0
      }
    });
  }, [gemLackTriggers, gemLackTriggersToReport, pushEvent]);
  useEffect(() => {
    if (data?.data.pageType === GemLackPopupPageType.STARTER_PACKAGE_POPUP) {
      const [singleProductModule, starterPackageModule] = data.data.sections[0].modules;
      showModal({
        key: ModalType.STARTER_PACKAGE_NUDGING,
        component: () => <StarterPackageNudgingModal description={gemLackDescription} singleProductModule={singleProductModule} starterPackageModule={starterPackageModule} refundPolicyModule={refundPolicyModule} origins={gemLackTriggersToReport} />
      });
      closeModal(ModalType.GEM_LACK);
    }
  }, [closeModal, data, gemLackDescription, gemLackTriggers, gemLackTriggersToReport, refundPolicyModule, showModal]);
  const isShowLoader = isLoading || !data;
  const pageType = data?.data.pageType;
  useEffect(() => {
    if (isLoading) return;
    if (!pageType || !Object.values(GemLackPopupPageType).includes(pageType)) {
      throw new Error(`Invalid gem lack page type has been given: ${pageType}`);
    }
  }, [isLoading, pageType]);
  return <ProductNudgingModalLayout onClose={handleClose} data-testid='gem-lack-modal' data-sentry-element="ProductNudgingModalLayout" data-sentry-component="GemLackModalInner" data-sentry-source-file="index.tsx">
      {isShowLoader && <ProductNudgingModalLoading />}
      {!isShowLoader && pageType === GemLackPopupPageType.GEM_LACK_POPUP && <NormalGemLackNudging title={t('FILTER_LOCATION_NOT_ENOUGH_GEM')} description={gemLackDescription} gemProductModules={data.data.sections[0].modules} refundPolicyModule={refundPolicyModule} gemLackTriggers={gemLackTriggersToReport} />}
    </ProductNudgingModalLayout>;
};
const GemLackModal = (props: GemLackModalProps) => {
  const closeModal = useSetAtom(closeModalAtom);
  const queryClient = useQueryClient();
  const handleRetryModalClose = () => {
    closeModal(ModalType.GEM_LACK);
  };
  return <ErrorBoundary fallback={({
    resetError
  }) => {
    return <ErrorRetryModal onRetry={() => {
      queryClient.resetQueries({
        queryKey: [GemLackApiList.GetGemLackPopup]
      });
      resetError();
    }} onClose={handleRetryModalClose} />;
  }} data-sentry-element="ErrorBoundary" data-sentry-component="GemLackModal" data-sentry-source-file="index.tsx">
      <GemLackModalInner {...props} data-sentry-element="GemLackModalInner" data-sentry-source-file="index.tsx" />
    </ErrorBoundary>;
};
export default GemLackModal;