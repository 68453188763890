import {
  VideoCallMetricCollector,
  VideoCallMetricCollectorOption,
  VideoCallStats,
} from '@hyperconnect/media-metric';
import * as Sentry from '@sentry/nextjs';

import store from 'src/stores';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { CandidateMessage, maxFramerate } from 'src/types/Match';
import { isError } from 'src/utils/error';
import {
  applyWorkaroundForCompat,
  fixMissingRtpmap,
  isMissingRtpmap,
  preferAudioCodec,
} from 'src/utils/media/codec';

// import { parseProfileLevelId, Profile } from './h264ProfileLevelId';
import { isValidCandidate, mungeSDPVideoStartBitrate } from 'src/utils/media/munge_sdp';

import { BitrateMovingAverageCalculator } from './bitrate_moving_average_calculator';
import { VideoStartBitrateMode } from './bridge';
import { webRtcDefaultStartBitrateKbps } from './constants';
import { IceCandidateChecker } from './ice-candidate-checker';

declare global {
  interface Window {
    webkitRTCPeerConnection?: RTCPeerConnection;
    mozRTCPeerConnection?: RTCPeerConnection;
    msRTCPeerConnection?: RTCPeerConnection;
  }
}

function gcd(a: number, b: number): number {
  a = Math.abs(a);
  b = Math.abs(b);
  if (b > a) {
    [a, b] = [b, a];
  }
  // eslint-disable-next-line  no-constant-condition
  while (true) {
    if (b == 0) return a;
    a %= b;
    if (a == 0) return b;
    b %= a;
  }
}

class Fraction {
  public numerator: number;

  public denominator: number;

  constructor(numerator: number, denominator: number) {
    this.numerator = numerator;
    this.denominator = denominator;
  }

  divideByGcd() {
    const g = gcd(this.numerator, this.denominator);
    this.numerator /= g;
    this.denominator /= g;
  }

  scalePixelCount(inputPixels: number): number {
    return (this.numerator * this.numerator * inputPixels) / (this.denominator * this.denominator);
  }
}

function findScale(inputWidth: number, inputHeight: number, maxPixels: number): Fraction {
  // WebRTC의 media/base/video_adapter.cc 로직을 사용
  const inputPixels = inputWidth * inputHeight;

  if (maxPixels >= inputPixels) {
    return new Fraction(1, 1);
  }
  let currentScale = new Fraction(1, 1);
  let bestScale = new Fraction(1, 1);
  if (inputWidth % 3 === 0 && inputHeight % 3 === 0) {
    currentScale = new Fraction(6, 6);
  }
  if (inputWidth % 9 === 0 && inputHeight % 9 === 0) {
    currentScale = new Fraction(9, 9);
  }

  let minPixelDiff = Number.MAX_SAFE_INTEGER;
  while (currentScale.scalePixelCount(inputPixels) > maxPixels) {
    if (currentScale.numerator % 3 === 0 && currentScale.denominator % 2 === 0) {
      // Multiply by 2/3.
      currentScale.numerator /= 3;
      currentScale.denominator /= 2;
    } else {
      // Multiply by 3/4.
      currentScale.numerator *= 3;
      currentScale.denominator *= 4;
    }

    const outputPixels = currentScale.scalePixelCount(inputPixels);
    if (outputPixels <= maxPixels) {
      const diff = maxPixels - outputPixels;
      if (diff < minPixelDiff) {
        minPixelDiff = diff;
        bestScale = currentScale;
      }
    }
  }
  bestScale.divideByGcd();
  return bestScale;
}

export class PeerConnectionClient {
  private readonly rtcConfig: RTCConfiguration = {}; // default rtc config

  private peerConnection: RTCPeerConnection | null = null;

  private onTrackEventHandler: RTCPeerConnection['ontrack'];

  private onICECandidateHandler: RTCPeerConnection['onicecandidate'];

  private onIceConnectionStateChange: RTCPeerConnection['oniceconnectionstatechange'];

  private onConnectionStateChange: RTCPeerConnection['onconnectionstatechange'];

  private onVideoCallStats: (stat: VideoCallStats) => void;

  private onIceCandidateError: RTCPeerConnection['onicecandidateerror'];

  private pendingVideoTrack: MediaStreamTrack | null = null;

  private hasVideoTransceiver = false;

  private hasRemoteDescription = false;

  private pendingIceCandidates: RTCIceCandidateInit[] = [];

  private matchVideoHeight: number | null = null;

  private inputVideoWidth: number | null = null;

  private inputVideoHeight: number | null = null;

  private maxVideoBandwidthKbps = 1000;

  private videoStartBitrateMode: VideoStartBitrateMode = VideoStartBitrateMode.None;

  private metricCollector: VideoCallMetricCollector | null = null;

  private iceCandidateChecker: IceCandidateChecker | null = null;

  constructor({
    onTrackEventHandler,
    onICECandidateHandler,
    onVideoCallStats,
    onIceConnectionStateChange,
    onConnectionStateChange,
    onIceCandidateError,
  }: {
    onVideoCallStats: (stat: VideoCallStats) => void;
    onTrackEventHandler: RTCPeerConnection['ontrack'];
    onICECandidateHandler: RTCPeerConnection['onicecandidate'];
    onIceConnectionStateChange: RTCPeerConnection['oniceconnectionstatechange'];
    onConnectionStateChange: RTCPeerConnection['onconnectionstatechange'];
    onIceCandidateError: RTCPeerConnection['onicecandidateerror'];
  }) {
    this.onTrackEventHandler = onTrackEventHandler;
    this.onICECandidateHandler = onICECandidateHandler;
    this.onVideoCallStats = onVideoCallStats;
    this.onIceConnectionStateChange = onIceConnectionStateChange;
    this.onConnectionStateChange = onConnectionStateChange;
    this.onIceCandidateError = onIceCandidateError;
  }

  private formatDescription(desc: RTCSessionDescriptionInit): RTCSessionDescriptionInit {
    let newSdp = desc.sdp ? desc.sdp : '';
    if (newSdp.length > 0 && isMissingRtpmap(newSdp)) {
      newSdp = fixMissingRtpmap(newSdp);
    }

    newSdp = applyWorkaroundForCompat(newSdp);
    newSdp = preferAudioCodec(newSdp, 'ISAC');

    const newDesc = {
      type: desc.type,
      sdp: newSdp,
    };
    return newDesc;
  }

  public open(config: RTCConfiguration) {
    // 브라우저 호환성 관련 Reference Error 방지
    const RTCPeerConnection =
      window.RTCPeerConnection ||
      window.webkitRTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.msRTCPeerConnection;

    this.peerConnection = new RTCPeerConnection({
      ...this.rtcConfig,
      ...config,
    });
    this.peerConnection.ontrack = this.onTrackEventHandler;
    this.peerConnection.onicecandidate = this.onICECandidateHandler;
    this.peerConnection.oniceconnectionstatechange = this.onIceConnectionStateChange;
    this.peerConnection.onconnectionstatechange = this.onConnectionStateChange;
    this.peerConnection.onicecandidateerror = this.onIceCandidateError;
    this.iceCandidateChecker = new IceCandidateChecker(this.peerConnection);
    this.startCollectStats();

    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: { debug: 'PeerConnectionClient open' },
    });
  }

  public close() {
    this.stopCollectStats();
    this.iceCandidateChecker?.dispose();
    this.peerConnection?.close();
    this.peerConnection = null;
    this.pendingVideoTrack = null;
    this.hasVideoTransceiver = false;
    this.pendingIceCandidates = [];
    this.hasRemoteDescription = false;

    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: { debug: 'PeerConnectionClient close' },
    });
    return this.peerConnection === null;
  }

  public getConnectionState() {
    return this.peerConnection?.connectionState || null;
  }

  public getIceConnectionState(): RTCIceConnectionState | null {
    return this.peerConnection?.iceConnectionState || null;
  }

  public getSignalingState(): RTCSignalingState | null {
    return this.peerConnection?.signalingState || null;
  }

  private checkAdded(track: MediaStreamTrack) {
    return this.peerConnection?.getSenders().some((sender) => sender.track === track);
  }

  public addTrack(track: MediaStreamTrack) {
    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: { debug: 'addTrack', trackKind: track.kind },
    });

    if (this.peerConnection) {
      const kind = track.kind;
      if (kind === 'audio') {
        // TODO(daniel.l): Audio Codec 설정 추가
        // MatchInfo.useOpus 반영 필요
        const isAdded = this.checkAdded(track);
        if (!isAdded) {
          this.peerConnection.addTrack(track);
        }
      } else if (kind === 'video') {
        this.pendingVideoTrack = track;
      }
    }
  }

  public addIceCandidate(message: CandidateMessage) {
    const candidateStr = message.candidate as string;
    if (candidateStr == '') {
      // 이런 Candidate 가 들어오는 경우가 없을 것 같지만, 혹시 모르니 기록한다.
      Sentry.captureMessage('Recv end-of-candidates');
      return;
    } else if (!isValidCandidate(candidateStr)) {
      Sentry.captureMessage('Recv invalid candidate', {
        extra: {
          sdpMid: message.id,
          sdpMLineIndex: message.label,
          candidate: candidateStr,
        },
      });
      return;
    }

    if (this.peerConnection) {
      const candidate = new RTCIceCandidate({
        sdpMLineIndex: message.label,
        sdpMid: message.id,
        candidate: candidateStr,
      }) as RTCIceCandidateInit;
      if (!this.hasRemoteDescription) {
        this.pendingIceCandidates.push(candidate);
        return;
      }
      return this.peerConnection.addIceCandidate(candidate);
    }
  }

  public async setRemoteDescription(desc: { type: RTCSdpType; sdp: string }) {
    let startBitrate: number | null = null;
    let resultSdp: string = desc.sdp;

    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'PeerConnectionClient.setRemoteDescription start',
        sdp: desc.sdp,
      },
    });

    switch (this.videoStartBitrateMode) {
      case VideoStartBitrateMode.Auto: {
        const averageBitrateKBps = BitrateMovingAverageCalculator.instance.getAverageBitrateKBps();
        if (averageBitrateKBps === null || averageBitrateKBps < webRtcDefaultStartBitrateKbps) {
          break;
        }
        startBitrate = averageBitrateKBps;
        if (this.maxVideoBandwidthKbps !== null) {
          startBitrate = Math.min(startBitrate, this.maxVideoBandwidthKbps);
        }
        break;
      }
      case VideoStartBitrateMode.None:
        // no-op
        break;
    }
    if (startBitrate !== null) {
      try {
        resultSdp = mungeSDPVideoStartBitrate(desc.sdp, startBitrate);
      } catch (_err) {
        const errorMessage = 'Failed to set startBitrateKbps: ' + startBitrate + ' kbps';
        const error = new Error(errorMessage);
        if (isError(_err)) {
          error.name = _err.name;
          error.stack = _err.stack;
        }
        Sentry.captureException(error);
      }
    }

    if (this.peerConnection) {
      if (this.pendingVideoTrack != null) {
        const isAdded = this.checkAdded(this.pendingVideoTrack);
        if (!isAdded) {
          this.peerConnection.addTrack(this.pendingVideoTrack);
        }
        this.pendingVideoTrack = null;
      }

      try {
        await this.peerConnection.setRemoteDescription({
          type: desc.type,
          sdp: resultSdp,
        });
      } catch (err) {
        store.set(eventMutateAtom, {
          eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
          eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
          eventParams: {
            debug: 'PeerConnectionClient.setRemoteDescription failed',
            error: err,
          },
        });
        Sentry.captureMessage(`${err}`, {
          extra: { sdp: desc },
        });
      }
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'PeerConnectionClient.setRemoteDescription done',
        },
      });

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const iceCandidate = this.pendingIceCandidates.shift();
        if (iceCandidate == null) {
          break;
        }
        await this.peerConnection?.addIceCandidate(iceCandidate);
      }
      this.hasRemoteDescription = true;
    }
  }

  private async setLocalDescription(desc?: RTCSessionDescriptionInit) {
    try {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'PeerConnectionClient.setLocalDescription start',
          sdp: desc?.sdp,
        },
      });
      this.iceCandidateChecker?.start();
      await this.peerConnection?.setLocalDescription(desc);
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'PeerConnectionClient.setLocalDescription done',
        },
      });
    } catch (e) {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'PeerConnectionClient.setLocalDescription failed',
          error: e,
        },
      });
      Sentry.captureMessage(`${e}`, {
        extra: { sdp: desc },
      });
    }
  }

  public async makeOffer(): Promise<RTCSessionDescriptionInit | null> {
    if (this.peerConnection) {
      if (this.pendingVideoTrack != null) {
        // RTCRtpEncodingParameters.maxFramerate: https://w3c.github.io/webrtc-extensions/#dom-rtcrtpencodingparameters-maxframerate
        // Chrome 81+, Safari 11+
        let encodingParameters: RTCRtpEncodingParameters[] = [{ maxFramerate }];
        encodingParameters = this.updateEncodingParameters(encodingParameters);
        this.peerConnection.addTransceiver(this.pendingVideoTrack, {
          sendEncodings: encodingParameters,
        });
        this.hasVideoTransceiver = true;
        this.pendingVideoTrack = null;
      }
      this.setVideoCodecPreference();

      const sessionDescription = await this.peerConnection.createOffer({
        offerToReceiveVideo: true,
        offerToReceiveAudio: true,
      });
      const desc = this.formatDescription(sessionDescription);
      await this.setLocalDescription(desc);
      return desc;
    }
    return null;
  }

  public async makeAnswer(): Promise<RTCSessionDescriptionInit | null> {
    if (this.peerConnection) {
      const sessionDescription = await this.peerConnection.createAnswer();
      const formedDesc = this.formatDescription(sessionDescription);
      await this.setLocalDescription(formedDesc);
      this.hasVideoTransceiver = true;
      for (let i = 0; i < this.peerConnection.getTransceivers().length; i++) {
        const transceiver = this.peerConnection.getTransceivers()[i];
        if (transceiver.sender.track?.kind === 'video') {
          const parameters = transceiver.sender.getParameters();
          parameters.encodings = this.updateEncodingParameters(parameters.encodings);
          await transceiver.sender.setParameters(parameters);
        }
      }
      return formedDesc;
    }
    return null;
  }

  public async setMatchVideoHeight(matchVideoHeight: number) {
    const changed = this.matchVideoHeight == null || this.matchVideoHeight !== matchVideoHeight;
    if (!changed) {
      return;
    }
    this.matchVideoHeight = matchVideoHeight;
    if (this.hasVideoTransceiver) {
      await this.setVideoParameters();
    }
  }

  public async setInputVideoSize(width: number, height: number) {
    const changed =
      this.inputVideoWidth == null ||
      this.inputVideoHeight == null ||
      this.inputVideoWidth !== width ||
      this.inputVideoHeight !== height;
    if (!changed) {
      return;
    }
    this.inputVideoWidth = width;
    this.inputVideoHeight = height;
    if (this.hasVideoTransceiver) {
      await this.setVideoParameters();
    }
  }

  public async setMaxVideoBandwidth(maxVideoBandwidthKbps: number) {
    const changed =
      this.maxVideoBandwidthKbps == null || this.maxVideoBandwidthKbps !== maxVideoBandwidthKbps;
    if (!changed) {
      return;
    }
    this.maxVideoBandwidthKbps = maxVideoBandwidthKbps;
    if (this.hasVideoTransceiver) {
      await this.setVideoParameters();
    }
  }

  private async setVideoParameters() {
    if (this.peerConnection == null) {
      return;
    }
    for (let i = 0; i < this.peerConnection.getSenders().length; i++) {
      const sender = this.peerConnection?.getSenders()[i];
      if (sender.track?.kind === 'video') {
        const parameters = sender.getParameters();
        parameters.encodings = this.updateEncodingParameters(parameters.encodings);
        await sender.setParameters(parameters);
      }
    }
  }

  private updateEncodingParameters(
    encodingParameters: RTCRtpEncodingParameters[]
  ): RTCRtpEncodingParameters[] {
    for (let i = 0; i < encodingParameters.length; i++) {
      // RTCRtpEncodingParameters.maxFramerate: https://w3c.github.io/webrtc-extensions/#dom-rtcrtpencodingparameters-maxframerate
      // Chrome 81+, Safari 11+
      encodingParameters[i].maxFramerate = maxFramerate;
    }

    if (
      this.matchVideoHeight != null &&
      this.inputVideoWidth != null &&
      this.inputVideoHeight != null
    ) {
      const targetPixels = this.matchVideoHeight * ((this.matchVideoHeight * 16) / 9);
      const fraction = findScale(this.inputVideoWidth, this.inputVideoHeight, targetPixels);
      // scaleResolutionDownBy 는 얼만큼 비율로 줄여주는지 이므로, numerator 와 denominator 를 반대로 계산한다.
      // 줄여줄 비율이 1 이하라면 굳이 추가하지 않는다.
      if (fraction.denominator / fraction.numerator > 1) {
        // RTCRtpEncodingParameters.scaleResolutionDownBy https://w3c.github.io/webrtc-pc/#dom-rtcrtpencodingparameters-scaleresolutiondownby
        // Chrome 74+, Safari: 11+
        for (let i = 0; i < encodingParameters.length; i++) {
          encodingParameters[i].scaleResolutionDownBy = fraction.denominator / fraction.numerator;
        }
      }
    }
    if (this.maxVideoBandwidthKbps != null) {
      // RTCRtpEncodingParameters.maxBitrate https://w3c.github.io/webrtc-pc/#dom-rtcrtpencodingparameters-maxbitrate
      // Chrome 67+, Safari: 11+
      for (let i = 0; i < encodingParameters.length; i++) {
        // encodingParameters[i].maxBitrate단위: bps
        encodingParameters[i].maxBitrate = this.maxVideoBandwidthKbps * 1000;
      }
    }
    return encodingParameters;
  }

  private setVideoCodecPreference() {
    // RTCRtpSender.getCapabilities: https://w3c.github.io/webrtc-pc/#dom-rtcrtpsender-getcapabilities
    // Chrome 66+, Safari: 11+
    const availableSendCodecs: RTCRtpCodec[] | undefined =
      window.RTCRtpSender.getCapabilities?.('video')?.codecs;
    if (availableSendCodecs == null) {
      return;
    }

    const preferredCodecs: RTCRtpCodec[] = [];
    availableSendCodecs.forEach((value) => {
      const codecName = value.mimeType.split('/', 2)[1].toLowerCase();
      // rtx, red, ulpfec, flexfec 는 항상 추가한다.
      if (
        codecName === 'rtx' ||
        codecName === 'red' ||
        codecName === 'ulpfec' ||
        codecName.startsWith('flexfec')
      ) {
        preferredCodecs.push(value);
        return;
      }

      // NOTE: 호환성 문제가 있는지 확인하기 위해 우선 VP8만 사용하게 합니다.
      if (codecName === 'vp8') {
        preferredCodecs.push(value);
        return;
      }
    });

    this.peerConnection?.getTransceivers().forEach((transceiver) => {
      if (transceiver.sender.track?.kind === 'video') {
        // RTCRtpTransceiver.setCodecPreferences: https://w3c.github.io/webrtc-pc/#dom-rtcrtptransceiver-setcodecpreferences
        // Chrome 76+, Safari 13.1+
        transceiver.setCodecPreferences?.(preferredCodecs);
      }
    });
  }

  public setVideoStartBitrateMode(isAuto: boolean) {
    this.videoStartBitrateMode = isAuto ? VideoStartBitrateMode.Auto : VideoStartBitrateMode.None;
  }

  public setStartBitrateControlAlpha(alpha: number) {
    BitrateMovingAverageCalculator.instance.setAlpha(alpha);
  }

  private startCollectStats() {
    if (!this.peerConnection) return;
    this.stopCollectStats();
    this.metricCollector = new VideoCallMetricCollectorOption(this.peerConnection)
      .setPreviewCallback((stats) => {
        if (this.onVideoCallStats) {
          this.onVideoCallStats(stats);
        }
      })
      .build();
    if (this.metricCollector == null) {
      Sentry.captureMessage('Failed to create VideoCallMetricCollector');
    }
  }

  private stopCollectStats() {
    if (this.metricCollector != null) {
      const sendVideoBitrateMedian = this.metricCollector.summary().sendVideoBitrateMedian;
      if (sendVideoBitrateMedian != null && sendVideoBitrateMedian > 0) {
        BitrateMovingAverageCalculator.instance.updateBitrate(sendVideoBitrateMedian);
      }
      this.metricCollector.close();
      this.metricCollector = null;
    }
  }

  // https://www.notion.so/hpcnt/Azar-WebRTC-Stat-6beae68736dd44faaaf9941ed3ebd967
  public getWebRTCMatchStats() {
    const params = {
      rp: null /* Web 에서 알 수 없음 */,
      pp: null /* Web 에서 알 수 없음 */,
      sendMaxBandwidth: this.maxVideoBandwidthKbps,
      thermalStatus: null /* Web 에서 알 수 없음 */,
      requestedSendVideoHeight: this.matchVideoHeight,
      isPowerSaveMode: null /* Web 에서 알 수 없음 */,
      sendStartBitrate: BitrateMovingAverageCalculator.instance.getAverageBitrateKBps(),
    };
    if (this.metricCollector == null) {
      return params;
    }
    return this.metricCollector.summary().makeParams({
      ...params,
    });
  }

  public getStats() {
    return this.peerConnection?.getStats();
  }
}
