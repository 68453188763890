import { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import color from 'src/styles/color';
import { GemProduct } from 'src/types/Payments';
import { getProductCalcGem, getProductPriceTextWithCurrency } from 'src/utils/payments';
import { ProductItem, ProductImage, ProductImageWrapper, ProductLabelWrapper, ProductPriceWrap, PriceText, ProductNameText } from './styles';
import useClickProductItem from './useClickProductItem';
const GemAmountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const BonusGemAmountText = styled.p<{
  colorCode: string;
}>`
  font-size: 14px;
  color: ${({
  colorCode
}) => colorCode};
  font-weight: 400;
`;
interface Props {
  gemProduct: GemProduct;
}

/**
 * 결제 수수료 관련 영향으로 웹에서 자동적으로 적용되는 할인율
 */
const GemProductItem: FC<Props> = ({
  gemProduct
}) => {
  const {
    handleClickProduct
  } = useClickProductItem(gemProduct);
  const {
    t
  } = useTranslation();
  const gemCount = getProductCalcGem(gemProduct);
  const bonusGemAmounts = [{
    key: 'bonus',
    count: gemCount.bonus,
    color: color.accentH1,
    text: gemCount.bonusText
  }, {
    key: 'promo',
    count: gemCount.promo,
    color: color.accentF1,
    text: gemCount.promoText
  }];
  const priceText = getProductPriceTextWithCurrency(gemProduct);
  return <ProductItem data-amount={gemCount.num} data-testid={'Payments-GemProduct'} onClick={handleClickProduct} data-sentry-element="ProductItem" data-sentry-component="GemProductItem" data-sentry-source-file="GemProductItem.tsx">
      <ProductImageWrapper backgroundColor={gemProduct.backgroundColor} data-sentry-element="ProductImageWrapper" data-sentry-source-file="GemProductItem.tsx">
        <ProductImage src={gemProduct.iconUrl} alt='gemProduct' data-sentry-element="ProductImage" data-sentry-source-file="GemProductItem.tsx" />
      </ProductImageWrapper>
      <ProductLabelWrapper data-sentry-element="ProductLabelWrapper" data-sentry-source-file="GemProductItem.tsx">
        <GemAmountWrapper data-sentry-element="GemAmountWrapper" data-sentry-source-file="GemProductItem.tsx">
          <ProductNameText data-sentry-element="ProductNameText" data-sentry-source-file="GemProductItem.tsx">{`${gemCount.baseText} ${t('common__gems')}`}</ProductNameText>
          {bonusGemAmounts.filter(v => v.count).map(item => <BonusGemAmountText key={item.key} colorCode={item.color}>
                {`+ ${item.text} ${t('common__gems')}`}
              </BonusGemAmountText>)}
        </GemAmountWrapper>
        <ProductPriceWrap data-sentry-element="ProductPriceWrap" data-sentry-source-file="GemProductItem.tsx">
          <PriceText data-sentry-element="PriceText" data-sentry-source-file="GemProductItem.tsx">{priceText}</PriceText>
        </ProductPriceWrap>
      </ProductLabelWrapper>
    </ProductItem>;
};
export default GemProductItem;