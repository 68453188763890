import { useCallback, useEffect, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { UpdatedTosApprovalModal } from 'src/components/LegalUpdateModal';
import useLogin from 'src/hooks/useLogin';
import { ConsentApiList } from 'src/stores/auth/apis';
import { isUserJustSignedUpAtom, userDataAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { OrderedModalHookType } from './types';
export const useUpdatedConsentModal: OrderedModalHookType = () => {
  const userData = useAtomValue(userDataAtom);
  const showModal = useSetAtom(showModalAtom);
  const {
    isLogin
  } = useLogin();
  const [isResolved, setIsResolved] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const isUserSignedUp = useAtomValue(isUserJustSignedUpAtom);
  const isFetchingUpdates = useIsFetching({
    queryKey: [ConsentApiList.GetConsentRevision]
  });
  useEffect(() => {
    if (!shouldShow) return;

    /**
     * 서버의 비동기 이슈로 인해 회원가입 시점에 약관 동의 기록이 반영되지 않았을 수 있음
     * 방금 회원가입 시점을 마친 유저는 약관 갱신을 체크할 필요가 없으므로 팝업 노출 제약 (앱클라와 동일)
     */
    if (isLogin && isUserSignedUp) {
      setIsResolved(true);
      return;
    }
    if (isFetchingUpdates) {
      setIsResolved(false);
      return;
    }
    const updatedConsentWithPopup = userData?.consentsRequired?.find(consent => consent.consentRevisionPopup);
    if (!updatedConsentWithPopup) {
      setIsResolved(true);
      return;
    }

    // consent가 mutable하기 때문에 조건문으로 consent에서 바로 검사 후 props 전달 시 타입에러 발생
    const {
      consentRevisionPopup,
      ...rest
    } = updatedConsentWithPopup;
    if (!consentRevisionPopup) {
      setIsResolved(true);
      return;
    }
    showModal({
      key: ModalType.TOS_RE_APPROVAL,
      component: () => <UpdatedTosApprovalModal consent={{
        ...rest,
        consentRevisionPopup
      }} />
    });
  }, [isFetchingUpdates, isLogin, isUserSignedUp, shouldShow, showModal, userData?.consentsRequired]);
  useEffect(() => {
    // 로그아웃 시, isResolved를 false로 초기화
    if (!isLogin) {
      setIsResolved(false);
    }
  }, [isLogin]);
  return {
    isResolved,
    show: useCallback(() => setShouldShow(true), [])
  };
};