import { useEffect, useMemo } from 'react';

import differenceInSeconds from 'date-fns/differenceInSeconds';
import { useAtom, useSetAtom } from 'jotai';

import { useGetConsentRevisionQuery } from 'src/hooks/useConsentQuery';
import { userDataAtom } from 'src/stores/auth/atoms';
import { lastGetLegalUpdatesDateAtom } from 'src/stores/user/atom';

const CHECK_LEGAL_UPDATE_MIN_INTERVAL_SECONDS = 60;

const useCheckLegalUpdates = () => {
  const [lastGetLegalUpdatesDate, setLastGetLegalUpdatesDate] = useAtom(
    lastGetLegalUpdatesDateAtom
  );
  const setUserData = useSetAtom(userDataAtom);

  /**
   * 잦은 새로고침 시마다 호출은 서버에 부하를 줄 수 있기에 최소 간격 부여
   */
  const shouldCheckLegalUpdates = useMemo(() => {
    if (lastGetLegalUpdatesDate === null) return true;

    const now = new Date();
    const diffInSeconds = differenceInSeconds(now, lastGetLegalUpdatesDate);
    return diffInSeconds >= CHECK_LEGAL_UPDATE_MIN_INTERVAL_SECONDS;
  }, [lastGetLegalUpdatesDate]);

  const { data } = useGetConsentRevisionQuery({
    enabled: shouldCheckLegalUpdates,
  });

  useEffect(() => {
    if (!data?.data) return;

    setUserData((prev) => {
      if (!prev) return prev;

      const {
        data: {
          result: { consentsRequired, legalNoticeRevisionPopups },
        },
      } = data;

      return {
        ...prev,
        consentsRequired,
        legalNoticeRevisionPopups,
      };
    });
    setLastGetLegalUpdatesDate(new Date());
  }, [data, setUserData, setLastGetLegalUpdatesDate]);
};

export default useCheckLegalUpdates;
