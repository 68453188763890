import { useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { azarTokenAtom, loginOAuthAtom, removeTokenAtom } from 'src/stores/auth/atoms';
export default function useLogin() {
  const logout = useSetAtom(removeTokenAtom);
  const loginOAuth = useSetAtom(loginOAuthAtom);
  const azarToken = useAtomValue(azarTokenAtom);
  const isLogin = useMemo(() => !!azarToken, [azarToken]);
  return {
    logout,
    loginOAuth,
    isLogin
  };
}