import { maxFramerate } from 'src/types/Match';
import { ControlledPromise } from 'src/utils/controlled-promise';
import { attachMediaElement } from 'src/utils/media/view';

import MediaStreamPipe from './base';

class MediaStreamPipeManager extends MediaStreamPipe {
  private timerId: ReturnType<typeof setInterval> | null = null;

  public firstFrameRenderedPromise = new ControlledPromise<void>();

  private markFirstFrameRenderedTimerId: ReturnType<typeof setTimeout> | null = null;

  private canvasAnimationId: number | null = null;

  private supportVideoFrameCallback = 'requestVideoFrameCallback' in HTMLVideoElement.prototype;

  public pipes: MediaStreamPipe[] = [];

  public pipe(pipe: MediaStreamPipe) {
    this.pipes.push(pipe);
    return this;
  }

  public unpipe(target: MediaStreamPipe) {
    this.stopRendering();
    this.pipes = this.pipes.filter((pipe) => pipe !== target);
    if (this.videoEl) {
      this.start();
    } else {
      this.dispose();
    }
  }

  public dispose(isPeerPipe = false) {
    if (this.mediaStream || this.videoEl) {
      this.stopRendering();
    }
    if (isPeerPipe) {
      this.pipes.forEach((pipe) => {
        pipe.dispose();
      });
      if (this.canvasEl) {
        this.canvasEl.getContext('2d')?.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      }
    }
    this.pipes = [];
    this.videoEl = null;
  }

  private renderCheckBackground() {
    if (document.hidden && !this.timerId) {
      this.timerId = setInterval(this.render.bind(this), 1000 / maxFramerate);
    }

    this.render();
  }

  private handleChangeVisibility() {
    if (!document?.hidden && this.timerId) {
      this.clearLoop();
      this.render();
      return;
    }

    this.renderCheckBackground();
  }

  public stopRendering() {
    this.clearLoop();
    window.removeEventListener('visibilitychange', this.handleChangeVisibility);
    if (this.videoEl) {
      this.videoEl.removeEventListener('resize', this.handleResizeSourceTrack);
    }
    this.pipes.forEach((pipe, i) => {
      pipe.stopRendering();
      if (i !== 0) {
        pipe.dispose();
      }
    });
  }

  protected clearLoop() {
    if (this.canvasAnimationId) {
      if (this.supportVideoFrameCallback) {
        this.videoEl?.cancelVideoFrameCallback(this.canvasAnimationId);
      } else {
        cancelAnimationFrame(this.canvasAnimationId);
      }
      this.canvasAnimationId = null;
    }
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  private handleResizeSourceTrack() {
    if (!this.videoEl?.srcObject) {
      return;
    }
    if (this.videoEl.srcObject instanceof MediaStream) {
      const videoTrack = this.videoEl.srcObject.getVideoTracks()?.[0];
      if (videoTrack) {
        const { width = 0, height = 0 } = videoTrack.getSettings();
        this.videoEl.width = width;
        this.videoEl.height = height;
        this.renderVideo();
      }
    }
  }

  public start() {
    if (!this.videoEl) {
      throw new Error('Cannot start MediaStreamPipe. videoEl not set');
    }
    window.addEventListener('visibilitychange', this.handleChangeVisibility.bind(this));
    const targetCanvas = this.canvasEl || document?.createElement('canvas');
    this.canvasEl = targetCanvas;
    if (navigator.userAgent.includes('Firefox')) {
      // getContext 안하면 captureStream 에러남
      // reference: https://bugzilla.mozilla.org/show_bug.cgi?id=1572422
      targetCanvas.getContext('2d');
    }
    this.mediaStream = targetCanvas.captureStream();
    this.videoEl.addEventListener('resize', this.handleResizeSourceTrack.bind(this));

    this.pipes.reduce((prevVideo, pipe, i) => {
      const isLast = i === this.pipes.length - 1;
      if (isLast) {
        pipe.setEl(prevVideo, targetCanvas);
        return prevVideo;
      } else {
        pipe.setEl(prevVideo);
        const mediaStream = pipe.captureMediaStream();
        if (!mediaStream) {
          return prevVideo;
        }
        const nextVideo = document?.createElement('video');
        nextVideo.addEventListener('resize', this.handleResizeSourceTrack.bind(this));
        attachMediaElement(nextVideo, mediaStream);
        return nextVideo;
      }
    }, this.videoEl);
    this.renderVideo();
    return {
      mediaStream: this.mediaStream,
      width: targetCanvas.width,
      height: targetCanvas.height,
    };
  }

  public renderVideo() {
    this.clearLoop();
    this.pipes.forEach((pipe) => {
      pipe.renderVideo();
    });

    this.renderCheckBackground();
  }

  public async render() {
    const canvasEl = this.canvasEl;
    const videoEl = this.videoEl;
    if (!canvasEl || !videoEl) {
      return;
    }

    if (!this.videoEl) {
      this.stopRendering();
      return;
    }

    for (const pipe of this.pipes) {
      await pipe.render.bind(pipe)();
    }

    if (!this.firstFrameRenderedPromise.ended && this.markFirstFrameRenderedTimerId === null) {
      this.markFirstFrameRenderedTimerId = setTimeout(() => {
        /**
         * canvas draw 직후에는 캡쳐 등의 로직이 정상 동작하지 않을 수 있음
         * 약간의 딜레이 이후에 최초 프레임 렌더링되었다고 마킹
         */
        this.firstFrameRenderedPromise.resolve();
        this.markFirstFrameRenderedTimerId = null;
      }, 100);
    }

    if (!this.timerId && this.videoEl) {
      const renderWithThisBind = this.render.bind(this);
      if (this.supportVideoFrameCallback) {
        this.canvasAnimationId = this.videoEl.requestVideoFrameCallback(renderWithThisBind);
      } else {
        this.canvasAnimationId = requestAnimationFrame(renderWithThisBind);
      }
    }
  }
}

export default MediaStreamPipeManager;
