import theme from 'src/styles/theme';

export type ButtonColor = 'primary' | 'secondary' | 'tertiary';
export type ButtonVariant = 'contained' | 'outlined' | 'text';
export type ButtonStatus = '&' | '&:hover' | '&:active' | '&:disabled';

type ThemedColorMap = {
  [status in Partial<ButtonStatus>]?: {
    [variant in ButtonVariant]?: {
      [buttonColor in ButtonColor]?: (typeof theme.color)[keyof typeof theme.color];
    };
  };
};

export const buttonColorMap: ThemedColorMap = {
  '&': {
    contained: {
      primary: theme.color.primary1,
      secondary: theme.color.surface12,
      tertiary: theme.color.surface5,
    },
    outlined: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
  '&:hover': {
    contained: {
      primary: theme.color.primary2,
      secondary: theme.color.surface10,
      tertiary: theme.color.surface4,
    },
    outlined: {
      primary: theme.color.surface4,
      secondary: theme.color.surface4,
      tertiary: theme.color.surface4,
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
  '&:active': {
    contained: {
      primary: theme.color.primary2,
      secondary: theme.color.surface10,
      tertiary: theme.color.surface4,
    },
    outlined: {
      primary: theme.color.surface9,
      secondary: theme.color.surface9,
      tertiary: theme.color.surface9,
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
  '&:disabled': {
    contained: {
      primary: theme.color.surface5,
      secondary: theme.color.surface5,
      tertiary: theme.color.surface5,
    },
    outlined: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
};

export const textColorMap: ThemedColorMap = {
  '&': {
    contained: {
      primary: theme.color.contentInverse,
      secondary: theme.color.contentBright,
      tertiary: theme.color.content12,
    },
    outlined: {
      primary: theme.color.content12,
      secondary: theme.color.content12,
      tertiary: theme.color.content12,
    },
    text: {
      primary: theme.color.content9,
      secondary: theme.color.content9,
      tertiary: theme.color.content9,
    },
  },
  '&:hover': {
    text: {
      primary: theme.color.content9,
      secondary: theme.color.content9,
      tertiary: theme.color.content9,
    },
  },
  '&:active': {
    text: {
      primary: theme.color.content9,
      secondary: theme.color.content9,
      tertiary: theme.color.content9,
    },
  },
  '&:disabled': {
    contained: {
      primary: theme.color.content7,
      secondary: theme.color.content7,
      tertiary: theme.color.content7,
    },
    outlined: {
      primary: theme.color.content7,
      secondary: theme.color.content7,
      tertiary: theme.color.content7,
    },
    text: {
      primary: theme.color.content5,
      secondary: theme.color.content5,
      tertiary: theme.color.content5,
    },
  },
};

export const borderColorMap: ThemedColorMap = {
  '&': {
    outlined: {
      primary: theme.color.surface9,
      secondary: theme.color.surface9,
      tertiary: theme.color.surface9,
    },
  },
  '&:hover': {
    outlined: {
      primary: theme.color.surface9,
      secondary: theme.color.surface9,
      tertiary: theme.color.surface9,
    },
  },
  '&:active': {
    outlined: {
      primary: theme.color.surface9,
      secondary: theme.color.surface9,
      tertiary: theme.color.surface9,
    },
  },
  '&:disabled': {
    outlined: {
      primary: theme.color.surface9,
      secondary: theme.color.surface9,
      tertiary: theme.color.surface9,
    },
  },
};

export const ColorMap: {
  [key: string]: ThemedColorMap;
} = {
  'background-color': buttonColorMap,
  color: textColorMap,
  'border-color': borderColorMap,
};
