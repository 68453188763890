import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ theme }) => theme.screenSize.mobile} {
    width: var(--100vw);
  }
`;

export const DescriptionContainer = styled.div`
  padding: 24px;
  width: 100%;
  flex: 1;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.color.contentDeep};
  font-size: 30px;
  text-overflow: ellipsis;
  font-weight: 700;
  margin-bottom: 8px;
  word-wrap: break-word;
`;
export const Description = styled.p`
  color: ${({ theme }) => theme.color.content8};
  font-size: 14px;
  word-wrap: break-word;
`;
export const ButtonWrap = styled.div`
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const LinkText = styled.a`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.content9};
  cursor: pointer;
  text-align: center;
  text-decoration: none;
`;
