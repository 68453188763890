import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import QueryString from 'qs';

import { eventMutateAtom } from 'src/stores/event/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import { UTMParamsAtom } from 'src/stores/utm/atoms';
import { API_NAME, EVENT_NAME, EVENT_TYPE, getErrorStatus } from 'src/types/Event';
import { PurchaseCompleteMessage, WindowMessageType } from 'src/types/message';
import {
  BillingInfoRequest,
  BillingInfoResponse,
  GemProductsResponse,
  getErrorCode,
  isPurchasableProductModule,
  OrderInfo,
  PaymentPopupInfo,
  PaymentsCompleteRequest,
  PaymentsReservation,
  PaymentsReserveResponse,
} from 'src/types/Payments';
import { Platform } from 'src/types/Platform';
import { ToastType } from 'src/types/Toast';
import { client } from 'src/utils/api';
import { isAxiosError, parseError } from 'src/utils/error';
import { getAtomWithSessionStorage } from 'src/utils/localStorageUtils';
import { getProductCalcGem, getProductCurrency, getProductPrice } from 'src/utils/payments';

import { deletePaymentMethodAPI } from './apis';
import { GetPurchasableSubscriptionProducts, DeletePaymentMethodRequest } from './types';

export const PAYMENT_POPUP_INFO_KEY = 'paymentInfo';
export const paymentPopupInfoAtom = getAtomWithSessionStorage<PaymentPopupInfo | null>(
  PAYMENT_POPUP_INFO_KEY,
  null
);

export const paymentsErrorAtom = atom<{
  errorCode: string;
} | null>(null);

export const billingInfoAtom = atom<BillingInfoResponse | null>(null);

export const getBillingInfoAtom = atom(
  null,
  async (_get, set, { productId, ...params }: BillingInfoRequest) => {
    const { data: billingInfo } = await client.get<BillingInfoResponse>(
      `/api/payments/billingInfo/${productId}`,
      { params }
    );
    set(billingInfoAtom, billingInfo);
  }
);

export const gemProductsAtom = getAtomWithSessionStorage<GemProductsResponse | null>(
  'gemProducts',
  null
);

export const getGemProductsFailedAtom = atom(false);
export const getGemProductsAtom = atom(null, async (_get, set) => {
  try {
    const platform = window.AzarJs?.getPlatform() || Platform.WebClient;
    const res = await client.get<GemProductsResponse>(
      '/api/shop/gem-products' +
        QueryString.stringify(
          { platform }, // MAC 예외처리
          { addQueryPrefix: true }
        )
    );
    set(gemProductsAtom, res.data);
    set(getGemProductsFailedAtom, false);
  } catch (_e) {
    set(getGemProductsFailedAtom, true);
  }
});

export const resetGemProductsAtom = atom(null, (get, set) => {
  set(gemProductsAtom, null);
});

export const paymentsReservationAtom = atom<PaymentsReservation | null>(null);

export const reservePaymentAtom = atom(null, async (get, set) => {
  const paymentMethod = get(gemProductsAtom)?.paymentMethod;
  const paymentPopupInfo = get(paymentPopupInfoAtom);
  const product = paymentPopupInfo?.product;
  if (!paymentMethod || !product) {
    return;
  }
  const body = {
    paymentMethod,
    productId: product.productId,
    productName: product.productName,
    amount: getProductPrice(product).toString(),
    currency: getProductCurrency(product),
    utmParameter: get(UTMParamsAtom),
  };
  client
    .post<PaymentsReserveResponse>('/api/payments/reserve', body)
    .then((res) => {
      set(paymentsReservationAtom, {
        ...res.data,
        paymentMethod,
      } as PaymentsReservation);
    })
    .catch((e) => {
      const errorCode = getErrorCode(e.response?.data?.code);
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.PAYMENT,
        eventName: EVENT_NAME.API_ERROR,
        eventParams: {
          payment_method: body.paymentMethod,
          api_name: API_NAME.RESERVE_PAYMENT,
          error_status_code: e.response.status,
          error_response: e.response.data,
        },
        options: { status: getErrorStatus(errorCode) },
      });

      set(paymentsErrorAtom, { errorCode });
    });
});

export const completedReservationAtom = atom<OrderInfo | null>(null);
export const isPaymentSuccessAtom = selectAtom(completedReservationAtom, (a) => !!a);

export const sendFailureEventAtom = atom(
  null,
  (get, set, { body, e }: { body: PaymentsCompleteRequest; e: AxiosError }) => {
    const errorCode = getErrorCode(e.response?.data?.code);
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.PAYMENT,
      eventName: EVENT_NAME.API_ERROR,
      eventParams: {
        payment_method: body.paymentMethod,
        api_name: API_NAME.COMPLETE_PAYMENT,
        error_status_code: e.response?.status,
        error_response: e.response?.data,
      },
      options: { status: getErrorStatus(errorCode) },
    });
  }
);

export const completeReservationAtom = atom(
  null,
  async (get, set, body: PaymentsCompleteRequest) => {
    const paymentPopupInfo = get(paymentPopupInfoAtom);

    if (!paymentPopupInfo) {
      Sentry.captureMessage('Payment popup info missing after completing purchase', {
        extra: { ...body },
      });
    }

    const origins = paymentPopupInfo?.origins ?? [];
    const purchasedProduct = paymentPopupInfo?.product;

    client
      .post<OrderInfo>('/api/payments/complete', body)
      .then((res) => {
        if (res.data) {
          set(eventMutateAtom, {
            eventType: EVENT_TYPE.PURCHASE,
            eventName: EVENT_NAME.PURCHASE_COMPLETE,
            eventParams: {
              action_category: 'action',
              page: 'anywhere',
              target: 'purchase_complete',
              payment_method: body.paymentMethod,
              value: body.amount,
              currency: body.currency,
              transaction_id: body.orderId,
              quantity: getProductCalcGem(res.data.product).num,
              origin: origins,
              product_id: purchasedProduct?.productId,
              tab: paymentPopupInfo?.originTab,
            },
          });
          set(completedReservationAtom, res.data);
        }
      })
      .catch((e) => {
        set(sendFailureEventAtom, { body, e });
      });

    if (purchasedProduct && window.opener) {
      // 메인 창에 결제 완료를 공유
      const message: PurchaseCompleteMessage = {
        type: WindowMessageType.PurchaseComplete,
        productModuleType: isPurchasableProductModule(purchasedProduct)
          ? purchasedProduct.moduleType
          : undefined,
      };
      window.opener.postMessage(message);
    }
  }
);

export const purchasableSubscriptionProductsAtom = atom<
  GetPurchasableSubscriptionProducts['subscriptionGroups'] | void
>(undefined);
export const getPurchasableSubscriptionProductsAtom = atom(null, async (get, set) => {
  const { data } = await client.get<GetPurchasableSubscriptionProducts>(
    '/api/v1/subscriptions/purchasable-products'
  );
  set(purchasableSubscriptionProductsAtom, data.subscriptionGroups);
});

export const deletePaymentMethodAtom = atom(
  null,
  (get, set, params: DeletePaymentMethodRequest) => {
    return deletePaymentMethodAPI(params).catch((e) => {
      if (!isAxiosError(e)) throw e;
      if (e.response?.status === 500) {
        set(showToastAtom, {
          message: parseError(e as Error),
          type: ToastType.ERROR,
        });
      }
      throw e;
    });
  }
);

export const isPurchasedStarterPackageAtom = atom(false);
