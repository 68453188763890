import { QueryObserverOptions } from '@tanstack/query-core';
import { AxiosError, AxiosResponse } from 'axios';
import { atom, WritableAtom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import { Subject } from 'rxjs';

import { azarTokenAtom } from 'src/stores/auth/atoms';
import { client } from 'src/utils/api';

export const atomWithPipes = <T, Parameter extends unknown[]>(
  value: T,
  setter: WritableAtom<T, Parameter, void | Promise<void>>['write'],
  operators: any[]
) => {
  const $s = new Subject();
  $s.pipe(
    ...(operators.map((pipe, i) => {
      if (i === operators.length - 1) {
        return pipe(async (x: () => Promise<void>) => {
          await x();
        });
      } else {
        return pipe;
      }
    }) as [])
  ).subscribe();
  const newAtom = atom(
    value,
    async (get, set, ...data: Parameter) =>
      new Promise<void>((resolve, reject) => {
        const fn = async () => {
          try {
            await setter(get, set, ...data);
            resolve();
          } catch (e) {
            reject(e);
          }
        };

        $s.next(fn);
      })
  );

  return newAtom;
};

/*
Usage
const atom = atomRestQuery<DataType, Parameter>({path:'/path', params});
const {data, isLoading} = useAtomValue(atom);
미로그인 상태에서 사용시 enabled true로 override 필요
*/
export const atomRestQuery = <TParams, TResponse, TError = void>({
  path,
  params,
  options,
}: {
  path: string;
  params?: TParams;
  options?: QueryObserverOptions<
    AxiosResponse<TResponse>,
    AxiosError<TError>,
    AxiosResponse<TResponse>
  >;
}) =>
  atomWithQuery<AxiosResponse<TResponse>, AxiosError<TError>, AxiosResponse<TResponse>>((get) => ({
    ...options,
    queryKey: [path, params],
    queryFn: () => {
      return client.get<TResponse>(path, {
        params: params ? { ...params } : null,
      });
    },
    enabled: !!get(azarTokenAtom),
  }));
