import { useCallback, useEffect, useRef } from 'react';

import getDeviceInfo from 'src/utils/device/info';

const useMobileIdleTimer = (timeoutDuration: number, onIdle: () => void) => {
  const { isMobile } = getDeviceInfo();
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const lastPosition = useRef([0, 0]);

  const resetTimer = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      onIdle();
    }, timeoutDuration);
  }, [onIdle, timeoutDuration]);

  const handleTouchStart = (event: TouchEvent) => {
    const [{ clientX, clientY }] = event.touches;
    lastPosition.current = [clientX, clientY];
  };

  // 스크롤 이벤트는 제어
  const handleTouchEnd = useCallback(
    (event: TouchEvent) => {
      const SCROLL_THRESHOLD = 3;
      const [lastX, lastY] = lastPosition.current;
      const [{ clientX, clientY }] = event.changedTouches;

      if (
        Math.abs(lastX - clientX) > SCROLL_THRESHOLD ||
        Math.abs(lastY - clientY) > SCROLL_THRESHOLD
      ) {
        // x, y값에 변동이 있으면 스크롤 이벤트로 리셋 타이머 제외
        return;
      }

      resetTimer();
    },
    [resetTimer]
  );

  const startIdleDetection = () => {
    if (!isMobile) return;

    window.addEventListener('touchstart', handleTouchStart, { passive: true });
    window.addEventListener('touchend', handleTouchEnd, { passive: true });

    resetTimer();
  };

  const stopIdleDetection = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    }
  }, [handleTouchEnd]);

  useEffect(() => {
    return () => {
      stopIdleDetection();
    };
  }, [timeoutDuration, onIdle, stopIdleDetection]);

  return {
    startIdleDetection,
    stopIdleDetection,
  };
};

export default useMobileIdleTimer;
