import styled from '@emotion/styled';

import ModalLayoutBase from 'src/components/ModalLayout';

export const AuthErrorModalLayout = styled(ModalLayoutBase)`
  min-height: 516px;
  max-height: 100%;
  width: 500px;
  overflow: hidden;
`;

export const AuthErrorMain = styled.div`
  flex-grow: 1;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.surface2};
  width: 100%;
  padding: 32px 24px;
  ${({ theme }) => theme.screenSize.mobile} {
    justify-content: center;
  }
`;

export const AuthErrorTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.color.contentDeep};
  margin-bottom: 12px;
  text-align: center;
`;

export const AuthErrorDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: ${({ theme }) => theme.color.contentDeep};
  text-align: center;
`;

export const AuthErrorImg = styled.img`
  margin-bottom: 20px;
`;
