import { DecoFilterResponse, UserEffectSetting } from 'src/types/Deco';
import { client, rpcClient } from 'src/utils/api';

export const getDecoEffectListAPI = () => client.get<DecoFilterResponse>('/api/v1/deco/effects');

export const reportInMatchEffectUsageAPI = (
  matchId: string,
  userEffectSetting: UserEffectSetting
) =>
  rpcClient<[string, UserEffectSetting]>('reportInMatchEffectUsage')([matchId, userEffectSetting]);
