import { ArrayElement } from './utils';

export enum DecoGroupName {
  ETC = 'DECO_WEB_ETC',
  BACKGROUND = 'DECO_WEB_BACKGROUND',
  STYLE = 'DECO_WEB_STYLE',
  BEAUTY = 'DECO_WEB_BEAUTY',
  FILTER = 'DECO_WEB_FILTER',
  GESTURE = 'DECO_WEB_GESTURE',
}
export const decoEffectGroupNameCrowdinKey: Record<DecoGroupName, string> = {
  [DecoGroupName.ETC]: 'deco_studio_subtitle_blur',
  [DecoGroupName.BACKGROUND]: 'deco_studio_subtitle_bg',
  [DecoGroupName.STYLE]: 'deco_studio_subtitle_style',
  [DecoGroupName.BEAUTY]: 'deco_studio_subtitle_beauty',
  [DecoGroupName.FILTER]: 'deco_studio_subtitle_filter',
  [DecoGroupName.GESTURE]: 'deco_studio_subtitle_gesture',
};

// 백오피스에서 등록한 EffectID와 crowdin key를 매핑
export const GESTURE_EFFECT_TRANSLATION_KEYS: Record<
  GestureEffectId,
  {
    title: string;
    description: string;
  }
> = {
  DECO_WEB_GESTURE_SMILE: {
    title: 'deco_studio_gesture_title_smile',
    description: 'deco_studio_gesture_desc_smile',
  },
  DECO_WEB_GESTURE_WINK: {
    title: 'deco_studio_geustre_title_wink',
    description: 'deco_studio_gesture_desc_wink',
  },
  DECO_WEB_GESTURE_WAVE: {
    title: 'deco_studio_gesture_title_wave',
    description: 'deco_studio_gesture_desc_wave',
  },
};

export const EFFECT_STRING_GUIDE_TRANSLATION_KEYS: Record<GestureEffectId, string> = {
  DECO_WEB_GESTURE_SMILE: 'deco_studio_gesture_guide_smile',
  DECO_WEB_GESTURE_WINK: 'deco_studio_gesture_guide_wink',
  DECO_WEB_GESTURE_WAVE: 'deco_studio_gesture_guide_wave',
};

export const enum EffectRendererName {
  STRETCH = 'Stretch',
  LIQUIFY = 'Liquify',
  MASK = 'Mask',
  TWO_D_STICKER = '2DSticker',
  THREE_D_STICKER = '3DSticker',
  SKIN_SMOOTH = 'SkinSmooth',
  BACKGROUND_BLUR = 'BackgroundBlur',
  BACKGROUND = 'Background',
  LUT_FILTER = 'LutFilter',
  GESTURE = 'Gesture',
  NONE = 'None',
}

export const enum ClientEffect {
  NONE = 'DECO_WEB_ETC_NOEFFECT',
  BLUR = 'DECO_WEB_ETC_BLUR',
}

export const enum CanvasType {
  MAIN = 'MAIN',
  DECO = 'DECO',
}

export const EFFECT_NONE: DecoEffect = {
  effectId: ClientEffect.NONE,
  thumbnailImageUrl: 'images/deco/none.svg',
  newBadgeEnabled: false,
  attributes: {
    directLightEnable: false,
    occludingEnable: false,
    effectRenderer: [EffectRendererName.NONE],
  },
};

export interface DecoFilterResponse {
  code: number;
  result: {
    effectGroups: DecoEffectGroup[];
  };
}

export interface DecoEffectGroup<T extends DecoGroupName = DecoGroupName> {
  groupId: T;
  effects: T extends DecoGroupName.GESTURE ? GestureEffect[] : DecoEffect[];
}

export interface DecoEffect<T extends string = string> {
  effectId: T;
  thumbnailImageUrl: string;
  resource?: DecoResource;
  attributes?: DecoAttribute;
  newBadgeEnabled: boolean;
}

export type GestureEffect = DecoEffect<GestureEffectId>;

// BackOffice에서 등록한 Gesture Effect의 ID. qa와 prod가 동일해야한다.
export const GESTURE_EFFECT_ID_LIST = [
  'DECO_WEB_GESTURE_SMILE',
  'DECO_WEB_GESTURE_WINK',
  'DECO_WEB_GESTURE_WAVE',
] as const;

export type GestureEffectId = ArrayElement<typeof GESTURE_EFFECT_ID_LIST>;

// https://www.notion.so/hpcnt/API-Specification-68f92aa8563147249a4979df3f061883

export interface DecoAttribute {
  directLightEnable: boolean;
  occludingEnable: boolean;
  effectRenderer: EffectRendererName[];
}

export interface DecoResource {
  resourceUrl: string;
  md5Hash: string;
  encryptionKey: string;
}

export interface DecoEffectStrength {
  effectId: string;
  strength: number;
}

export interface UserEffectSetting {
  effectIdList: string[];
  decoEffectStrengths: DecoEffectStrength[];
  facialRecognitionValue: number;
}

export const enum DecoUnsupportReason {
  WEBGL2_NOT_SUPPORTED = 'webgl2_not_supported',
  MEDIAPIPE_LOAD_FAILED_FACE_LANDMARKER = 'mediapipe_load_fail_face_landmark',
  MEDIAPIPE_LOAD_FAILED_SELFIE_SEGMENTATION = 'mediapipe_load_fail_selfie_seg',
  THREE_MODEL_PARSE_FAILED = '3d_model_parse_failed',
}

/**
 * mediapipe가 랜더링환경을 설정한 것으로부터 가져왔습니다. 아래 코드 확인
 * https://github.com/google-ai-edge/mediapipe/blob/67d14a5aa0a53cfeec33426b8d5ce1a180b30949/mediapipe/graphs/face_effect/face_effect_gpu.pbtxt#L62
 */
export const DEFAULT_PERSPECTIVE_CAMERA_SETTINGS = {
  fov: 63,
  near: 0.1,
  far: 10000,
};
