import { FC } from 'react';

import IcDiscover from 'src/assets/images/icDiscover.svg';
import IcHistory from 'src/assets/images/icHistory.svg';
import IcLive from 'src/assets/images/icLive.svg';
import IcMessage from 'src/assets/images/icMessageFill.svg';
import { Routes } from 'src/router/route';
import { Pathname } from 'src/router/types';

export const TAB_NAME = ['live', 'mirror', 'about', 'history', 'blog', 'message'] as const;

export type TabName = (typeof TAB_NAME)[number];

export interface TabConfig {
  route: keyof typeof Routes;
  icon?: FC;
  label: string;
  name: TabName;
  pathnames: Pathname[];
}

export const TAB_CONFIGS = [
  {
    route: '/',
    icon: IcDiscover,
    label: 'Video Chat',
    name: 'mirror',
    pathnames: ['/', '/[locale]'],
  },
  {
    route: '/live',
    icon: IcLive,
    label: 'Live',
    name: 'live',
    pathnames: [
      '/live',
      '/live/host',
      '/live/publish',
      '/[locale]/live',
      '/[locale]/live/host',
      '/[locale]/live/publish',
    ],
  },
  {
    route: '/history',
    icon: IcHistory,
    label: 'History',
    name: 'history',
    pathnames: ['/history', '/[locale]/history'],
  },
  {
    route: '/about',
    label: 'About',
    name: 'about',
    pathnames: ['/about', '/[locale]/about'],
  },
  {
    route: '/blog',
    label: 'Blog',
    name: 'blog',
    pathnames: [
      '/blog',
      '/blog/[sectionName]/[articleTitle]',
      '/[locale]/blog',
      '/[locale]/blog/[sectionName]/[articleTitle]',
    ],
  },
  {
    route: '/messages',
    icon: IcMessage,
    label: 'Messages',
    name: 'message',
    pathnames: [
      '/messages',
      '/[locale]/messages',
      '/messages/[roomId]',
      '/[locale]/messages/[roomId]',
    ],
  },
] satisfies TabConfig[];

/**
 * EventLog의 Tab 개념과 SelectTab API의 Tab개념이 다름
 * - EventLog = mirror / match를 구분하지 않음
 * - SelectTab API = mirror / match를 구분함
 *
 * 이 차이를 개선하는 고도화는 추후 진행
 * [WEB-3601:](https://hyperconnect.atlassian.net/browse/WEB-3601) 이벤트로그의 page/tab 최신화 및 모든 이벤트에 적용
 */
export interface SelectTabRequest {
  tabName: 'MIRROR' | 'MATCH' | 'LIVE' | 'HISTORY' | 'ABOUT' | 'BLOG' | 'MESSAGE';
}
