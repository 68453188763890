import { Platform } from 'src/types/Platform';

export interface VerificationsRequest {
  countryPrefixNumber: string;
  localeLanguageCode: string;
  phoneNumber: string;
  platform: Platform;
}

export interface VerifyPreparationRequest {
  languageCode: string;
  platform: Platform;
}

export interface SendVerifyCodeToUserResponse {
  formattedPhoneNumber: string;
}
export interface VerifyAccountRequest {
  localeInfo: {
    countryCode: null;
    languageCode: string;
  };
  phoneVerification: {
    verifyCode: string;
    formattedPhoneNumber: string;
  };
  platform: Platform;
}

export interface SendVerifyCodeToUserRequest {
  countryPrefixNumber: number;
  localeLanguageCode: string;
  phoneNumber: string;
  platform: Platform.WebClient;
}

export interface VerifyPreparation {
  countryName?: string;
  countryPrefixNumber?: number;
}

export interface VerifyPreparationResponse {
  userLocationInfos: VerifyPreparation[];
}

export interface VerificationsResponse {
  formattedPhoneNumber: string;
}
export interface VerifyAccountResponse {
  phoneToken: string;
  phoneId: string;
}

export interface LocaleInfo {
  countryCode: string | null;
  languageCode: string;
}

export interface MatchEmailRequest {
  email: string;
  phoneId: string;
  phoneToken: string;
  deviceId: string;
}

export interface MatchEmailResponse {
  phoneId: string;
  phoneToken: string;
}

export interface LoginWithPhoneThrowAble {
  userEmail: string;
  verified: boolean;
  reason: string;
}

export enum LoginErrorType {
  VerifyEmailNeedException = 'com.azarlive.api.exception.VerifyEmailNeedException',
  DeviceTransferException = 'com.azarlive.api.exception.DeviceTransferException',
}
export const VERIFY_CODE_LENGTH = 6;

export interface DeviceTransferVerifyCodeRequest {
  phoneId: string;
  phoneToken: string;
  verifyCode: string;
  deviceId: string;
}

export interface DeviceTransferSendCode {
  phoneToken?: string;
  phoneId?: string;
}

export type DeviceTransferSendCodeErrorCode =
  | 'VERIFICATION_FAILED'
  | 'SEND_CODE_FAIL'
  | 'EMAIL_VERIFIED'
  | 'VERIFY_COUNT_EXCEED';

export type DeviceTransferVerifyCodeErrorCode =
  | 'VERIFICATION_FAILED'
  | 'VERIFY_CODE_MISMATCH'
  | 'VERIFY_COUNT_EXCEED';

export type DeviceTransferMatchEmailErrorCode = 'EMAIL_MISMATCH' | 'VERIFICATION_FAILED';

export const deviceTransferSendCodeErrorCodeTranslationMap: Record<
  DeviceTransferSendCodeErrorCode,
  string
> = {
  SEND_CODE_FAIL: 'device_transfer__send_code_fail',
  EMAIL_VERIFIED: 'device_transfer__email_verified',
  VERIFY_COUNT_EXCEED: 'device_transfer__request_code_exceed',
  VERIFICATION_FAILED: 'SIGNUP_PHONE_VERIFICATION_FAIL',
};

export const deviceTransferVerifyCodeErrorCodeTranslationMap: Record<
  DeviceTransferVerifyCodeErrorCode,
  string
> = {
  VERIFY_CODE_MISMATCH: 'device_transfer__verity_code_mismatch',
  VERIFY_COUNT_EXCEED: 'device_transfer__verify_code_exeed',
  VERIFICATION_FAILED: 'SIGNUP_PHONE_VERIFICATION_FAIL',
};

export const deviceTransferMatchEmailErrorCodeTranslationMap: Record<
  DeviceTransferMatchEmailErrorCode,
  string
> = {
  EMAIL_MISMATCH: 'device_transfer__email_mismatch',
  VERIFICATION_FAILED: 'SIGNUP_PHONE_VERIFICATION_FAIL',
};
