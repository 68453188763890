import { FC, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import Logo from 'src/assets/images/imgAzarSymbolBackground.svg';
import Button from 'src/components/Button';
import ModalLayout, { MobileModalType, ModalContent } from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { WebToAppModalProps, WebToAppModalType } from 'src/types/WebToApp';
import getDeviceInfo from 'src/utils/device/info';
const RootStyle = styled.div`
  width: 100%;
  color: white;
`;
const LogoWrap = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.p`
  color: ${({
  theme
}) => theme.color.contentDeep};
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  margin-bottom: 8px;
`;
const Description = styled.p`
  color: ${({
  theme
}) => theme.color.content9};
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
`;
const translationKeys: { [key in WebToAppModalType]: {
  title: string;
  description: string;
} } = {
  [WebToAppModalType.BASIC]: {
    title: 'web_to_app_tilte',
    description: 'web_to_app_desc'
  },
  [WebToAppModalType.EFFECT]: {
    title: 'web_to_app_effect_title',
    description: 'web_to_app_effect_desc'
  },
  [WebToAppModalType.MESSAGE]: {
    title: 'web_to_app_message_title',
    description: 'web_to_app_message_desc'
  }
};

/**
 * 모바일 전용 웹투앱 모달
 */
const WebToAppModal: FC<WebToAppModalProps> = ({
  type,
  onClose
}) => {
  const {
    t
  } = useTranslation();
  const keys = translationKeys[type];
  const pushEvent = useEvent();
  const {
    isMobile
  } = getDeviceInfo();
  useEffect(() => {
    if (!isMobile) return;
    pushEvent({
      eventType: EVENT_TYPE.WEB2APP,
      eventName: EVENT_NAME.ANYWHERE__IMPRESSION_TO_APP_MODAL_BTN,
      eventParams: {
        action_category: 'impression',
        tab: type === WebToAppModalType.MESSAGE ? 'history' : 'mirror',
        page: 'main',
        target: 'to_app_modal',
        content_id: type,
        type: 'button'
      }
    });
  }, [pushEvent, type]);
  const handleClick = useCallback(() => {
    //event
    pushEvent({
      eventType: EVENT_TYPE.WEB2APP,
      eventName: EVENT_NAME.ANYWHERE__CLICK_TO_APP_MODAL_BTN,
      eventParams: {
        action_category: 'click',
        tab: type === WebToAppModalType.MESSAGE ? 'history' : 'mirror',
        page: 'main',
        target: 'to_app_modal_btn',
        content_id: type
      }
    });
    const campaignId: { [key in WebToAppModalType]: string } = {
      [WebToAppModalType.BASIC]: 'web_to_app_home',
      [WebToAppModalType.EFFECT]: 'web_to_app_effect',
      [WebToAppModalType.MESSAGE]: 'web_to_app_message'
    };
    window.open(`https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=${campaignId[type]}`, '_blank');
  }, [pushEvent, type]);
  return <ModalLayout mobileType={MobileModalType.BOTTOM_SHEET} isDim onClose={onClose} data-testid='Web-To-App-Modal' data-sentry-element="ModalLayout" data-sentry-component="WebToAppModal" data-sentry-source-file="index.tsx">
      <RootStyle data-sentry-element="RootStyle" data-sentry-source-file="index.tsx">
        <LogoWrap data-sentry-element="LogoWrap" data-sentry-source-file="index.tsx">
          <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
        </LogoWrap>
        <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="index.tsx">
          <Title data-sentry-element="Title" data-sentry-source-file="index.tsx">{t(keys.title)}</Title>
          <Description data-sentry-element="Description" data-sentry-source-file="index.tsx">{t(keys.description)}</Description>
        </ModalContent>
        <ButtonWrap data-sentry-element="ButtonWrap" data-sentry-source-file="index.tsx">
          <Button id='anywhere__click_to_app_modal_btn' color='primary' onClick={handleClick} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {t('web_to_app_button')}
          </Button>
        </ButtonWrap>
      </RootStyle>
    </ModalLayout>;
};
export default WebToAppModal;