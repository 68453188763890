import store from 'src/stores';
import { serverInfoPromiseAtom, setServersInfoAtom } from 'src/stores/auth/atoms';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { AzarUser } from 'src/types/AzarUser';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { getLoginParam } from 'src/utils/auth';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';

const ICE_SERVER_REFRESH_THRESHOLD = 5;

const iceServersAtom = getAtomWithStorage<AzarUser['iceServers']>('iceServersV2', []);

/**
 * ice server manager
 * ice server를 관리하는 클래스
 */
export class IceServerManager {
  private static instance: IceServerManager;

  private emptyIceCandidateErrorCount = 0;

  static getInstance(): IceServerManager {
    if (!IceServerManager.instance) {
      IceServerManager.instance = new IceServerManager();
    }
    return IceServerManager.instance;
  }

  public async fetchIceServers() {
    this.emptyIceCandidateErrorCount = 0;
    await store.set(setServersInfoAtom);
  }

  public async getIceServers() {
    await store.get(serverInfoPromiseAtom);
    return store.get(iceServersAtom);
  }

  public async setIceServers(iceServers: AzarUser['iceServers']) {
    store.set(iceServersAtom, iceServers);
  }

  public handleEmptyIceCandidateError() {
    this.emptyIceCandidateErrorCount++;
    if (this.emptyIceCandidateErrorCount > ICE_SERVER_REFRESH_THRESHOLD) {
      this.fetchIceServers();
    }
  }

  public async reportConnectionFail({
    errorMessage,
    generalConnectionFailType,
    generalConnectionAddress,
  }: {
    errorMessage: string;
    generalConnectionFailType: string;
    generalConnectionAddress?: string[];
  }) {
    const {
      localeInfo: { countryCode: localeCountryCode },
    } = getLoginParam();

    store.set(eventMutateAtom, {
      eventType: EVENT_TYPE.DEBUG,
      eventName: EVENT_NAME.GENERAL_CONNECTION_FAIL,
      eventParams: {
        generalConnectionFailInfo: errorMessage,
        generalConnectionFailType,
        country: localeCountryCode,
        generalConnectionAddress,
      },
    });
  }
}
