const vividiSystemColor = {
  primary1: 'yellow500',
  primary2: 'yellow600',
  primary3: 'yellow700',
  primary4: 'yellow800',
  primary5: 'yellow850',
  primaryBright: 'yellow50',
  primaryDeep: 'yellow900',
  bg1: 'solidWhiteBg',
  bg2: 'solidWhiteModal',
  surfaceBright: 'solidWhite',
  surface1: 'gray30',
  surface2: 'gray60',
  surface3: 'gray80',
  surface4: 'gray100',
  surface5: 'gray200',
  surface6: 'gray300',
  surface7: 'gray400',
  surface8: 'gray500',
  surface9: 'gray600',
  surface10: 'gray700',
  surface11: 'gray800',
  surface12: 'gray900',
  surfaceDeep: 'solidBlack',
  surfaceDeepFixed: 'solidBlackFixed',
  surfaceBrightFixed: 'solidWhiteFixed',
  surface6Fixed: 'gray300Fixed',
  surface12Fixed: 'gray900Fixed',
  contentInverse: 'solidBlackFixed',
  contentBright: 'solidWhite',
  content1: 'gray30',
  content2: 'gray60',
  content3: 'gray80',
  content4: 'gray100',
  content5: 'gray200',
  content6: 'gray300',
  content7: 'gray400',
  content8: 'gray500',
  content9: 'gray600',
  content10: 'gray700',
  content11: 'gray800',
  content12: 'gray900',
  contentDeep: 'solidBlack',
  contentDeepFixed: 'solidBlackFixed',
  contentBrightFixed: 'solidWhiteFixed',
  content6Fixed: 'gray300Fixed',
  content12Fixed: 'gray900Fixed',
  accentA1: 'red500',
  accentA2: 'red600',
  accentA3: 'red700',
  accentB1: 'orange500',
  accentB2: 'orange600',
  accentB3: 'orange700',
  accentC1: 'yellow500',
  accentC2: 'yellow600',
  accentC3: 'yellow700',
  accentD1: 'blue500',
  accentD2: 'blue600',
  accentD3: 'blue700',
  accentE1: 'indigo500',
  accentE2: 'indigo600',
  accentE3: 'indigo700',
  accentF1: 'purple500',
  accentF2: 'purple600',
  accentF3: 'purple700',
  accentG1: 'pink500',
  accentG2: 'pink600',
  accentG3: 'pink700',
  accentH1: 'green500',
  accentH2: 'green600',
  accentH3: 'green700',
  facebook1: '#1877F2',
  facebook2: '#015FD9',
  kakao1: '#FFEB00',
  kakao2: '#E6D019',
  line1: '#04BB00',
  line2: '#238421',
  x1: '#111111',
  x2: '#000000',
};

export default vividiSystemColor;
