import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import useLogin from 'src/hooks/useLogin';
import { useRestQuery } from 'src/hooks/useRestQuery';
import { RegisterApiList } from 'src/stores/register/apis';
import { GetConsentsAcceptedResponse } from 'src/stores/register/types';

export const useGetConsentsAcceptedQuery = () => {
  const { isLogin } = useLogin();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLogin) {
      queryClient.invalidateQueries([RegisterApiList.GetConsentsAccepted]);
    }
  }, [isLogin, queryClient]);

  return useRestQuery<null, GetConsentsAcceptedResponse, void>({
    path: RegisterApiList.GetConsentsAccepted,
    options: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  });
};
