import { atom } from 'jotai';

import { VisitorConfigs } from 'src/types/Visitor';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';

const baseVisitorConfigAtom = atom<VisitorConfigs['configs'] | undefined>(undefined);

export const debugVisitorConfigAtom = getAtomWithStorage<VisitorConfigs['configs'] | null>(
  'debugVisitorConfig',
  null
);

export const visitorConfigAtom = atom(
  (get) => {
    const baseVisitorConfig = get(baseVisitorConfigAtom);
    return baseVisitorConfig ? { ...baseVisitorConfig, ...get(debugVisitorConfigAtom) } : undefined;
  },
  (get, set, update: VisitorConfigs['configs']) => {
    set(baseVisitorConfigAtom, update);
  }
);
