import React, { ImgHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { screenWidth } from 'src/styles/screenSize';
const Img = styled.img<{
  hasSrc2: boolean;
}>`
  ${({
  hasSrc2,
  theme
}) => hasSrc2 && `
    ${theme.screenSize.mobile} {
      display: none !important;
    }
  `}
`;
const Img2 = styled.img`
  display: none;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: unset !important;
  }
`;
export interface ImageProps extends ImgHTMLAttributes<HTMLElement> {
  src: string;
  propsForMobile?: ImageProps;
  isBig?: boolean;
  supportImageSet?: boolean;
}
function generateResponsiveImageData(src: string) {
  const srcSet = `${src} ${screenWidth.mobile}w, ${src.replace('.', '@2x.')} ${screenWidth.tablet}w, ${src.replace('.', '@3x.')} ${screenWidth.desktop}w`;
  const sizes = `(max-width: ${screenWidth.mobile}px) 50px, (max-width: ${screenWidth.tablet}px) ${screenWidth.tablet}px, ${screenWidth.desktop}px`;
  return {
    srcSet,
    sizes
  };
}
const Image: React.FC<ImageProps> = ({
  src,
  propsForMobile,
  isBig = true,
  supportImageSet = true,
  alt,
  ...props
}) => <>
    <Img {...props} {...supportImageSet && !isBig ? generateResponsiveImageData(src) : {}} hasSrc2={!!propsForMobile} src={isBig ? src.replace('.', '@3x.') : src} alt={alt || src} data-sentry-element="Img" data-sentry-source-file="index.tsx" />
    {propsForMobile && <Img2 {...props} {...propsForMobile} {...supportImageSet && !isBig ? generateResponsiveImageData(src) : {}} src={isBig ? propsForMobile.src.replace('.', '@3x.') : propsForMobile.src} alt={alt || propsForMobile.src} />}
  </>;
export default Image;