import { FC } from 'react';
import styled from '@emotion/styled';
import { RefundPolicyModule } from 'src/types/gemLack';
import { setTargetBlankToLinks } from 'src/utils/html';
import { hoverAndActiveCss } from 'src/utils/styled';
const Wrap = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
  margin-top: 24px;
  padding: 0 8px;
  color: ${({
  theme
}) => theme.color.content6};
  width: 100%;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  font-size: 13px;

  ${({
  theme
}) => hoverAndActiveCss(`color: ${theme.color.content5};`)}
`;
interface Props {
  refundPolicyModule: RefundPolicyModule;
}
const RefundPolicyText: FC<Props> = ({
  refundPolicyModule
}) => {
  return <Wrap dangerouslySetInnerHTML={{
    __html: setTargetBlankToLinks(refundPolicyModule.text)
  }} data-sentry-element="Wrap" data-sentry-component="RefundPolicyText" data-sentry-source-file="RefundPolicyText.tsx" />;
};
export default RefundPolicyText;