import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import InfoModalBase from 'src/components/InfoModal';
import useEvent from 'src/hooks/useEvent';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
const InfoModal = styled(InfoModalBase)`
  max-width: 400px;
`;
interface Props {
  userAge: number;
  limitedAge: number;
}
export const AgeConfirmModal: FC<Props> = ({
  userAge,
  limitedAge
}) => {
  const {
    t
  } = useTranslation();
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventName: EVENT_NAME.SIGNUP__IMPRESSION_BIRTHDAY_INFO_RETRY_POPUP,
      eventType: EVENT_TYPE.SIGN_UP,
      eventParams: {
        action_category: 'impression',
        tab: 'signup',
        page: 'birthday',
        target: 'birthday_info_popup',
        age_value: userAge,
        age_limit: limitedAge
      }
    });
  }, [limitedAge, pushEvent, userAge]);
  const handleBackToEdit = () => {
    pushEvent({
      eventName: EVENT_NAME.SIGNUP__CLICK_BIRTHDAY_INFO_RETRY_BUTTON,
      eventType: EVENT_TYPE.SIGN_UP,
      eventParams: {
        action_category: 'click',
        tab: 'signup',
        page: 'birthday',
        target: 'birthday_retry_button',
        age_value: userAge,
        age_limit: limitedAge
      }
    });
    closeModal(ModalType.INVALID_AGE_CHECK);
  };
  return <InfoModal title={t('signup_extra_info_birthday_check_title')} desc={t('signup_extra_info_birthday_check_body')} buttonLabel={t('ROOM__EDIT__TITLE_LABEL')} onClickSubmit={handleBackToEdit} closeWhenClickOutside={true} data-sentry-element="InfoModal" data-sentry-component="AgeConfirmModal" data-sentry-source-file="AgeConfirmModal.tsx" />;
};