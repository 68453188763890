//추후 번역 추가 가능
export const BLOG_SUPPORTED_LANGUAGES = [
  // 'ar',
  // 'pt-br',
  // 'nl',
  'en-us',
  // 'fr',
  // 'de',
  // 'hi',
  // 'hu',
  // 'id',
  // 'it',
  // 'ja',
  // 'ko',
  // 'ru',
  // 'zh-cn',
  // 'es',
  // 'sv',
  // 'th',
  // 'zh-tw',
  // 'tr',
  // 'vi',
];

export const getBlogLanguage = () => {
  const BLOG_FALLBACK_LANGUAGE = 'en-us';

  // TODO: 블로그 다국어 지원 시 개선
  // const pathSegments = window.location.pathname.split('/');

  // if (
  //   BLOG_SUPPORTED_LANGUAGES.includes(pathSegments?.[2]) &&
  //   pathSegments[1] === 'blog'
  // ) {
  //   return `${pathSegments[2]}`;
  // }
  // if (BLOG_SUPPORTED_LANGUAGES.includes(getZendeskLanguage())) {
  //   return getZendeskLanguage();
  // }
  return BLOG_FALLBACK_LANGUAGE;
};
