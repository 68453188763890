import { AzarUser, Location } from 'src/types/AzarUser';

export interface FeatureSettings {
  featureSettings: {
    name: string;
    value: boolean | null;
    defaultValue: boolean;
  }[];
}

export interface MoreSettings {
  types: string[];
}

export interface SetFeatureSettingsParam {
  featureSettings: SetFeatureSettings[];
}

export interface SetFeatureSettings {
  type: UserSettingType;
  value: boolean;
}

export interface SetFeatureSettingsResponse {
  featureSettings: SetFeatureSettings[];
}

export enum UserSettingType {
  EASY_CONNECT = 'EASY_CONNECT',
  NEW_DISCOVER = 'NEW_DISCOVER',
  MARKETING_PUSH = 'MARKETING_PUSH',
  FRIEND_ONLINE_PUSH = 'FRIEND_ONLINE_PUSH',
  PERSONALIZED_ADS = 'PERSONALIZED_ADS',
  HIDE_GENDER = 'HIDE_GENDER',
  LIVE_EASY_CONNECT = 'LIVE_EASY_CONNECT',
  MATCH_IN_LIVE = 'MATCH_IN_LIVE',
  TERM_OF_USE = 'TERM_OF_USE',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
}

interface UpdatedUserInfo extends Pick<AzarUser, 'remoteConfig' | 'featureOptions'> {
  location: Location;
}
export interface GetUpdatedUserInfoResponse {
  result: UpdatedUserInfo;
}

export interface GetFeatureSettingsPopupResponse {
  result: {
    popups: FeatureSettingPopup[];
  };
}

export interface FeatureSettingPopup {
  type: UserSettingType;
  title: string;
  content: string;
  agreeButtonText: string;
  disagreeButtonText?: string;
}
