function findLineInRange(
  sdpLines: string[],
  startLine: number,
  endLine: number,
  prefix: string,
  substr: string
): number | null {
  const realEndLine = endLine !== -1 ? endLine : sdpLines.length;
  for (let i = startLine; i < realEndLine; ++i) {
    if (sdpLines[i].indexOf(prefix) === 0) {
      if (!substr || sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
        return i;
      }
    }
  }
  return null;
}

function findLine(sdpLines: string[], prefix: string, substr: string): number | null {
  return findLineInRange(sdpLines, 0, -1, prefix, substr);
}

function getCodecPayloadType(sdpLine: string) {
  const pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+');
  const result = sdpLine.match(pattern);
  return result && result.length === 2 ? result[1] : null;
}

/* mLine: sdp line ex) m=audio 9 RTP/SAVPF 103 111 104 9 0 8 106 105 13 126
 * 코덱을 원하는 payload로 설정한다 */
function setDefaultCodec(mLine: string, payload: string) {
  const elements = mLine.split(' ');
  const newLine = [];
  let index = 0;
  for (let i = 0; i < elements.length; i++) {
    if (index === 3) {
      newLine[index++] = payload;
    }
    if (elements[i] !== payload) {
      newLine[index++] = elements[i];
    }
  }
  return newLine.join(' ');
}

export function applyWorkaroundForCompat(sdp: string) {
  // https://code.google.com/p/webrtc/issues/detail?id=2796
  // 이 이슈에 따라 새 버전에서 'UDP/TLS/RTP/SAVPF' 포맷으로 보내면 기존 WebRTC와 호환이 안되어서 문제가 생긴다.
  // 구 WebRTC와 호환 유지를 위해서 기존대로 'RTP/SAVPF' 로 보내도록 임시로 처리를 해두도록 한다.
  return sdp.replace('UDP/TLS/RTP/SAVPF', 'RTP/SAVPF');
}

export function preferAudioCodec(sdp: string, codec: string) {
  const sdpLines = sdp.split('\r\n');
  const mLineIndex = findLine(sdpLines, 'm=', 'audio');
  if (mLineIndex === null) {
    return sdp;
  }
  const codecIndex = findLine(sdpLines, 'a=rtpmap', codec);
  if (codecIndex) {
    const payload = getCodecPayloadType(sdpLines[codecIndex]);
    if (payload) {
      sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], payload);
    }
  }
  sdp = sdpLines.join('\r\n');
  return sdp;
}

/**
 * Parses SDP lines and extracts payload type information
 * @param lines - Array of SDP lines
 * @returns Object containing sets of referenced and rtpmap payload types
 */
function parsePayloadTypes(lines: string[]): {
  allReferencedPayloadTypes: Set<number>;
  rtpmapPayloadTypes: Set<number>;
} {
  // Track all payload types mentioned in the SDP
  const allReferencedPayloadTypes = new Set<number>();
  // Track payload types that have rtpmap definitions
  const rtpmapPayloadTypes = new Set<number>();

  if (!lines || lines.length === 0) {
    return { allReferencedPayloadTypes, rtpmapPayloadTypes };
  }

  // Collect all payload types from media lines and attribute lines
  for (const line of lines) {
    // Check for media line (m=audio, m=video, etc.)
    const mediaMatch = line.match(/^m=\w+\s+\d+\s+[\w/]+\s+(.*)/);
    if (mediaMatch) {
      // Extract payload types from the media line
      const payloadTypesStr = mediaMatch[1];
      const payloadTypes = payloadTypesStr
        .split(' ')
        .map(Number)
        .filter((pt) => !isNaN(pt));

      // Add all payload types from the media line
      payloadTypes.forEach((pt) => allReferencedPayloadTypes.add(pt));
      continue;
    }

    // Check for attribute lines that reference payload types
    const attrMatch = line.match(/^a=(fmtp|rtcp-fb):(\d+)\s+/);
    if (attrMatch) {
      const payloadType = parseInt(attrMatch[2], 10);
      allReferencedPayloadTypes.add(payloadType);
      continue;
    }

    // Check for rtpmap lines
    const rtpmapMatch = line.match(/^a=rtpmap:(\d+)\s+/);
    if (rtpmapMatch) {
      const payloadType = parseInt(rtpmapMatch[1], 10);
      rtpmapPayloadTypes.add(payloadType);
    }
  }

  return { allReferencedPayloadTypes, rtpmapPayloadTypes };
}

/**
 * Checks if the SDP string is missing rtpmap attributes
 * @param sdp - Session Description Protocol string
 * @returns boolean indicating if rtpmap is missing
 */
export function isMissingRtpmap(sdp: string): boolean {
  if (!sdp) {
    return false;
  }

  // Split the SDP into lines
  const lines = sdp.split('\r\n');
  const { allReferencedPayloadTypes, rtpmapPayloadTypes } = parsePayloadTypes(lines);

  // Check if any referenced payload type is missing an rtpmap
  for (const pt of allReferencedPayloadTypes) {
    if (!rtpmapPayloadTypes.has(pt)) {
      return true;
    }
  }

  return false;
}

/**
 * Fixes SDP string with missing rtpmap attributes
 * @param sdp - Session Description Protocol string
 * @returns Fixed SDP string with proper rtpmap attributes
 */
export function fixMissingRtpmap(sdp: string): string {
  if (!sdp) {
    return sdp;
  }

  // Split the SDP into lines
  const lines = sdp.split('\r\n');
  const { rtpmapPayloadTypes } = parsePayloadTypes(lines);

  // Filter out lines that reference payload types without rtpmap
  const filteredLines: string[] = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    // Check for media line (m=audio, m=video, etc.)
    const mediaMatch = line.match(/^m=(\w+)\s+(\d+)\s+([\w/]+)\s+(.*)/);
    if (mediaMatch) {
      const mediaType = mediaMatch[1]; // audio, video, etc.
      const port = mediaMatch[2];
      const proto = mediaMatch[3];
      const payloadTypesStr = mediaMatch[4];

      // Filter out payload types that don't have rtpmap
      const payloadTypes = payloadTypesStr.split(' ').filter((pt) => {
        const num = parseInt(pt, 10);
        return !isNaN(num) && rtpmapPayloadTypes.has(num);
      });

      // Reconstruct the media line with only valid payload types
      filteredLines.push(`m=${mediaType} ${port} ${proto} ${payloadTypes.join(' ')}`);
      continue;
    }

    // Check for attribute lines that reference payload types
    const attrMatch = line.match(/^a=(fmtp|rtcp-fb):(\d+)\s+/);
    if (attrMatch) {
      const payloadType = parseInt(attrMatch[2], 10);
      // Only include attribute lines for payload types that have rtpmap
      if (rtpmapPayloadTypes.has(payloadType)) {
        filteredLines.push(line);
      }
      continue;
    }

    // Include all other lines
    filteredLines.push(line);
  }

  // Join the filtered lines back into an SDP string
  return filteredLines.join('\r\n');
}
