import { useCallback, useEffect, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { UpdatedLegalNoticeModal } from 'src/components/LegalUpdateModal';
import useLogin from 'src/hooks/useLogin';
import { ConsentApiList } from 'src/stores/auth/apis';
import { userDataAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { LegalNoticeRevisionPopup } from 'src/types/AzarUser';
import { ModalType } from 'src/types/Modal';
const useUpdatedLegalNoticeModal = () => {
  const userData = useAtomValue(userDataAtom);
  const showModal = useSetAtom(showModalAtom);
  const {
    isLogin
  } = useLogin();
  const [isResolved, setIsResolved] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [currentModalKey, setCurrentModalKey] = useState<string | null>(null);
  const isFetchingUpdates = useIsFetching({
    queryKey: [ConsentApiList.GetConsentRevision]
  });
  const getFirstModalKey = (popups: LegalNoticeRevisionPopup[]) => {
    if (popups.length > 0) {
      return popups[0].noticeIds.join('-');
    }
    return null;
  };
  const updateCurrentModalKey = useCallback(() => {
    const firstPopupKey = getFirstModalKey(userData?.legalNoticeRevisionPopups || []);
    if (firstPopupKey) {
      setCurrentModalKey(firstPopupKey);
    }
  }, [userData?.legalNoticeRevisionPopups]);
  const showNextModal = useCallback(() => {
    let currentIndex = -1;
    const updatedLegalNoticeWithPopup = userData?.legalNoticeRevisionPopups?.find((popup, index) => {
      if (popup.noticeIds.join('-') === currentModalKey) {
        currentIndex = index;
        return true;
      }
    });
    if (updatedLegalNoticeWithPopup) {
      const nextIndex = currentIndex + 1;
      if (userData?.legalNoticeRevisionPopups && nextIndex < userData.legalNoticeRevisionPopups.length) {
        const nextPopup = userData.legalNoticeRevisionPopups[nextIndex];
        setCurrentModalKey(nextPopup.noticeIds.join('-'));
      } else {
        setIsResolved(true);
      }
    }
  }, [currentModalKey, userData]);
  useEffect(() => {
    if (!shouldShow) return;
    if (isFetchingUpdates) {
      setIsResolved(false);
      return;
    }
    const updatedLegalNoticeWithPopup = userData?.legalNoticeRevisionPopups?.find(popup => popup.noticeIds.join('-') === currentModalKey);
    if (!updatedLegalNoticeWithPopup) {
      setIsResolved(true);
      return;
    }
    showModal({
      key: ModalType.LEGAL_NOTICE_RE_APPROVAL,
      component: () => <UpdatedLegalNoticeModal legalNoticeRevisionPopup={updatedLegalNoticeWithPopup} onClose={showNextModal} />
    });
  }, [currentModalKey, isLogin, shouldShow, showModal, showNextModal, userData]);
  useEffect(() => {
    if (isLogin) {
      updateCurrentModalKey();
    } else {
      setIsResolved(false);
      setCurrentModalKey('');
    }
  }, [isLogin, updateCurrentModalKey]);
  useEffect(() => {
    // legalNoticeRevisionPopups의 길이가 변경될 때 인덱스 초기화
    updateCurrentModalKey();
  }, [updateCurrentModalKey, userData?.legalNoticeRevisionPopups]);
  return {
    isResolved,
    show: useCallback(() => {
      updateCurrentModalKey();
      setShouldShow(true);
    }, [updateCurrentModalKey])
  };
};
export default useUpdatedLegalNoticeModal;