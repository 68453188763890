import React, { useEffect, useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import useLogin from 'src/hooks/useLogin';
import { handleAgeRestrictedLoginAttemptAtom, isDeviceAgeRestrictedAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { LoginModalType, isRegisterCompleteAtom, loginModalTypeAtom, resetRegisterAtom } from 'src/stores/register/atoms';
import { ModalType } from 'src/types/Modal';
import LoginEntry, { Props as LoginEntryProps } from './LoginEntry';
import PhoneNumberLogin from './PhoneNumberLogin';
import SocialLogin from './SocialLogin';
import SocialRegister from './SocialRegister';
type Props = LoginEntryProps;
const LoginBox: React.FC<Props> = props => {
  const loginModalType = useAtomValue(loginModalTypeAtom);
  const isDeviceAgeRestricted = useAtomValue(isDeviceAgeRestrictedAtom);
  const handleAgeRestrictedLoginAttempt = useSetAtom(handleAgeRestrictedLoginAttemptAtom);
  const isRegisterComplete = useAtomValue(isRegisterCompleteAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const resetRegister = useSetAtom(resetRegisterAtom);
  const {
    isLogin
  } = useLogin();

  // 회원가입/로그인 과정 중간에 다른 탭에서 로그인 등에 의해 로그인된 경우
  const isLoggedInWhileInProgress = useMemo(() => {
    // 회원가입 성공 마친 가이드라인 단계에서는 로그인상태지만 모달 노출 유지
    return isLogin && !isRegisterComplete;
  }, [isLogin, isRegisterComplete]);
  useEffect(() => {
    if (isDeviceAgeRestricted) {
      handleAgeRestrictedLoginAttempt();
    }
  }, [handleAgeRestrictedLoginAttempt, isDeviceAgeRestricted]);
  useEffect(() => {
    if (isLoggedInWhileInProgress) {
      closeModal(ModalType.LOGIN);
    }
  }, [isLoggedInWhileInProgress, closeModal]);
  useEffect(() => () => {
    resetRegister();
  }, [resetRegister]);
  if (isDeviceAgeRestricted || isLoggedInWhileInProgress) return null;
  switch (loginModalType) {
    case LoginModalType.LOGIN_ENTRY:
      return <LoginEntry {...props} />;
    case LoginModalType.SOCIAL_LOGIN:
      return <SocialLogin />;
    case LoginModalType.SOCIAL_REGISTER:
      return <SocialRegister />;
    case LoginModalType.PHONE_NUMBER_LOGIN:
      return <PhoneNumberLogin />;
  }
};
export default LoginBox;