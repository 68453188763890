import React, { FC, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
export const InputBase = ({
  theme
}: {
  theme: Theme;
}) => css`
  box-sizing: border-box;
  flex: 1;
  color: ${theme.color.contentDeep};

  outline: none;
  background: ${theme.color.surface4};
  border: 1px solid ${theme.color.surface4};
  :focus {
    border: 1px solid ${theme.color.accentH1};
  }
  border-radius: 8px;
  ::placeholder {
    font-size: 15px;
    color: ${theme.color.content7};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.color.contentDeep};
    -webkit-box-shadow: 0 0 0px 1000px ${theme.color.surface2} inset;
    transition: background-color 9999s ease-in-out 0s; // 자동완성때문에 인풋창이 노란색으로 변하는걸 막기 위해 delay
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
const StyledInput = styled.input`
  ${InputBase}
  padding: ${({
  theme
}) => theme.isRtl ? '24px 16px 6px 56px' : '24px 56px 6px 16px'};

  line-height: 15px;
  height: 56px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    padding: 0px;
    padding-bottom: 4px;
    ${({
  theme
}) => theme.isRtl ? 'padding-left' : 'padding-right'}: 40px;
    border: none;
    border-radius: 0;
    font-size: 24px;

    border-bottom: 2px solid ${({
  theme
}) => theme.color.surface10};
    background-color: transparent;
    margin-bottom: 10px;
    font-weight: 700;
    color: ${({
  theme
}) => theme.color.accentH1};
    ::placeholder {
      color: ${({
  theme
}) => theme.color.content5};
      opacity: 1;
    }
    :focus {
      border: none;
      border-bottom: 2px solid ${({
  theme
}) => theme.color.surface10};
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      -webkit-text-fill-color: ${({
  theme
}) => theme.color.accentH1};
    }
    width: 100%;
    display: block;
  }
`;
export const Label = styled.label<{
  isFocus: boolean;
}>`
  z-index: 1;
  position: absolute;
  top: 19px;
  ${({
  theme
}) => theme.isRtl ? 'right' : 'left'}: 16px;
  color: ${({
  theme
}) => theme.color.content9};
  transition:
    transform 150ms ease-out,
    font-size 150ms ease-out;
  font-size: 15px;
  box-sizing: border-box;
  ${({
  isFocus
}) => isFocus && css`
      transform: translateY(-60%);
      font-size: 12px;
    `}
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: none;
  }
`;
const Form = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({
  theme
}) => theme.screenSize.mobile} {
    flex: 1;
  }
`;
const ResetButton = styled.button`
  background-color: transparent;
  position: absolute;
  ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 16px;
  cursor: pointer;
  padding: 0;
  display: none;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: block;
    ${({
  theme
}) => theme.isRtl ? 'left' : 'right'}: 0;
    color: ${({
  theme
}) => theme.color.primary1};
  }
  width: 24px;
  height: 24px;
  top: 8px;
`;
const ResetImage = styled.img`
  height: 24px;
`;
export const ErrorRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
  margin-top: 16px;
  ${({
  theme
}) => theme.screenSize.mobile} {
    margin-top: 0;
  }
`;
export const ErrorText = styled.p`
  font-size: 14px;
  color: ${({
  theme
}) => theme.color.accentA1};
  a {
    color: inherit;
  }
`;
export const ErrorIcon = styled.img``;
export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value?: string;
  onChangeText: (text: string) => void;
  onSubmit?: () => void;
  onFocus?: () => void;
  placeholder: string;
  onClickHide?: () => void;
  errorMessage?: string;
  isShowResetButton?: boolean;
}
export const ErrorMessage: React.FC<{
  message: string;
}> = ({
  message
}) => {
  const {
    t
  } = useTranslation();
  return <ErrorRow data-sentry-element="ErrorRow" data-sentry-component="ErrorMessage" data-sentry-source-file="index.tsx">
      <ErrorIcon src='/images/icons/icWarningRed.svg' alt='error' data-sentry-element="ErrorIcon" data-sentry-source-file="index.tsx" />
      <ErrorText dangerouslySetInnerHTML={{
      __html: t(message)
    }} data-sentry-element="ErrorText" data-sentry-source-file="index.tsx"></ErrorText>
    </ErrorRow>;
};
const Input: FC<InputProps> = ({
  placeholder,
  value,
  onFocus,
  type,
  errorMessage,
  onChangeText,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  return <>
      <Form data-sentry-element="Form" data-sentry-source-file="index.tsx">
        {placeholder && <Label htmlFor={placeholder} isFocus={isFocus || !!value}>
            {placeholder}
          </Label>}
        <StyledInput {...props} id={placeholder} onFocus={() => {
        setIsFocus(true);
        if (onFocus) onFocus();
      }} onBlur={() => {
        setIsFocus(false);
      }} onChange={e => onChangeText(e.target.value)} type={type} data-sentry-element="StyledInput" data-sentry-source-file="index.tsx" />
        {!!value && <ResetButton type='button' onClick={() => {
        onChangeText('');
      }}>
            <ResetImage src='/images/icons/icCloseCircle.svg' alt='reset' />
          </ResetButton>}
      </Form>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>;
};
export default Input;