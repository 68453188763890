import { FC, SVGProps } from 'react';

export enum ToastType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

interface BaseWebviewToastProps {
  uuid: string;
  message: string;
  duration?: number;
  type: ToastType;
}

export interface WebviewToastProps extends BaseWebviewToastProps {
  title?: string;
  iconUrl?: string;
}

export interface NonWebviewToastProps extends BaseWebviewToastProps {
  Icon?: FC<SVGProps<SVGSVGElement>>;
}

export type ToastProps = WebviewToastProps | NonWebviewToastProps;
