import { FC, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { LoadingBackground, ModalLayout } from 'src/components/AzarOnly/Login/styles';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { MobileModalType, ModalHeader } from 'src/components/ModalLayout';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { exitLoginModalAtom, loginModalHeaderAtom, phoneNumberLoginHistoryAtom, popPhoneNumberLoginHistoryAtop, pushPhoneNumberLoginHistoryAtom } from 'src/stores/phoneNumberLogin/atoms';
import { isRegisterLoadingAtom } from 'src/stores/register/atoms';
import { ModalNavButtonType } from 'src/types/Modal';
import ConsentStep from './Steps/ConsentStep';
import SmsVerificationPhoneNumberStep from './Steps/SmsVerification/PhoneNumberStep';
const PhoneNumberLogin: FC = () => {
  const steps = useAtomValue(phoneNumberLoginHistoryAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const isRegisterLoading = useAtomValue(isRegisterLoadingAtom);
  const exitLoginModal = useSetAtom(exitLoginModalAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    uiType: consentsUiType
  } = consentsData?.data?.result || {};
  const stepNumber = steps.length;
  const step = steps[stepNumber - 1];
  const popHistory = useSetAtom(popPhoneNumberLoginHistoryAtop);
  const pushHistory = useSetAtom(pushPhoneNumberLoginHistoryAtom);
  const closeModal = useSetAtom(closeAllModalAtom);
  const handleCloseModal = () => {
    exitLoginModal();
    closeModal();
  };
  useEffect(() => {
    switch (consentsUiType) {
      case 'CONSENT_WEB_A':
        {
          pushHistory(<ConsentStep />);
          break;
        }
      case 'CONSENT_WEB_B':
        {
          pushHistory(<SmsVerificationPhoneNumberStep />);
          break;
        }
    }
    return () => {
      popHistory();
    };
  }, [consentsUiType, popHistory, pushHistory]);
  const isConsentStep = stepNumber === 1 && consentsUiType === 'CONSENT_WEB_A';
  const navButtonType = stepNumber === 1 ? ModalNavButtonType.CLOSE : ModalNavButtonType.BACK_AND_CLOSE;
  return <ModalLayout onBack={popHistory} navButtonType={navButtonType} mobileNavButtonType={navButtonType} isDim closeWhenClickOutside={false} closeWithESC={false} onClose={handleCloseModal} mobileType={MobileModalType.SCREEN_MODAL} minHeight={isConsentStep ? '600px' : undefined} data-sentry-element="ModalLayout" data-sentry-component="PhoneNumberLogin" data-sentry-source-file="PhoneNumberLogin.tsx">
      {loginModalHeader?.component || <ModalHeader />}
      {isRegisterLoading && <LoadingBackground>
          <LoadingIndicator size={24} />
        </LoadingBackground>}
      {step}
    </ModalLayout>;
};
export default PhoneNumberLogin;