import { FC } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { MobileModalType, ModalHeader } from 'src/components/ModalLayout';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { loginModalHeaderAtom } from 'src/stores/phoneNumberLogin/atoms';
import { exitSocialLoginAtom, popSocialLoginHistoryAtom, socialLoginHistoryAtom } from 'src/stores/socialLogin/atom';
import { ModalNavButtonType } from 'src/types/Modal';
import { ModalLayout } from './styles';
const SocialLogin: FC = () => {
  const steps = useAtomValue(socialLoginHistoryAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const exitSocialLogin = useSetAtom(exitSocialLoginAtom);
  const stepNumber = steps.length;
  const step = steps[stepNumber - 1];
  const popHistory = useSetAtom(popSocialLoginHistoryAtom);
  const closeModal = useSetAtom(closeAllModalAtom);
  const handleCloseModal = () => {
    exitSocialLogin();
    closeModal();
  };
  const navButtonType = stepNumber === 1 ? ModalNavButtonType.CLOSE : ModalNavButtonType.BACK_AND_CLOSE;
  return <ModalLayout onBack={popHistory} navButtonType={navButtonType} mobileNavButtonType={navButtonType} isDim closeWhenClickOutside={false} closeWithESC={false} onClose={handleCloseModal} mobileType={MobileModalType.SCREEN_MODAL} data-sentry-element="ModalLayout" data-sentry-component="SocialLogin" data-sentry-source-file="SocialLogin.tsx">
      {loginModalHeader?.component || <ModalHeader />}
      {step}
    </ModalLayout>;
};
export default SocialLogin;