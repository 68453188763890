import React, { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ButtonCss } from 'src/components/Button';
import { ButtonColor, ButtonVariant } from 'src/components/Button/types';
import ExternalLink from '.';
const ButtonStyledLink = styled(ExternalLink)<{
  color: ButtonColor;
  variant: ButtonVariant;
  message?: string;
  isMobile?: boolean;
}>`
  box-sizing: border-box;
  text-decoration: none;
  ${({
  theme,
  color,
  variant,
  message,
  isMobile
}) => ButtonCss({
  color,
  variant,
  message,
  isMobile,
  theme
})};
`;
interface LinkButtonProps extends React.HTMLProps<HTMLAnchorElement> {
  color?: ButtonColor;
  variant?: ButtonVariant;
}
const ExternalLinkButton: FC<PropsWithChildren<LinkButtonProps>> = ({
  color = 'primary',
  variant = 'contained',
  className,
  children,
  href,
  ...props
}) => {
  return <ButtonStyledLink color={color} variant={variant} className={className} {...props} href={href} role='button' data-sentry-element="ButtonStyledLink" data-sentry-component="ExternalLinkButton" data-sentry-source-file="ExternalLinkButton.tsx">
      {children}
    </ButtonStyledLink>;
};
export default ExternalLinkButton;