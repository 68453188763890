import {
  differenceInDays,
  differenceInMilliseconds,
  format,
  formatDistanceToNow,
  isToday,
  isYesterday,
} from 'date-fns';
import ar from 'date-fns/locale/ar';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import hi from 'date-fns/locale/hi';
import id from 'date-fns/locale/id';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';
import ptBR from 'date-fns/locale/pt-BR';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import th from 'date-fns/locale/th';
import tr from 'date-fns/locale/tr';
import vi from 'date-fns/locale/vi';
import zhCN from 'date-fns/locale/zh-CN';
import zhTW from 'date-fns/locale/zh-TW';
import { i18n } from 'next-i18next';

import { SupportLanguage } from './language';

const languageDateLocaleMap: Record<SupportLanguage, Locale> = {
  [SupportLanguage.AR]: ar,
  [SupportLanguage.DE]: de,
  [SupportLanguage.EN]: enUS,
  [SupportLanguage.ES]: es,
  [SupportLanguage.ES_MX]: es,
  [SupportLanguage.FR]: fr,
  [SupportLanguage.HI]: hi,
  [SupportLanguage.ID]: id,
  [SupportLanguage.IT]: it,
  [SupportLanguage.JA]: ja,
  [SupportLanguage.KO]: ko,
  [SupportLanguage.TR]: tr,
  [SupportLanguage.ZH_CN]: zhCN,
  [SupportLanguage.ZH_RTW]: zhTW,
  [SupportLanguage.TH]: th,
  [SupportLanguage.RU]: ru,
  [SupportLanguage.SV]: sv,
  [SupportLanguage.VI]: vi,
  [SupportLanguage.NL]: nl,
  [SupportLanguage.PT]: pt,
  [SupportLanguage.PT_BR]: ptBR,
};

export const checkAgeOverN = (birthday: Date, n: number, today: Date = new Date()) => {
  const dateNYearsAgo = new Date(today);
  dateNYearsAgo.setFullYear(dateNYearsAgo.getFullYear() - n);
  dateNYearsAgo.setHours(0, 0, 0, 0);

  const birthdayZeroTime = new Date(birthday);
  birthdayZeroTime.setHours(0, 0, 0, 0);

  return birthdayZeroTime.getTime() <= dateNYearsAgo.getTime();
};

export const formatDate = (date: Date | number) => {
  let tmp = new Date(date);
  const offset = tmp.getTimezoneOffset();
  tmp = new Date(tmp.getTime() - offset * 60 * 1000);
  const [isoDate] = tmp.toISOString().split('T');
  return isoDate;
};

export const formatDateDistance = (date: Date) => {
  const d = new Date(date);
  const now = Date.now();

  // dynamic import로 하면 bundle size가 늘어남
  const locale = languageDateLocaleMap[(i18n?.language as SupportLanguage) || SupportLanguage.EN];

  if (differenceInDays(now, d) < 7) {
    const dateTimeString =
      ' ' +
      date.toLocaleTimeString(locale.code, {
        hour: 'numeric',
        minute: 'numeric',
      });
    if (isToday(d)) {
      return i18n ? i18n?.t('history_time_today') + dateTimeString : '';
    }
    if (isYesterday(d)) {
      return i18n ? i18n?.t('history_time_yesterday') + dateTimeString : '';
    }
    return formatDistanceToNow(d, { addSuffix: true, locale }) + dateTimeString;
  }
  return date.toLocaleTimeString(locale.code, {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const millisecondsToHHMMSS = (milliseconds: number) => {
  const updateTime = Math.abs(milliseconds);
  const hours = Math.floor(updateTime / (1000 * 60 * 60));
  const minutes = Math.floor((updateTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((updateTime % (1000 * 60)) / 1000);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export type DatePatternShorthand = 'MM' | 'DD' | 'YEAR';

/**
 * @returns Locale에 따라 정렬된 ['MM', 'DD', 'YEAR'] 형태의 날짜 패턴을 반환
 * @example ko = ['YEAR', 'MM', 'DD']
 * @example en = ['MM', 'DD', 'YEAR']
 */
export const getLocaleDatePattern = (): DatePatternShorthand[] => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  } as const;
  const locale = navigator.language;
  const formatter = new Intl.DateTimeFormat(locale, options).formatToParts();

  const result = formatter
    .map(function (e) {
      switch (e.type) {
        case 'month':
          return 'MM';
        case 'day':
          return 'DD';
        case 'year':
          return 'YEAR';
        default:
          return null;
      }
    })
    .filter((e) => e !== null);

  return result as DatePatternShorthand[];
};

export const calculateAge = (birthDate: Date) => {
  const today = new Date();

  // 나이 계산
  const yearDiff = today.getFullYear() - birthDate.getFullYear();

  // 생일이 지났는지 확인 (만나이 계산)
  const isBirthdayPassed =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

  if (!isBirthdayPassed) {
    return yearDiff - 1;
  }

  return yearDiff;
};

export function formatTimestamp(timestamp: number) {
  const now = new Date();
  const dateA = new Date(timestamp);

  // 두 날짜의 차이를 밀리초 단위로 계산
  const diffInMs = Math.abs(differenceInMilliseconds(now, dateA));

  // 하루를 밀리초로 환산
  const oneDayInMs = 24 * 60 * 60 * 1000;

  if (diffInMs < oneDayInMs) {
    // 하루 이하 차이: 'hh:mm a' 형식으로 포맷팅
    return format(dateA, 'hh:mm a'); // 예: '03:45 PM'
  } else {
    // 하루 이상 차이: 'yyyy.MM.dd' 형식으로 포맷팅
    return format(dateA, 'yyyy.MM.dd'); // 예: '2025.02.10'
  }
}
