export function getFirstImage(htmlString: string) {
  const imgRegex = /<img[^>]+src="([^">]+)"/i;
  const match = htmlString.match(imgRegex);
  return match ? match[1] : '/images/blog/blog_thumb.png';
}

export function htmlToText(htmlString: string): string {
  const strippedString = htmlString.replace(/<br>/g, '').replace(/(\n)/g, '');
  return strippedString.replace(/<[^>]+>/g, '');
}

/**
 * URL 인코딩, 디코딩에도 변환되지 않도록 게시글 제목에 포함된 특수문자, 공백문자 등 모두 삭제
 */
export const normalizeStringForURL = (title: string) => {
  return title.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
};
