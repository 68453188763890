import { useCallback, useEffect, useRef } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import FeatureSettingsConsentsModal from 'src/components/FeatureSettingsConsentsModal/FeatureSettingsConsentsModal';
import { featureSettingsPopupsAtom, getFeatureSettingsPopupAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { OrderedModalHookType } from './types';
export const useFeatureSettingsPopupModal: OrderedModalHookType = () => {
  const featureSettingsPopups = useAtomValue(featureSettingsPopupsAtom);
  const getFeatureSettingsPopup = useSetAtom(getFeatureSettingsPopupAtom);
  const showModal = useSetAtom(showModalAtom);
  const getFeatureSettingsPromise = useRef<Promise<void> | null>(null);
  useEffect(() => {
    if (featureSettingsPopups === undefined) {
      getFeatureSettingsPromise.current = getFeatureSettingsPopup();
    }
  }, [featureSettingsPopups, getFeatureSettingsPopup]);
  const show = useCallback(async () => {
    await getFeatureSettingsPromise.current;
    if (featureSettingsPopups === undefined) {
      await getFeatureSettingsPopup();
    }
    if (featureSettingsPopups) {
      showModal({
        key: ModalType.FEATURE_SETTINGS_CONSENTS,
        component: FeatureSettingsConsentsModal
      });
    }
  }, [featureSettingsPopups, getFeatureSettingsPopup, showModal]);
  return {
    isResolved: featureSettingsPopups?.length === 0,
    show
  };
};