import { useAtomValue } from 'jotai';
import useArrayRef from 'src/hooks/useArrayRef';
import useIsomorphicLayoutEffect from 'src/hooks/useIsomorphicLayoutEffect';
import { toastsAtom } from 'src/stores/toast/atoms';
import { ToastProps } from 'src/types/Toast';
import { safeWindow } from 'src/utils/static-generation';
import NonWebviewToast from './NonWebviewToast';
import WebviewToast from './WebviewToast';
const ToastContainer = () => {
  const toasts = useAtomValue(toastsAtom);
  const {
    arrayRef: toastsRef,
    addToRefs
  } = useArrayRef<HTMLDivElement>();
  useIsomorphicLayoutEffect(() => {
    let y = 0;
    toastsRef.current.forEach(toastNode => {
      toastNode.style.setProperty('--y', `${y}px`);
      y += toastNode.offsetHeight;
    });
  }, [toasts]);
  const Toast = safeWindow?.AzarJs ? WebviewToast : NonWebviewToast;
  return <>
      {toasts.map((toast, index) => <Toast key={toast.uuid} toast={toast} ref={addToRefs} index={index} />)}
    </>;
};
export default ToastContainer;
export type { ToastProps };