import styled from '@emotion/styled';

import {
  Container as ContainerStyled,
  RootStyle as RootStyled,
  Title as TitleStyled,
} from 'src/components/Payments/style';

export const RootStyle = styled(RootStyled)`
  flex-direction: column;
`;

export const Container = styled(ContainerStyled)`
  flex: 1;
`;

export const Title = styled(TitleStyled)`
  white-space: pre-line;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImgFailed = styled.img`
  width: 88px;
  height: 88px;
`;

export const ErrorCode = styled.div`
  font-size: 16px;
  white-space: pre-line;
  color: ${({ theme }) => theme.color.content12};
  font-weight: bold;
  align-self: center;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.color.content8};
  text-align: center;
  margin-bottom: 28px;
  align-self: center;
  word-break: keep-all;
  padding: 0 24px 0 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 24px 32px 24px;
`;
