import { FC } from 'react';
import styled from '@emotion/styled';
import ModalLayoutBase, { MobileModalType, ModalLayoutProps } from 'src/components/ModalLayout';
const ModalLayout = styled(ModalLayoutBase)`
  width: 400px;
`;
export type ProductNudgingModalLayoutProps = Omit<ModalLayoutProps, 'mobileType' | 'isDim'>;
const ProductNudgingModalLayout: FC<ProductNudgingModalLayoutProps> = ({
  children,
  ...props
}) => {
  return <ModalLayout mobileType={MobileModalType.BOTTOM_SHEET} isDim {...props} data-sentry-element="ModalLayout" data-sentry-component="ProductNudgingModalLayout" data-sentry-source-file="index.tsx">
      {children}
    </ModalLayout>;
};
export default ProductNudgingModalLayout;
export { default as ProductNudgingModalHeader } from './Header';
export { default as ProductNudgingRefundPolicyText } from './RefundPolicyText';
export { ModalLoading as ProductNudgingModalLoading, ModalContent as ProductNudgingModalContent } from './styles';