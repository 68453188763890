/**
 * Message Broker 에서 발생 가능한 에러 코드
 * message header 에 `error-code` 라는 키로 들어옵니다.
 * @see {@link https://git.dev.hpcnt.com/hyperconnect/message-broker/blob/master/pkg/errors/errors.go#L17}
 */
export enum MessageBrokerErrorCode {
  LoginErrorCode = 200,
  ConnectionErrorCode,
  PermissionErrorCode,
  CommandErrorCode,
  MissingDestinationErrorCode,
  MissingSubscriptionIdErrorCode,
  LoginExpiredErrorCode,
}
