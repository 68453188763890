import { VideoCallStats } from '@hyperconnect/media-metric';

import { DebugStat } from 'src/types/Match';

export enum VideoStartBitrateMode {
  None, // 설정하지 않음. WebRTC 내부 로직의 기본값을 사용. (현재는 300kbps)
  Auto, // 직전 Bitrate 기반 설정 기능 사용 (BitrateMovingAverageCalculator 사용)
}

export const printVideoCallStats = (stats: VideoCallStats): DebugStat => {
  return {
    local: {
      default: `[${stats.localVideoCodec}/${stats.localVideoWidth}x${stats.localVideoHeight} | ${stats.localAudioCodec}:${stats.localAudioInputLevel}]`,
      framerate: `[${stats.localVideoFrameRateInput}/${stats.diff?.localVideoFrameRate}]`,
      'encode time': `${stats.diff?.localVideoEncodeMs}`,
      localVideoPacketsLost: `${stats.localVideoPacketsLost}`,
    },
    remote: {
      default: `[${stats.remoteVideoCodec}/${stats.remoteVideoWidth}x${stats.remoteVideoHeight} | ${stats.remoteAudioCodec}:${stats.remoteAudioOutputLevel}]`,
      framerate: `[${stats.diff?.remoteVideoFrameRateOutput}/${stats.diff?.remoteVideoFrameRateDecoded}/${stats.diff?.remoteVideoFrameRate}]`,
      'jitter ms': `${stats.diff?.remoteVideoJitterBufferMs}`,
      'decode time': `${stats.diff?.remoteVideoDecodeMs}`,
      remoteVideoPacketsLost: `${stats.remoteVideoPacketsLost}`,
    },
    connection: {
      local: `[${stats.localCandidateType}] ${stats.localAddress}`,
      remote: `[${stats.remoteCandidateType}] ${stats.remoteAddress}`,
      'encoding bitrate': `${stats.diff?.encBitrate}`,
      'transmit bitrate': `${stats.diff?.transBitrate}`,
      'send bandwidth': `${stats.sendBandwidthBps}`,
      rtt: `${stats.rtt}`,
    },
  };
};
