import { FC, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import Button from 'src/components/Button';
import ExternalLinkButton from 'src/components/ExternalLink/ExternalLinkButton';
import { MobileModalType, ModalContent, ModalFooter, ModalHeader, ModalHeaderTitle } from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { useRestMutation } from 'src/hooks/useRestMutation';
import useSelectedTab from 'src/hooks/useSelectedTab';
import { LegalNoticeApiList } from 'src/stores/auth/apis';
import { userDataAtom } from 'src/stores/auth/atoms';
import { EventParams } from 'src/stores/event/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { showCommonErrorToastAtom } from 'src/stores/toast/atoms';
import { LegalNoticeRevisionPopup } from 'src/types/AzarUser';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { setTargetBlankToLinks } from 'src/utils/html';
import { Desc, ModalLayout } from './style';
const LinkButton = styled(ExternalLinkButton)`
  margin-bottom: 16px;
`;
interface Props {
  legalNoticeRevisionPopup: LegalNoticeRevisionPopup;
  onClose: () => void;
}
const UpdatedLegalNoticeModal: FC<Props> = ({
  legalNoticeRevisionPopup,
  onClose
}) => {
  const pushEvent = useEvent();
  const {
    selectedTab
  } = useSelectedTab();
  const closeModal = useSetAtom(closeModalAtom);
  const setUserData = useSetAtom(userDataAtom);
  const showCommonErrorToast = useSetAtom(showCommonErrorToastAtom);
  const handlePushEvent = useCallback(({
    eventType,
    eventName,
    eventParams
  }: EventParams) => {
    pushEvent({
      eventType,
      eventName,
      eventParams: {
        ...eventParams,
        tab: selectedTab.name,
        page: 'main',
        target: 'consent_popup',
        if_required: 'false',
        popup_type: 'LegalNotice',
        popup_id: legalNoticeRevisionPopup.noticeIds
      }
    });
  }, [legalNoticeRevisionPopup.noticeIds, pushEvent, selectedTab]);
  const {
    mutate,
    isLoading
  } = useRestMutation<{
    noticeIds: string[];
  }>({
    path: LegalNoticeApiList.ConfirmLegalNotice,
    method: 'post',
    options: {
      onSuccess: () => {
        handlePushEvent({
          eventType: EVENT_TYPE.BUTTON,
          eventName: EVENT_NAME.ANYWHERE_CLICK_CONSENT_BUTTON,
          eventParams: {
            action_category: 'click'
          }
        });
        closeModal(ModalType.LEGAL_NOTICE_RE_APPROVAL);
        setUserData(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            legalNoticeRevisionPopups: prev.legalNoticeRevisionPopups?.slice(1)
          };
        });
      },
      onError: () => {
        showCommonErrorToast();
      }
    }
  });
  const handleConfirm = () => {
    mutate({
      noticeIds: legalNoticeRevisionPopup.noticeIds
    });
  };
  const handleClose = () => {
    closeModal(ModalType.LEGAL_NOTICE_RE_APPROVAL);
    onClose();
  };
  const handleClickLink = () => {
    handlePushEvent({
      eventType: EVENT_TYPE.BUTTON,
      eventName: EVENT_NAME.ANYWHERE_CLICK_LINK_BUTTON,
      eventParams: {
        action_category: 'click'
      }
    });
  };
  useEffect(() => {
    handlePushEvent({
      eventType: EVENT_TYPE.POPUP,
      eventName: EVENT_NAME.ANYWHERE_IMPRESSION_CONSENT_POPUP,
      eventParams: {
        action_category: 'impression'
      }
    });
  }, [handlePushEvent]);
  return <ModalLayout isDim closeWhenClickOutside mobileType={MobileModalType.BOTTOM_SHEET} data-testid='Updated-Legal-Notice-Modal' onClose={handleClose} data-sentry-element="ModalLayout" data-sentry-component="UpdatedLegalNoticeModal" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
          {legalNoticeRevisionPopup.title}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
        <Desc dangerouslySetInnerHTML={{
        __html: setTargetBlankToLinks(legalNoticeRevisionPopup.content)
      }} data-sentry-element="Desc" data-sentry-source-file="UpdatedLegalNoticeModal.tsx" />
      </ModalContent>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
        <LinkButton id='LEGAL_NOTICE_LINK' variant='outlined' href={legalNoticeRevisionPopup.linkButton.url} target='_blank' onClick={handleClickLink} data-sentry-element="LinkButton" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
          {legalNoticeRevisionPopup.linkButton.label}
        </LinkButton>
        <Button data-testid='Confirm-Button' onClick={handleConfirm} id='LEGAL_NOTICE_CONFIRM' disabled={isLoading} data-sentry-element="Button" data-sentry-source-file="UpdatedLegalNoticeModal.tsx">
          {legalNoticeRevisionPopup.confirmButtonLabel}
        </Button>
      </ModalFooter>
    </ModalLayout>;
};
export default UpdatedLegalNoticeModal;