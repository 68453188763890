import { useAtomValue } from 'jotai';

import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import { VisitorRemoteConfig } from 'src/types/Visitor';
import { isObjectKeyTrue } from 'src/utils/common';

const useEnableVisitorConfig = (key: keyof VisitorRemoteConfig) => {
  const visitorConfig = useAtomValue(visitorConfigAtom);
  return visitorConfig?.[key] !== undefined ? isObjectKeyTrue(visitorConfig, key) : undefined;
};

export default useEnableVisitorConfig;
