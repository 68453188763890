import { FC, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { MobileModalType, ModalHeader } from 'src/components/ModalLayout';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { exitLoginModalAtom, loginModalHeaderAtom } from 'src/stores/phoneNumberLogin/atoms';
import { isRegisterLoadingAtom, popSocialRegisterHistoryAtom, pushSocialRegisterHistoryAtom, socialRegisterHistoryAtom } from 'src/stores/register/atoms';
import { ModalNavButtonType } from 'src/types/Modal';
import ConsentStep from './Steps/ConsentStep';
import RegisterBirthdayStep from './Steps/Register/BirthdayStep';
import { LoadingBackground, ModalLayout } from './styles';
const SocialRegister: FC = () => {
  const steps = useAtomValue(socialRegisterHistoryAtom);
  const popHistory = useSetAtom(popSocialRegisterHistoryAtom);
  const pushHistory = useSetAtom(pushSocialRegisterHistoryAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const isRegisterLoading = useAtomValue(isRegisterLoadingAtom);
  const exitLoginModal = useSetAtom(exitLoginModalAtom);
  const closeModal = useSetAtom(closeAllModalAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    uiType: consentsUiType
  } = consentsData?.data?.result || {};
  const stepNumber = steps.length;
  const step = steps[stepNumber - 1];
  const handleCloseModal = () => {
    exitLoginModal();
    closeModal();
  };
  useEffect(() => {
    switch (consentsUiType) {
      case 'CONSENT_WEB_A':
        {
          pushHistory(<ConsentStep />);
          break;
        }
      case 'CONSENT_WEB_B':
        {
          pushHistory(<RegisterBirthdayStep />);
          break;
        }
    }
    return () => {
      popHistory();
    };
  }, [consentsUiType, popHistory, pushHistory]);
  const isConsentStep = stepNumber === 1 && consentsUiType === 'CONSENT_WEB_A';
  const navButtonType = stepNumber === 1 ? ModalNavButtonType.CLOSE : ModalNavButtonType.BACK_AND_CLOSE;
  return <ModalLayout onBack={loginModalHeader?.onBack || popHistory} navButtonType={navButtonType} mobileNavButtonType={navButtonType} mobileType={MobileModalType.SCREEN_MODAL} isDim closeWhenClickOutside={false} closeWithESC={false} minHeight={isConsentStep ? '600px' : undefined} onClose={handleCloseModal} data-sentry-element="ModalLayout" data-sentry-component="SocialRegister" data-sentry-source-file="SocialRegister.tsx">
      {loginModalHeader?.component || <ModalHeader />}
      {isRegisterLoading && <LoadingBackground>
          <LoadingIndicator size={24} />
        </LoadingBackground>}

      {step}
    </ModalLayout>;
};
export default SocialRegister;