import styled from '@emotion/styled';
const Gap = styled.div<{
  width: number;
  mobileWidth?: number;
  tabletWidth?: number;
}>`
  flex-shrink: 0;
  width: ${({
  width
}) => width}px;
  ${({
  theme
}) => theme.screenSize.tablet} {
    width: ${({
  tabletWidth,
  width
}) => tabletWidth ?? width}px;
  }
  ${({
  theme
}) => theme.screenSize.mobile} {
    width: ${({
  mobileWidth,
  width
}) => mobileWidth ?? width}px;
  }
`;
export default Gap;