import { useEffect } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import LiveOffModal from 'src/components/LiveOffModal';
import useEnableVisitorConfig from 'src/hooks/useEnableVisitorConfig';
import { isShowedLiveOffModalAtom } from 'src/stores/live/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
export default function useLiveOffModal() {
  const isEnableLiveModalConfig = useEnableVisitorConfig('LiveModalEnabled');
  const showModal = useSetAtom(showModalAtom);
  const [isShowedLiveOffModal, setIsShowedLiveOffModal] = useAtom(isShowedLiveOffModalAtom);
  useEffect(() => {
    if (isEnableLiveModalConfig && !isShowedLiveOffModal) {
      showModal({
        key: ModalType.LIVE_OFF,
        component: () => <LiveOffModal />
      });
      setIsShowedLiveOffModal(true);
    }
  }, [isEnableLiveModalConfig, isShowedLiveOffModal, setIsShowedLiveOffModal, showModal]);
  return {
    isEnableLiveModalConfig
  };
}