import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import { nextPagePropsAtom } from 'src/stores/pageProps/atoms';
import { setClientLocale } from 'src/utils/api';
import { getLocale } from 'src/utils/language';
async function getDynamicLanguageResource(locale: string) {
  try {
    return await import(`../../translations/translations.${locale}.json`);
  } catch {
    return null;
  }
}
const I18nProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const pageProps = useAtomValue(nextPagePropsAtom);
  const [language, setLanguage] = useState<string | undefined>(pageProps?.staticGenerationLanguage);
  const {
    i18n
  } = useTranslation();
  useEffect(() => {
    if (!i18n.isInitialized) {
      return;
    }
    if (language) {
      setClientLocale(language);
    } else {
      const locale = getLocale();
      i18n.changeLanguage(locale);
      getDynamicLanguageResource(locale).then(resource => {
        if (resource) {
          i18n.addResources(locale, 'translations', resource);
          setLanguage(locale);
        }
      });
    }
  }, [i18n, language]);
  if (!language) return null;
  return children;
};
export default I18nProvider;