import * as Sentry from '@sentry/nextjs';
import { useAtomValue, useSetAtom } from 'jotai';

import useErrorMessageTranslation from 'src/hooks/useErrorMessageTranslation';
import { useRestMutation } from 'src/hooks/useRestMutation';
import { loginWithPhoneNumberAtom } from 'src/stores/auth/atoms';
import { PhoneNumberLoginApiList } from 'src/stores/phoneNumberLogin/apis';
import { smsVerificationResponseAtom } from 'src/stores/phoneNumberLogin/atoms';
import {
  DeviceTransferVerifyCodeErrorCode,
  deviceTransferVerifyCodeErrorCodeTranslationMap,
  DeviceTransferVerifyCodeRequest,
} from 'src/stores/phoneNumberLogin/types';
import { ErrorResponse } from 'src/utils/api';
import getDeviceId from 'src/utils/device/id';

export default function useDeviceTransferVerifyCode() {
  const loginWithPhone = useSetAtom(loginWithPhoneNumberAtom);

  const { isLoading, isSuccess, isError, error, failureCount, mutate } = useRestMutation<
    DeviceTransferVerifyCodeRequest,
    void,
    ErrorResponse<null, DeviceTransferVerifyCodeErrorCode>
  >({
    path: PhoneNumberLoginApiList.DeviceTransferVerifyCode,
    method: 'post',
    options: {
      onSuccess: () => {
        loginWithPhone();
      },
    },
  });
  const smsVerificationResponse = useAtomValue(smsVerificationResponseAtom);

  const handleSend = async (verifyCode: string) => {
    if (!smsVerificationResponse) {
      Sentry.captureMessage('sendCode called without smsVerificationResponse', {
        extra: {
          hook: 'useDeviceTransferVerifyCode',
        },
      });
      return;
    }

    const { phoneId, phoneToken } = smsVerificationResponse;
    mutate({
      phoneId,
      phoneToken,
      verifyCode,
      deviceId: getDeviceId(),
    });
  };

  const translatedErrorMessage = useErrorMessageTranslation(
    deviceTransferVerifyCodeErrorCodeTranslationMap,
    'SIGNUP_PHONE_VERIFICATION_FAIL',
    error?.response?.data.error
  );

  return {
    handleSend,
    isLoading,
    isSuccess,
    isError,
    translatedErrorMessage,
    failureCount,
  };
}
