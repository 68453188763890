import { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import Button from 'src/components/Button';
import ExternalLink from 'src/components/ExternalLink';
import { ModalFooter } from 'src/components/ModalLayout';
import { ErrorCode, Wrapper as WrapperBase } from 'src/components/Payments/Failure/style';
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled(WrapperBase)`
  padding: 60px 0;
  flex-grow: 1;
`;
const Link = styled(ExternalLink)`
  text-decoration: none;
`;
const EmptyGemProductsImage = styled.img``;
const EmptyProducts: FC = () => {
  const {
    t
  } = useTranslation();
  return <Container data-sentry-element="Container" data-sentry-component="EmptyProducts" data-sentry-source-file="EmptyProducts.tsx">
      <Wrapper data-sentry-element="Wrapper" data-sentry-source-file="EmptyProducts.tsx">
        <EmptyGemProductsImage src='/images/shop/emptyGemProducts.svg' alt='empty-gem-products' data-sentry-element="EmptyGemProductsImage" data-sentry-source-file="EmptyProducts.tsx" />
        <ErrorCode data-sentry-element="ErrorCode" data-sentry-source-file="EmptyProducts.tsx">{t('shop_empty_package')}</ErrorCode>
      </Wrapper>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="EmptyProducts.tsx">
        <Link href='https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=empty_shop' data-sentry-element="Link" data-sentry-source-file="EmptyProducts.tsx">
          <Button id='gem-products-empty' data-sentry-element="Button" data-sentry-source-file="EmptyProducts.tsx">{t('buy_in_app')}</Button>
        </Link>
      </ModalFooter>
    </Container>;
};
export default EmptyProducts;