import { atom } from 'jotai';

import { gemProductsAtom, getGemProductsAtom } from 'src/stores/payments/atoms';
import { getPurchasableSubscriptionProductsAtom } from 'src/stores/payments/atoms';
import { VipRatingDetails, VipUserRating } from 'src/types/VIP';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';

import { getVipRating, getVipRatingDetails } from './apis';

export const vipRatingAtom = getAtomWithStorage<VipUserRating | null>('VipUserRating', null);
export const getVipRatingAtom = atom(null, async (get, set) => {
  const res = await getVipRating();
  set(vipRatingAtom, res.data);
});

export const currentRatingIndexAtom = atom<number>(0);
export const selectedRatingIndexAtom = atom<number>(0);
export const pgEnableAtom = atom<boolean>(false);
export const vipRatingDetailsAtom = atom<VipRatingDetails | void>(undefined);
export const getVipRatingDetailsAtom = atom(null, async (get, set) => {
  const [result] = await Promise.allSettled([
    getVipRatingDetails(),
    set(getGemProductsAtom),
    set(getPurchasableSubscriptionProductsAtom),
  ]);
  if (result.status !== 'fulfilled') {
    if (window.AzarJs) {
      alert('Sorry. Please retry');
    }
    return;
  }
  const res = result.value;
  const gemProduct = get(gemProductsAtom);
  const currentIndex = res.data.ratings.findIndex(
    ({ rating }) => rating === res.data.userRating.currentRating
  );
  set(currentRatingIndexAtom, currentIndex);
  set(selectedRatingIndexAtom, (currentIndex || res.data.ratings.length - 1) - 1);
  if (gemProduct) {
    set(pgEnableAtom, !!gemProduct.paymentMethod?.length);
  }
  set(vipRatingDetailsAtom, res.data);
});
