import { useEffect, useMemo, useState } from 'react';
import { screenWidth } from 'src/styles/screenSize';
function getWindowDimensions() {
  const {
    innerWidth: width,
    innerHeight: height
  } = typeof window !== 'undefined' ? window : {
    innerWidth: 0,
    innerHeight: 0
  };
  return {
    width,
    height
  };
}
export default function useCssMediaDevice() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const device = useMemo(() => {
    if (windowDimensions.width >= screenWidth.desktop) {
      return 'desktop';
    }
    if (windowDimensions.width <= screenWidth.tablet && windowDimensions.width > screenWidth.mobile) {
      return 'tablet';
    }
    if (windowDimensions.width <= screenWidth.mobile) {
      return 'mobile';
    }
  }, [windowDimensions.width]);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return device;
}