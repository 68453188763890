import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import Script from 'next/script';
import { oneTrustToken } from 'src/pages/_app';
import { isOneTrustReadyAtom } from 'src/stores/oneTrust/atom';
const OneTrustLoader: FC<PropsWithChildren> = ({
  children
}) => {
  /**
   * SEO: app ui 먼저 그리고 OneTrust ui 그리기 위해
   * @see https://hpcnt.slack.com/archives/C02SQS5AJDR/p1684288556273129
   */
  const [isShowOneTrustBanner, setIsShowOneTrustBanner] = useState(false);
  const setIsOneTrustReady = useSetAtom(isOneTrustReadyAtom);
  useEffect(() => {
    /**
     * OneTrust 스크립트 로드 후 실행되는 함수
     * OptanonWrapper callback이 호출된 이후에, OneTrust 관련 객체 접근 및 유저의 관련 설정값 등을 확인할 수 있음
     * @see https://my.onetrust.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561?language=en_US
     * @see https://git.dev.hpcnt.com/hyperconnect/azar-web-client/pull/1085#discussion_r385282
     */
    window.OptanonWrapper = function () {
      setIsOneTrustReady(true);
    };
    setIsShowOneTrustBanner(true);
  }, [setIsOneTrustReady]);
  return <>
      {typeof window !== 'undefined' && !window.AzarJs && isShowOneTrustBanner && <Script src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js' type='text/javascript' data-domain-script={oneTrustToken} />}
      {children}
    </>;
};
export default OneTrustLoader;