import { FC } from 'react';
import Image from 'src/components/Image';
interface Props {
  className?: string;
}
const GemImage: FC<Props> = ({
  className
}) => {
  return <Image src='/images/icons/imgGemSquare.png' alt='gem' isBig={false} className={className} data-sentry-element="Image" data-sentry-component="GemImage" data-sentry-source-file="index.tsx" />;
};
export default GemImage;