import { RestQueryOptions, useRestQuery } from 'src/hooks/useRestQuery';
import { GetGemLackPopupRequest, GetGemLackPopupResponse } from 'src/types/gemLack';

export enum GemLackApiList {
  GetGemLackPopup = '/api/popups/gem-lack-popup/',
}

export const useGemLackPopupQuery = (
  { gemAmount, shopCategory }: GetGemLackPopupRequest,
  options: RestQueryOptions<GetGemLackPopupResponse> = {}
) => {
  return useRestQuery<GetGemLackPopupRequest, GetGemLackPopupResponse, void>({
    path: GemLackApiList.GetGemLackPopup,
    params: {
      gemAmount,
      shopCategory,
    },
    options: {
      ...options,
      cacheTime: 0, // 젬부족 모달은 노출될 때마다 새롭게 API로 정보 가져와야함
    },
  });
};
