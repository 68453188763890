import { useEffect } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import useEvent from 'src/hooks/useEvent';
import { pushedUTMFirstEventAtom, setUTMParamsAtom, UTMParamsAtom } from 'src/stores/utm/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { getUTMParams } from 'src/utils/common';

const useUTM = () => {
  const router = useRouter();
  const UTMParams = useAtomValue(UTMParamsAtom);
  const setUTMParams = useSetAtom(setUTMParamsAtom);
  const [pushedUTMFirstEvent, setPushedUTMFirstEvent] = useAtom(pushedUTMFirstEventAtom);
  const pushEvent = useEvent();

  useEffect(() => {
    if (router.isReady) {
      const newUTMParams = getUTMParams(router.query);
      if (Object.keys(newUTMParams).length > 0) {
        setUTMParams(newUTMParams);
      }
    }
  }, [router.isReady, router.query, setUTMParams]);

  useEffect(() => {
    // 팝업 띄울 때 안 보내기 위해 session storage 확인
    if (!pushedUTMFirstEvent && Object.keys(UTMParams).length > 0) {
      pushEvent({
        eventName: EVENT_NAME.UTM_FIRST_OPEN,
        eventType: EVENT_TYPE.UTM,
      });
      setPushedUTMFirstEvent(true);
    }
  }, [UTMParams, pushEvent, pushedUTMFirstEvent, setPushedUTMFirstEvent]);
};

export default useUTM;
