import { RestQueryOptions, useRestQuery } from 'src/hooks/useRestQuery';
import { GetStarterPackagePopupResponse } from './types';
export enum ContentsMatchApiList {
  GetStarterPackagePopup = '/api/popups/starter-package-popup',
}
export const useGetStarterPackagePopupQuery = (options: RestQueryOptions<GetStarterPackagePopupResponse> = {}) => {
  return useRestQuery<void, GetStarterPackagePopupResponse, void>({
    path: ContentsMatchApiList.GetStarterPackagePopup,
    options: {
      ...options,
      cacheTime: 0 // 스타터패키지 대상 여부는 변경 가능하기 때문에 매번 새롭게 호출
    }
  });
};