import { useAtom, useSetAtom } from 'jotai';
import EmailAddressRegisterStep from 'src/components/AzarOnly/Login/Steps/Email/EmailAddressRegisterStep';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { useRestMutation } from 'src/hooks/useRestMutation';
import { loginRetryDataAtom, loginWithPhoneNumberAtom, retryLoginWithPhoneVerifyAtom, socialTokenAtom } from 'src/stores/auth/atoms';
import { PhoneNumberLoginApiList, checkAccountExistAPI } from 'src/stores/phoneNumberLogin/apis';
import { smsVerificationAtom, smsVerificationResponseAtom, verifyErrorMessages, pushPhoneNumberLoginHistoryAtom } from 'src/stores/phoneNumberLogin/atoms';
import { VerifyAccountRequest, VerifyAccountResponse } from 'src/stores/phoneNumberLogin/types';
import { getSignUpSettingsAPI } from 'src/stores/register/apis';
import { FALLBACK_SIGNUP_SETTINGS, LoginModalType, loginModalTypeAtom, registerAtom } from 'src/stores/register/atoms';
import { UserSettingType } from 'src/stores/user/types';
import { Platform } from 'src/types/Platform';
import { ApiResponse, ErrorAlertMessage, ErrorResponse } from 'src/utils/api';
import { getServerSideLanguageCode } from 'src/utils/language';
export default function useVerifyCode() {
  const setSocialToken = useSetAtom(socialTokenAtom);
  const pushHistory = useSetAtom(pushPhoneNumberLoginHistoryAtom);
  const loginWithPhone = useSetAtom(loginWithPhoneNumberAtom);
  const [smsVerification, setSmsVerification] = useAtom(smsVerificationAtom);
  const setSmsVerificationPhone = useSetAtom(smsVerificationResponseAtom);
  const [loginModalType] = useAtom(loginModalTypeAtom);
  const verifyCodeMutation = useRestMutation<VerifyAccountRequest, ApiResponse<VerifyAccountResponse>, ErrorResponse<ErrorAlertMessage>>({
    method: 'post',
    path: PhoneNumberLoginApiList.VerificationsValidate,
    options: {
      onSuccess: async ({
        data: {
          result
        }
      }) => {
        const isExistAccount = await checkAccountExistAPI(result.phoneId)();
        setSocialToken({
          id: result.phoneId,
          phoneToken: result.phoneToken
        });
        setSmsVerificationPhone(result);
        if (isExistAccount.data.result) {
          loginWithPhone();
        } else {
          pushHistory(<EmailAddressRegisterStep />);
        }
      },
      onError: error => {
        if (error.response?.data.error.code === 'VERIFICATION_FAILED') {
          setSmsVerification({
            ...smsVerification,
            errorMessage: verifyErrorMessages[error.response.data.error.code] || 'SIGNUP_PHONE_VERIFICATION_FAIL'
          });
        }
      }
    }
  });
  const {
    formattedPhoneNumber
  } = smsVerification;
  const [loginRetryData, setLoginRetryData] = useAtom(loginRetryDataAtom);
  const register = useSetAtom(registerAtom);
  const retryLoginWithPhoneVerify = useSetAtom(retryLoginWithPhoneVerifyAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    uiType: consentsUiType
  } = consentsData?.data?.result || {};

  // 회원가입 중 NotVerifiedException 에러가 발생했을 때 재시도를 위한 로직
  const retryRegister = async (code: string) => {
    const signUpSettingsResponse = await getSignUpSettingsAPI();
    const signUpSettings = signUpSettingsResponse.data ?? FALLBACK_SIGNUP_SETTINGS;
    const retryPayload = {
      phoneVerification: {
        verifyCode: code,
        formattedPhoneNumber
      },
      featureSettings: [{
        type: UserSettingType.HIDE_GENDER,
        value: signUpSettings.genderSettings.hideGenderDefaultValue
      }],
      cmpConsentsAccepted: consentsUiType === 'CONSENT_WEB_A'
    };
    register(retryPayload);
  };

  // 로그인 중 NotVerifiedException 에러가 발생했을 때 재시도를 위한 로직
  const retryLogin = async (code: string) => {
    if (!loginRetryData) return;
    const retryPayload = {
      phoneVerification: {
        verifyCode: code,
        formattedPhoneNumber
      }
    };
    const originData = JSON.parse(loginRetryData.config.data);
    retryLoginWithPhoneVerify({
      ...loginRetryData.config,
      data: {
        ...originData,
        params: [{
          ...originData.params[0],
          ...retryPayload
        }]
      }
    });
    setLoginRetryData(null);
  };
  const handleVerifyCode = (code: string) => {
    switch (loginModalType) {
      case LoginModalType.SOCIAL_REGISTER:
        {
          retryRegister(code);
          break;
        }
      case LoginModalType.SOCIAL_LOGIN:
        {
          retryLogin(code);
          break;
        }
      case LoginModalType.PHONE_NUMBER_LOGIN:
        {
          verifyCodeMutation.mutate({
            localeInfo: {
              countryCode: null,
              languageCode: getServerSideLanguageCode().toLowerCase()
            },
            phoneVerification: {
              verifyCode: code,
              formattedPhoneNumber
            },
            platform: Platform.WebClient
          });
          break;
        }
      default:
        throw new Error('Invalid loginModalType in handleVerifyCode');
    }
  };
  return {
    handleVerifyCode,
    isLoading: verifyCodeMutation.isLoading,
    isSuccess: verifyCodeMutation.isSuccess,
    isError: verifyCodeMutation.isError
  };
}