// https://stomp-js.github.io/guide/stompjs/using-stompjs-v5.html
import { Client, IFrame, IPublishParams } from '@stomp/stompjs';

import store from 'src/stores';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { lastStompMessageAtom } from 'src/stores/stomp/atoms';
import { StompBrokerInfo } from 'src/types/AzarUser';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { MessageBrokerErrorCode } from 'src/types/MessageBroker';

/**
 * StompJS client를 생성하는 함수입니다.
 * @param stompBrokerInfo `LoginAPI`의 response가 `connectMediaBroker.request()`의 payload로 넘어옵니다.
 */
export function createWebclientConnection(
  stompBrokerInfo: StompBrokerInfo,
  onConnect: () => void,
  onError: (e: IFrame | Error) => void
) {
  const { uri, login, passcode } = stompBrokerInfo;
  store.set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
    eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
    eventParams: { debug: 'stomp.createWebclientConnection', stompBrokerInfo },
  });
  const client = new Client({
    brokerURL: uri.replace(/^tls?:\/\//i, 'wss://'),
    connectHeaders: { login, passcode },
    // heartbeat를 받지못하면, 웹소켓을 재연결
    discardWebsocketOnCommFailure: true,
    // debug: function(msg: string): void {
    //   console.info('stomp debg', new Date(), msg);
    // },
    onConnect: (frame) => {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onConnect', frame },
      });
      let attemp = 0;
      let timerId: ReturnType<typeof setInterval> | null;

      // ping/pong 응답을 핸들링 하기 위해, 원래의 onmessage 함수를 덮어씌웁니다.
      if (client.webSocket) {
        const stompOnMessageCallback = client.webSocket.onmessage;
        if (stompOnMessageCallback) {
          client.webSocket.onmessage = (event) => {
            stompOnMessageCallback.bind(client.webSocket)(event);
            const str =
              typeof event.data === 'string' ? event.data : new TextDecoder().decode(event.data);
            store.set(lastStompMessageAtom, {
              message: str,
              timestamp: new Date().getTime(),
            });
          };
        }
      }

      const check = () => {
        if (client.webSocket?.readyState === WebSocket.OPEN) {
          if (timerId) {
            clearInterval(timerId);
            timerId = null;
          }
          store.set(eventMutateAtom, {
            eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
            eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
            eventParams: { debug: 'stomp.onConnect check pass' },
          });
          onConnect();
        } else {
          attemp += 1;
          if (attemp === 5) {
            if (timerId) {
              clearInterval(timerId);
              timerId = null;
              store.set(eventMutateAtom, {
                eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
                eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
                eventParams: {
                  debug: 'stomp.onConnect check timeout',
                  attemp,
                  readyState: client.webSocket?.readyState,
                },
              });
              onError(new Error('websocket connection timeout'));
            }
          }
        }
      };
      timerId = setInterval(check, 500);
    },
    onStompError(message) {
      const {
        headers: { ['error-code']: errorCode },
      } = message;

      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onStompError', message },
      });

      switch (Number(errorCode)) {
        /*  stomp credential expire시 onStompError fire */
        case MessageBrokerErrorCode.LoginErrorCode:
        case MessageBrokerErrorCode.LoginExpiredErrorCode:
          onError(message);
          break;
        /* TODO: 그 이외 에러에 대해서 어떻게 핸들링하는지 찾아야 함. */
        default:
          break;
      }
    },
    onDisconnect(frame) {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onDisconnect', frame },
      });
    },
    onWebSocketClose(event) {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onWebSocketClose', event },
      });
    },
    onWebSocketError(event) {
      store.set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'stomp.onWebSocketError', event },
      });
    },

    reconnectDelay: 5000,
  });

  client.activate();
  return client;
}

export function closeChannel(subscriptionId: string, client: Client) {
  client.unsubscribe(subscriptionId);
}

/**
 * stompJS client에 메시지를 보내는 함수입니다.
 *
 * @param client stompJS client
 * @param destination matchReducer `signalingInfo.channelId`값을 전달받습니다.
 * @param clientId matchReducer `signalingInfo.clientId` 값을 전달 받습니다.
 * @param body 메시지 내용입니다.
 */
export function sendMessage(client: Client, destination: string, clientId: string, body: string) {
  const iPublishParams = {
    destination,
    headers: { 'sender-id': clientId },
    body,
  } as IPublishParams;
  client.publish(iPublishParams);
}

// match info message sample
//  {
//   "matchId":"V6201912198a88656d71866b67e89ccef848620a08b1944ff70166ec07f18ed7d855dbd7",
//   "peerProfile":{
//     "uid":"306116",
//     "loginType":"FACEBOOK",
//     "simpleName":"창•",
//     "veiledName":"창•",
//     "gender":"MALE",
//     "country":"KR",
//     "language":"ko",
//     "location":{
//       "country":"South Korea",
//       "countryCode":"KR",
//       "state":null,
//       "city":null,
//       "timeZoneId":"America/New_York",
//       "timeZoneOffset":-18000
//     },
//     "position":{
//       "latitude":37.566,
//       "longitude":126.9784
//     },
//     "screenshotAllowed":false,
//     "requestFriendAllowed":true,
//     "highDefinitionVideo":false,
//     "maxVideoBandwidthKbps":500,
//     "sayHi":"An-nyeong",
//     "sayCompliment":"Mert-it-er-yo",
//     "sayAskFriend":"chin-gu-hal-ka-yo?",
//     "thumbnailImageUrl":"http://dyna0zvk5krbd.cloudfront.net/public/7b114be9-5b68-4834-aff1-f5b691226e3d.jpg",
//     "profileImageUrl":"http://dyna0zvk5krbd.cloudfront.net/public/b4d20f5d-5890-44db-9566-e122b0a50f53.jpg",
//     "coverProfileImageUrl":"https://d3cmj2pfvp1c98.cloudfront.net/cover-profile/imgCover28.jpg",
//     "coolPoint":0,
//     "popularity":"0",
//     "profileMessage":null,
//     "textChatSupported":true,
//     "faceDetectionRequired":true,
//     "confirmedVisualAbuse":false,
//     "confirmedToplessAbuse":false,
//     "mutualInterest":null,
//     "interestInfos":[],
//     "monitorability":"MONITORABLE",
//     "receivedGiftRecently":false,
//     "lightweightGiftPointsInfo":{
//       "pointList":[]
//     },
//     "type":"peerProfile"
//   },
//   "initiator":true,
//   "canTranslateTextChat":true,
//   "canTranslateVoice":true,
//   "safeMatchIntervalMs":2000,
//   "safeMatchAutoReportDurationLimitMs":30000,
//   "safeMatchAutoReportConfidence":"0.80",
//   "safeMatchIntervalMsAfterAutoReport":5000,
//   "safeMatchMaxSample":20,
//   "disableRedUlpfec":true,
//   "supportSdes":false,
//   "type":"matchInfo"
// }
