export const checkImage = (image: Exclude<CanvasImageSource, VideoFrame>) => {
  if (image instanceof HTMLVideoElement) {
    if (image.videoWidth === 0 || image.videoHeight === 0) {
      return;
    }
    if (image.width === 0 || image.height === 0) {
      image.width = image.videoWidth;
      image.height = image.videoHeight;
    }
  } else if (image.width === 0 || image.height === 0) {
    return;
  }
  return image;
};

export const getImage = (videoEl: HTMLVideoElement) =>
  checkImage(
    (() => {
      if (!videoEl.srcObject) {
        return videoEl;
      }
      const videoTrack = (videoEl.srcObject as MediaStream)?.getVideoTracks?.()?.[0];
      if (!videoTrack) {
        return videoEl;
      }
      const canvasCaptureMediaStreamTrack = videoTrack as CanvasCaptureMediaStreamTrack;
      if (canvasCaptureMediaStreamTrack.canvas) {
        return canvasCaptureMediaStreamTrack.canvas;
      }
      return videoEl;
    })()
  );
