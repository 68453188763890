import { atom } from 'jotai';
import { supportsAvif, supportsWebp } from 'src/utils/imageUtils';
export const isWebpSupportedAtom = atom<null | boolean>(null);
export const isAvifSupportedAtom = atom<null | boolean>(null);
export const setWebpAndAvifSupportAtom = atom(null, async (get, set) => {
  const [webp, avif] = await Promise.allSettled([supportsWebp(), supportsAvif()]);
  if (webp.status === 'fulfilled') {
    set(isWebpSupportedAtom, webp.value);
  } else if (webp.status === 'rejected') {
    set(isWebpSupportedAtom, false);
  }
  if (avif.status === 'fulfilled') {
    set(isAvifSupportedAtom, avif.value);
  } else if (avif.status === 'rejected') {
    set(isAvifSupportedAtom, false);
  }
});