import styled from '@emotion/styled';

import {
  ModalContent as ModalContentBase,
  ModalLoading as ModalLoadingBase,
} from 'src/components/ModalLayout';

export const ModalContent = styled(ModalContentBase)`
  padding: 16px 16px 24px 16px;
`;

export const ModalLoading = styled(ModalLoadingBase)`
  height: 470px;
`;
