import { FC, useEffect, useRef, useState } from 'react';
import { CountUp } from 'countup.js';
interface ICounter {
  start: number;
  end: number;
  className?: string;
}
const Counter: FC<ICounter> = ({
  start,
  end,
  className
}) => {
  const countUpRef = useRef(null);
  const [countUp, setCountUp] = useState<CountUp | null>(null);
  useEffect(() => {
    if (!countUpRef?.current || !!countUp) return;
    const newCountUp = new CountUp(countUpRef.current, end, {
      startVal: start
    });
    newCountUp.update(end);
    setCountUp(newCountUp);
  }, [countUp, end, start]);
  useEffect(() => {
    if (end && countUp) {
      countUp.update(end);
    }
  }, [countUp, end]);
  return <div ref={countUpRef} className={className} data-sentry-component="Counter" data-sentry-source-file="index.tsx" />;
};
export default Counter;