import { clearStream } from 'src/utils/media/stream';
import { detachMediaElement } from 'src/utils/media/view';

class MediaStreamPipe {
  protected type: 'main' | 'deco' | null = null;

  protected videoEl: HTMLVideoElement | null = null;

  public mediaStream: MediaStream | null = null;

  protected canvasEl: HTMLCanvasElement | null = null;

  protected drawOriginalVideo() {
    const canvasEl = this.canvasEl;
    const videoEl = this.videoEl;
    if (!canvasEl || !videoEl) {
      return;
    }
    const { videoWidth, videoHeight } = videoEl;
    canvasEl.width = videoWidth;
    canvasEl.height = videoHeight;
    const context = canvasEl.getContext('2d');
    if (!context) {
      return;
    }

    context.clearRect(0, 0, videoWidth, videoHeight);
    context.drawImage(videoEl, 0, 0, videoWidth, videoHeight);
  }

  public setEl(videoEl: HTMLVideoElement, canvasEl?: HTMLCanvasElement, type?: 'main' | 'deco') {
    this.videoEl = videoEl;
    const nextCanvasEl = canvasEl || document.createElement('canvas');
    this.canvasEl = nextCanvasEl;
    this.type = type || 'main';
  }

  public captureMediaStream() {
    // firefox에서 captureStream() 호출 시, NS_ERROR_NOT_INITIALIZED 에러 발생함
    this.canvasEl?.getContext('2d');
    this.mediaStream = this.canvasEl?.captureStream() || null;
    return this.mediaStream;
  }

  public stopRendering() {}

  public dispose() {
    if (this.videoEl) {
      detachMediaElement(this.videoEl);
      this.videoEl = null;
    }
    if (this.mediaStream) {
      clearStream(this.mediaStream);
      this.mediaStream = null;
    }
  }

  public startRendering() {}

  public renderVideo() {}

  public render() {}
}

export default MediaStreamPipe;
