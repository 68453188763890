import React, { useRef } from 'react';

const useIntersection = (
  handleIntersect: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  const intersectionObserverRef = useRef<IntersectionObserver>();

  const observerRef = React.useCallback(
    (targetElement: HTMLElement | null) => {
      if (targetElement) {
        const observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(targetElement);
        intersectionObserverRef.current = observer;
      } else {
        // cleanup
        intersectionObserverRef.current?.disconnect();
      }
    },
    [handleIntersect, options]
  );
  return { observerRef };
};

export default useIntersection;
