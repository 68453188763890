import useLogin from 'src/hooks/useLogin';
import { RestQueryOptions, useRestQuery } from 'src/hooks/useRestQuery';
import { ConsentApiList } from 'src/stores/auth/apis';
import { GetConsentsRevisionsResponse } from 'src/types/consent';

export const useGetConsentRevisionQuery = ({
  enabled = true,
  ...options
}: RestQueryOptions<GetConsentsRevisionsResponse> = {}) => {
  const { isLogin } = useLogin();

  return useRestQuery<void, GetConsentsRevisionsResponse, void>({
    path: ConsentApiList.GetConsentRevision,
    options: {
      ...options,
      enabled: enabled && isLogin,
    },
  });
};
