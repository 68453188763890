import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue } from 'jotai';

import { azarTokenAtom } from 'src/stores/auth/atoms';
import { client, clientWithoutAuth } from 'src/utils/api';

export type RestQueryOptions<TResponse = void, TError = void> = UseQueryOptions<
  AxiosResponse<TResponse>,
  AxiosError<TError>,
  AxiosResponse<TResponse>,
  QueryKey
>;

export const useRestQuery = <TParams, TResponse, TError>({
  path,
  params,
  withoutAuth = false,
  options,
}: {
  path: string;
  params?: TParams;
  withoutAuth?: boolean;
  options?: UseQueryOptions<AxiosResponse<TResponse>, AxiosError<TError>, AxiosResponse<TResponse>>;
}) => {
  const token = useAtomValue(azarTokenAtom);
  const apiClient = withoutAuth ? clientWithoutAuth : client;

  return useQuery<AxiosResponse<TResponse>, AxiosError<TError>, AxiosResponse<TResponse>>({
    queryKey: [path, params],
    queryFn: async () =>
      apiClient.get<TResponse>(path, {
        params: params ? { ...params } : null,
      }),
    enabled: withoutAuth ? true : !!token,
    ...options,
  });
};
