import { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import IcTimerBase from 'src/assets/images/icHistoryFill.svg';
import GemAmountTextWithBonusHighlight, { BonusGemText } from 'src/components/GemAmountTextWithBonusHighlight';
import useProductWithPurchaseLimit from 'src/hooks/useProductWithPurchaseLimit';
import { StarterPackagePopupPage } from 'src/types/gemLack';
import { getProductPriceTextWithCurrency, getSaveRateByGemProductComparison } from 'src/utils/payments';
import { PriceText, ProductImage as ProductImageBase, ProductImageWrapper as ProductImageWrapperBase, ProductItem as ProductItemBase, ProductLabelWrapper, ProductNameText, ProductPriceWrap } from './styles';
import useClickProductItem from './useClickProductItem';
const ProductItem = styled(ProductItemBase)`
  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;
const ProductImageWrapper = styled(ProductImageWrapperBase)`
  flex-direction: column;
`;
const ProductImage = styled(ProductImageBase)`
  width: 58px;
  height: 58px;
`;
const SaveRateLabel = styled.div`
  padding: 4px 8px;
  border-radius: 11px;
  background-color: ${({
  theme
}) => theme.color.surfaceDeepFixed};
  width: fit-content;

  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  color: ${({
  theme
}) => theme.color.accentH1};
`;
const DesktopSaveRateLabel = styled(SaveRateLabel)`
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: none;
  }
`;
const MobileSaveRateLabel = styled(SaveRateLabel)`
  display: none;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: block;
    margin-bottom: 4px;
  }
`;
const TimerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  color: ${({
  theme
}) => theme.color.contentDeep};
`;
const IcTimer = styled(IcTimerBase)`
  width: 14px;
  height: 14px;
`;
const RemainingTimeText = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
`;
const ProductInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ${({
  theme
}) => theme.screenSize.mobile} {
    gap: 4px;
  }
`;
const ProductContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
const GemIcon = styled.img`
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({
  theme
}) => theme.screenSize.mobile} {
    display: none;
  }
`;
const GemAmountText = styled(GemAmountTextWithBonusHighlight)`
  font-size: 15px;
  line-height: 20px;

  ${BonusGemText} {
    font-weight: 400;
  }
`;
interface Props {
  starterPackagePopupPage: StarterPackagePopupPage;
}
const StarterPackageItem: FC<Props> = ({
  starterPackagePopupPage
}) => {
  const [singleProductModule, starterPackageModule] = starterPackagePopupPage.sections[0].modules;
  const {
    handleClickProduct
  } = useClickProductItem(starterPackageModule);
  const {
    remainingTime,
    isPurchasable
  } = useProductWithPurchaseLimit(starterPackageModule);
  const {
    t
  } = useTranslation();

  /**
   * 서버에서 관련해서 내려주는 필드가 없어서 동일 금액의 다른 상품과 임의 비교
   */
  const starterPackageSaveRate = useMemo(() => {
    return getSaveRateByGemProductComparison(starterPackageModule, singleProductModule);
  }, [starterPackageModule, singleProductModule]);
  const handleClickStarterPackage = () => {
    if (!isPurchasable) return;
    handleClickProduct();
  };
  const saveRateText = t('web_starterpackage_discount', {
    percent: starterPackageSaveRate
  });
  return <ProductItem disabled={!isPurchasable} onClick={handleClickStarterPackage} data-sentry-element="ProductItem" data-sentry-component="StarterPackageItem" data-sentry-source-file="StarterPackageItem.tsx">
      <ProductImageWrapper backgroundColor={starterPackageModule.content.iconBackgroundColor} data-sentry-element="ProductImageWrapper" data-sentry-source-file="StarterPackageItem.tsx">
        <MobileSaveRateLabel data-sentry-element="MobileSaveRateLabel" data-sentry-source-file="StarterPackageItem.tsx">{saveRateText}</MobileSaveRateLabel>
        <ProductImage src={starterPackageModule.iconUrl} alt={`${starterPackageModule.productName} icon`} data-sentry-element="ProductImage" data-sentry-source-file="StarterPackageItem.tsx" />
        {remainingTime && <TimerWrap>
            <IcTimer />
            <RemainingTimeText>{remainingTime.text}</RemainingTimeText>
          </TimerWrap>}
      </ProductImageWrapper>
      <ProductLabelWrapper data-sentry-element="ProductLabelWrapper" data-sentry-source-file="StarterPackageItem.tsx">
        <ProductInfoWrap data-sentry-element="ProductInfoWrap" data-sentry-source-file="StarterPackageItem.tsx">
          <DesktopSaveRateLabel data-sentry-element="DesktopSaveRateLabel" data-sentry-source-file="StarterPackageItem.tsx">{saveRateText}</DesktopSaveRateLabel>
          <ProductNameText data-sentry-element="ProductNameText" data-sentry-source-file="StarterPackageItem.tsx">{starterPackageModule.productName}</ProductNameText>
          <ProductContentRow data-sentry-element="ProductContentRow" data-sentry-source-file="StarterPackageItem.tsx">
            <GemIcon src={starterPackageModule.content.gemIconUrl} alt='gem icon' data-sentry-element="GemIcon" data-sentry-source-file="StarterPackageItem.tsx" />
            <GemAmountText gemProduct={starterPackageModule} data-sentry-element="GemAmountText" data-sentry-source-file="StarterPackageItem.tsx" />
          </ProductContentRow>
        </ProductInfoWrap>
        <ProductPriceWrap data-sentry-element="ProductPriceWrap" data-sentry-source-file="StarterPackageItem.tsx">
          <PriceText data-sentry-element="PriceText" data-sentry-source-file="StarterPackageItem.tsx">{getProductPriceTextWithCurrency(starterPackageModule)}</PriceText>
        </ProductPriceWrap>
      </ProductLabelWrapper>
    </ProductItem>;
};
export default StarterPackageItem;