import { useCallback, useEffect, useRef } from 'react';

import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import useInterval from 'src/hooks/useInterval';
import useLogin from 'src/hooks/useLogin';
import { azarTokenAtom, remoteConfigAtom } from 'src/stores/auth/atoms';
import { heartbeatAPI, heartbeatVisitorAPI, OnlineType } from 'src/stores/Heartbeat/apis';
import { matchInfoAtom, statusAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';
import getDeviceId from 'src/utils/device/id';

const POLLING_INTERVAL = 1000 * 60 * 3;

export default function useHeartbeat() {
  const remoteConfig = useAtomValue(remoteConfigAtom);

  const { isLogin } = useLogin();
  const uuid = getDeviceId();
  const azarToken = useAtomValue(azarTokenAtom);
  const matchInfo = useAtomValue(matchInfoAtom);

  const { pathname } = useRouter();
  const status = useAtomValue(statusAtom);
  const onlineTypeRef = useRef<OnlineType>(OnlineType.ETC);

  useEffect(() => {
    switch (pathname) {
      case '/':
      case '/[locale]':
        onlineTypeRef.current = status === STATUS.INITIAL ? OnlineType.MIRROR : OnlineType.MATCH;
        break;
      case '/live':
      case '/[locale]/live':
      case '/live/host':
      case '/[locale]/live/host':
        onlineTypeRef.current = OnlineType.LIVE;
        break;
      case '/live/publish':
      case '/[locale]/live/publish':
        onlineTypeRef.current = OnlineType.LIVE_STREAMING;
        break;
      case '/about':
      case '/[locale]/about':
        onlineTypeRef.current = OnlineType.ABOUT;
        break;
      case '/history':
      case '/[locale]/history':
        onlineTypeRef.current = OnlineType.HISTORY;
        break;
      default:
        onlineTypeRef.current = OnlineType.ETC;
    }
  }, [pathname, status]);

  const heartbeat = useCallback(() => {
    if (azarToken?.accessToken) {
      heartbeatAPI({
        onlineType: onlineTypeRef.current,
        matchId: matchInfo?.matchId,
      });
      return;
    }

    if (uuid) {
      heartbeatVisitorAPI({
        onlineType: onlineTypeRef.current,
        uuid,
      });
    }
  }, [azarToken, matchInfo, uuid]);

  useEffect(() => {
    if (isLogin) {
      heartbeat();
    }
    if (!isLogin) {
      const id = setTimeout(heartbeat, 3000);
      return () => clearTimeout(id);
    }
  }, [heartbeat, isLogin]);

  useInterval(heartbeat, remoteConfig?.heartbeatIntervalMS || POLLING_INTERVAL);
}
