import { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import ButtonBase from 'src/components/Button';
import ModalLayoutBase, { MobileModalType } from 'src/components/ModalLayout';
const ModalLayout = styled(ModalLayoutBase)`
  width: 400px;
  padding: 72px 32px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    padding: 91px 32px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;
const PlaceholderImage = styled.img`
  width: 260px;
  height: 160px;
`;
const GuideText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: ${({
  theme
}) => theme.color.content8};
  white-space: pre-wrap;
`;
const RetryButton = styled(ButtonBase)`
  min-width: 178px;
  max-width: 100%;
  width: fit-content;

  ${({
  theme
}) => theme.screenSize.mobile} {
    min-width: 120px;
  }
`;
interface Props {
  onRetry: () => void;
  onClose?: () => void;
}
const ErrorRetryModal: FC<Props> = ({
  onRetry,
  onClose
}) => {
  const {
    t
  } = useTranslation();
  return <ModalLayout onClose={onClose} mobileType={MobileModalType.BOTTOM_SHEET} isDim data-sentry-element="ModalLayout" data-sentry-component="ErrorRetryModal" data-sentry-source-file="index.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <PlaceholderImage src='/images/error/imgPlaceholder.svg' alt='Error placeholder' data-sentry-element="PlaceholderImage" data-sentry-source-file="index.tsx" />
        <GuideText data-sentry-element="GuideText" data-sentry-source-file="index.tsx">{t('HOME__ERROR_CODE_NETWORK_DISCONNECT')}</GuideText>
        <RetryButton id='Error_Retry_Button' onClick={onRetry} data-sentry-element="RetryButton" data-sentry-source-file="index.tsx">
          {t('common__try_again_button')}
        </RetryButton>
      </Container>
    </ModalLayout>;
};
export default ErrorRetryModal;