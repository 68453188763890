import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import ButtonBase from 'src/components/Button';
import ModalLayout, { MobileModalType, ModalContent, ModalFooter, ModalHeader, ModalHeaderTitle } from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { acceptCmpConsentsAtom, cmpConsentsAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
const Wrap = styled.div`
  max-width: 400px;
  ${({
  theme
}) => theme.screenSize.mobile} {
    max-width: 100%;
  }
`;
const Button = styled(ButtonBase)`
  margin-bottom: 18px;
`;
const Desc = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;

  color: ${({
  theme
}) => theme.color.content8};
  a {
    color: ${({
  theme
}) => theme.color.content8};
    font-weight: 700;
  }
`;
const CmpConsentsModal: FC = () => {
  const consent = useAtomValue(cmpConsentsAtom);
  const acceptCmp = useSetAtom(acceptCmpConsentsAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();
  useEffect(() => {
    if (consent) {
      pushEvent({
        eventName: EVENT_NAME.CONSENT_POPUP__IMPRESSION,
        eventType: EVENT_TYPE.SIGN_UP
      });
    }
  }, [pushEvent, consent]);
  const handleAccept = () => {
    acceptCmp();
    closeModal(ModalType.CMP_CONSENTS);
  };
  return consent ? <ModalLayout isDim closeWhenClickOutside={false} mobileType={MobileModalType.BOTTOM_SHEET} data-testid='Cmp-Consent-Modal' data-sentry-element="ModalLayout" data-sentry-component="CmpConsentsModal" data-sentry-source-file="index.tsx">
      <Wrap data-sentry-element="Wrap" data-sentry-source-file="index.tsx">
        <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="index.tsx">
          <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="index.tsx">
            {consent?.popup.title}
          </ModalHeaderTitle>
        </ModalHeader>
        <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="index.tsx">
          <Desc dangerouslySetInnerHTML={{
          __html: consent?.popup.description || ''
        }} data-sentry-element="Desc" data-sentry-source-file="index.tsx" />
        </ModalContent>
      </Wrap>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="index.tsx">
        <Button data-testid='Confirm-Button' onClick={handleAccept} id='CONSENT_AGREE' data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {consent?.popup.confirmButtonText}
        </Button>
      </ModalFooter>
    </ModalLayout> : null;
};
export default CmpConsentsModal;