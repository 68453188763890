import { Platform } from 'src/types/Platform';
import { client } from 'src/utils/api';

export enum OnlineType {
  MIRROR = 'MIRROR',
  MESSAGE = 'MESSAGE',
  LOUNGE = 'LOUNGE',
  HISTORY = 'HISTORY',
  MATCH = 'MATCH',
  LIVE = 'LIVE',
  LIVE_STREAMING = 'LIVE_STREAMING', // 방송송출 상태 (방송시청은 LIVE로 전송)
  ABOUT = 'ABOUT',
  ETC = 'ETC',
}

export const heartbeatAPI = ({
  onlineType,
  matchId,
}: {
  onlineType: OnlineType;
  matchId?: string | null;
}) => {
  return client.post('/onair/v1/heartbeat', {
    onlineType,
    ...(onlineType === OnlineType.MATCH ? { matchId } : {}),
  });
};
export const heartbeatVisitorAPI = ({
  onlineType,
  uuid,
}: {
  onlineType: OnlineType;
  uuid: string;
}) =>
  client.post('/onair/v1/heartbeat/visitors', {
    onlineType,
    visitorUserId: uuid,
    platform: Platform.WebClient,
  });
