export const getRandomElement = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};

/**
 * 주어진 배열 내에서 특정 요소가 맨 앞으로 위치하도록 정렬 (원본 변경 X)
 */
export const prioritizeElementInArray = <T>(arr: T[], prioritizedElement: T) => {
  return [...arr].sort((a, b) => {
    if (a === prioritizedElement) return -1;
    if (b === prioritizedElement) return 1;
    return 0;
  });
};

/**
 * 주어진 배열을 기준으로 함수가 true를 반환하는 요소와 false를 반환하는 요소로 나누어 반환
 * validator에 부합하는 요소들이 첫 번째 배열에, 부합하지 않는 요소들이 두 번째 배열에 위치
 */
export const partition = <T>(array: T[], validator: (element: T) => boolean): [T[], T[]] => {
  return array.reduce<[T[], T[]]>(
    ([pass, fail], elem) => {
      return validator(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
};
