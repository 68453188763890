import { StarterKitConfig } from '@hyperconnect/matata-starter-kit';
import store from 'src/stores';
import { nextPagePropsAtom } from 'src/stores/pageProps/atoms';

/**
 * API서버 번역에 사용될 language code
 * ISO 639-1 언어코드 (영문2자리)
 */
export const getServerSideLanguageCode = () => {
  const webviewLocale = typeof window !== 'undefined' && window.AzarJs ? window.AzarJs.getUiLocale?.() : undefined;
  const pathLocale = store.get(nextPagePropsAtom)?.staticGenerationLanguage;
  const fallbackLocale = navigator.language;
  return (webviewLocale || pathLocale || fallbackLocale).substring(0, 2).toLowerCase();
};
export enum SupportLanguage {
  AR = 'ar',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  ES_MX = 'es_MX',
  FR = 'fr',
  HI = 'hi',
  ID = 'id',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NL = 'nl',
  PT = 'pt',
  PT_BR = 'pt_BR',
  RU = 'ru',
  SV = 'sv',
  TH = 'th',
  TR = 'tr',
  VI = 'vi',
  ZH_CN = 'zh_CN',
  ZH_RTW = 'zh_rTW',
}
export const MatataLocaleMap: Record<SupportLanguage, Required<StarterKitConfig['locale']>> = {
  [SupportLanguage.AR]: 'ar',
  [SupportLanguage.DE]: 'de',
  [SupportLanguage.EN]: 'en',
  [SupportLanguage.ES]: 'es-ES',
  [SupportLanguage.ES_MX]: 'es-MX',
  [SupportLanguage.FR]: 'fr',
  [SupportLanguage.HI]: 'hi',
  [SupportLanguage.ID]: 'id',
  [SupportLanguage.IT]: 'it',
  [SupportLanguage.JA]: 'ja',
  [SupportLanguage.KO]: 'ko',
  [SupportLanguage.TR]: 'tr',
  [SupportLanguage.ZH_CN]: 'zh-CN',
  [SupportLanguage.ZH_RTW]: 'zh-TW',
  [SupportLanguage.TH]: 'th',
  [SupportLanguage.RU]: 'ru',
  [SupportLanguage.SV]: 'sv-SE',
  [SupportLanguage.VI]: 'vi',
  [SupportLanguage.NL]: 'nl',
  [SupportLanguage.PT]: 'pt-PT',
  [SupportLanguage.PT_BR]: 'pt-BR'
};
const DEFAULT_LANGUAGE = SupportLanguage.EN;
export const getLocale: () => SupportLanguage = () => {
  const userLanguage = getServerSideLanguageCode() as SupportLanguage;
  return Object.values(SupportLanguage).includes(userLanguage) ? userLanguage : DEFAULT_LANGUAGE;
};