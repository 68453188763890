import appConfig from 'app-config';

import isDark from './isDark';
import azarReferenceColor from './referenceColor/azarReferenceColor';
import ReferenceColor from './referenceColor/referenceColorType';
import vividiReferenceColor from './referenceColor/vividiReferenceColor';
import azarSystemColor from './systemColor/azarSystemColor';
import { SystemColor } from './systemColor/systemColorType';
import vividiSystemColor from './systemColor/vividiSystemColor';

const brandList = ['facebook1', 'facebook2', 'kakao1', 'kakao2', 'line1', 'line2', 'x1', 'x2'];

export const createSystemColor: (
  reference: ReferenceColor,
  system: SystemColor
) => Record<keyof SystemColor, string> = (reference, system) => {
  return Object.fromEntries(
    Object.entries(system).map(([key, value]) => {
      if (brandList.some((brand) => brand === key)) {
        return [key, value];
      }

      const colorValue = reference[value as keyof ReferenceColor];

      if (typeof colorValue === 'string') {
        return [key, colorValue];
      }

      return [key, colorValue[isDark ? 'dark' : 'light']];
    })
  ) as Record<keyof SystemColor, string>;
};

const getServiceColors = () => {
  switch (appConfig.serviceCode) {
    case 'vividi':
      return {
        referenceColor: vividiReferenceColor,
        systemColor: vividiSystemColor,
      };
    case 'azar':
    default:
      return {
        referenceColor: azarReferenceColor,
        systemColor: azarSystemColor,
      };
  }
};
const colors = getServiceColors();

const color = createSystemColor(colors.referenceColor, colors.systemColor);

export default color;
