import styled from '@emotion/styled';

import { hoverAndActiveCss } from 'src/utils/styled';

export const ProductPriceWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.surface5};
  border-radius: 12px;
  padding: 8px 16px;

  ${({ theme }) => theme.screenSize.mobile} {
    width: 100%;
    margin-left: 0px;
  }
`;

export const ProductItem = styled.button`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.color.surface4};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  align-items: stretch;
  text-align: unset;
  min-height: 94px;

  ${({ theme }) => theme.screenSize.mobile} {
    min-height: 108px;
  }

  ${({ theme }) =>
    hoverAndActiveCss(`
      background-color: ${theme.color.surface5};
      ${ProductPriceWrap} {
        background-color: ${theme.color.surface6};
      }
  `)}
`;

export const ProductImageWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 117px;
  line-height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  justify-content: center;
  align-items: center;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ProductLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 16px;
  gap: 8px;

  ${({ theme }) => theme.screenSize.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ProductNameText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.color.content12};
`;

export const PriceText = styled.span`
  color: ${({ theme }) => theme.color.content12};
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
`;
