import { atom } from 'jotai';

import { getAtomWithSessionStorage } from 'src/utils/localStorageUtils';

const initialState = {};

export type TUTMParams = Record<string, string | string[] | undefined>;

export const UTMParamsAtom = getAtomWithSessionStorage<TUTMParams>('utm_params', initialState);
export const setUTMParamsAtom = atom(null, (get, set, newUTMParams: TUTMParams) => {
  set(UTMParamsAtom, {
    ...get(UTMParamsAtom),
    ...newUTMParams,
  });
});

export const pushedUTMFirstEventAtom = getAtomWithSessionStorage<boolean>(
  'pushed_utm_first_event',
  false
);
