import { useEffect } from 'react';

import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import QueryString from 'qs';

import useEvent from 'src/hooks/useEvent';
import useSelectedTab from 'src/hooks/useSelectedTab';
import { livePageAtom } from 'src/stores/live/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { getUTMParams } from 'src/utils/common';

export default function useScreenOpen() {
  const router = useRouter();
  const pushEvent = useEvent();
  const { selectedTab } = useSelectedTab();
  const livePage = useAtomValue(livePageAtom);

  useEffect(() => {
    if (router.isReady) {
      window.addPagePageViewGtag({ page_path: router.pathname });
      const debounceScreenOpenEvent = setTimeout(() => {
        pushEvent({
          eventType: EVENT_TYPE.WEB,
          eventName: EVENT_NAME.SCREEN_OPENED,
          eventParams: {
            tab: selectedTab.name,
            path: router.pathname,
            queryParams: QueryString.stringify(router.query),
            // 현재 screen_opened 이벤트는 live 관련 집계에서만 사용됨.
            // 따라서, live에서만 page 정보를 전달함.
            // page를 전체 적용하는 작업은 별도 작업예정
            // https://hyperconnect.atlassian.net/browse/WEB-3601
            ...(livePage && { page: livePage }),

            // HTTP referrer와 함께 페이지에 진입한 경우 (일반적인 케이스 추적)
            ...(document.referrer && { documentReferrer: document.referrer }),
            // query param에 referrer가 있는 경우 (바이럴 이벤트 등, 특정 케이스 추적)
            ...(router.query.referrer && { referrer: router.query.referrer }),
          },
          options: { utm_params: getUTMParams(router.query) },
        });
      }, 100);
      return () => {
        clearTimeout(debounceScreenOpenEvent); // 이 전의 timer를 clear합니다.
      };
    }
  }, [pushEvent, router.pathname, router.isReady, router.query, selectedTab.name, livePage]);
}
