import color from './color';
import isDark from './isDark';
import pointer from './pointer';
import screenSize from './screenSize';
import scroll from './scroll';
import { textShadow } from './textShadow';

const theme = {
  color,
  isDark,
  scroll,
  textShadow,
  screenSize,
  pointer,
  isRtl: false,
};

export default theme;
