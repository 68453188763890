import axios from 'axios';

import { VerifyAccountResponse } from 'src/stores/phoneNumberLogin/types';
import { GetFeatureSettingsPopupResponse } from 'src/stores/user/types';
import { AzarUser } from 'src/types/AzarUser';
import { CpmContentResponse } from 'src/types/consent';
import { LoginType, RegisterParamsBase } from 'src/types/register';
import { MatataToken } from 'src/types/Token';
import { client, clientWithoutAuth, RPCThrowableError, rpcClient } from 'src/utils/api';
import { getLoginParam } from 'src/utils/auth';

export enum ConsentApiList {
  ReportConsent = '/api/v2/consents/report',
  GetConsentRevision = '/api/v3/consents/revisions',
}

export enum LegalNoticeApiList {
  ConfirmLegalNotice = '/api/legal-notices/confirm',
}

export const GetGoogleUserInfoAPI = async (authorizationCode: string) => {
  const resp = await clientWithoutAuth.post(
    '/api/v1/accounts/google/token',
    { authorizationCode },
    { headers: { 'X-Requested-With': 'XmlHttpRequest' } }
  );
  // const { accessToken: access_token } = resp.data;

  // const profile = await axios.get('https://people.googleapis.com/v1/people/me', { params: { personFields:'birthdays,genders', access_token } });
  return [
    resp,
    // profile
  ];
};

export const GetFacebookUserInfoAPI = (access_token: string) =>
  axios.get('https://graph.facebook.com/me', {
    params: {
      access_token,
      fields: 'birthday,gender',
    },
  });

const CMP_CONSENTS_ENDPOINT = '/cmp/v1/consents/user/message';
export const acceptCmpConsentsAPI = (code: string) =>
  client.post<CpmContentResponse>(CMP_CONSENTS_ENDPOINT, { consent: { code } });

export const getCmpConsentsAPI = () => client.get<CpmContentResponse>(CMP_CONSENTS_ENDPOINT);

const FEATURE_SETTINGS_POPUP_ENDPOINT = '/api/popups/feature-settings';
export const getFeatureSettingsPopupAPI = () =>
  client.get<GetFeatureSettingsPopupResponse>(FEATURE_SETTINGS_POPUP_ENDPOINT);

export const getInventoryAPI = () =>
  client.get<{ data: AzarUser['inventoryItems'] }>('/api/v1/inventories');

export const matataTokenAPI = () => client.post<MatataToken>('/api/matata/users/token', {});

export const refreshAccessTokenAPI = (token?: string) =>
  clientWithoutAuth.post('/api/accounts/token', { refreshToken: token });

export const VisitorTokenAPI = () =>
  clientWithoutAuth.post<MatataToken>('/api/matata/users/token/visitor', {
    //마타타 토큰 관련은 withcredential설정 없어야해서 axios써야함
    countryCode: getLoginParam().localeInfo.languageCode,
    countryCodeFallback: 'XX',
  });

export const GetServersInfoAPI = () =>
  client.get<Pick<AzarUser, 'iceServers' | 'stompBrokerInfo'>>('/api/v1/servers-info');

export interface LoginEmailParams {
  email: string;
  password: string;
}

export const loginEmailAPI = (params: LoginEmailParams) => {
  const loginParams = { ...getLoginParam(), ...params };
  return rpcClient<typeof loginParams, LoginResponse>('loginWithEmail')(loginParams);
};

type OAuthMethods = LoginType;
export interface LoginOAuthParams {
  method: OAuthMethods;
  accessToken: string;
  id?: string;
}
export const LoginOauthMap: {
  [key in LoginType]: string;
} = {
  [LoginType.GOOGLE]: '',
  [LoginType.APPLE]: 'appleId',
  [LoginType.FACEBOOK]: 'facebookId',
  [LoginType.SMS]: 'phoneId',
};
export const LoginOAuthMethodMap: {
  [key in LoginType]: string;
} = {
  [LoginType.FACEBOOK]: 'loginWithFacebook2',
  [LoginType.GOOGLE]: 'loginWithGoogleV2',
  [LoginType.APPLE]: 'loginWithApple',
  [LoginType.SMS]: 'loginWithPhone',
};

export interface LoginResponse extends AzarUser {
  /**
   * 방금 회원가입한 유저인지 여부를 구분하는 flag
   */
  userSignedUp?: boolean;
}

export const loginOAuthAPI = ({ method, accessToken, id }: LoginOAuthParams) => {
  const params = {
    ...getLoginParam(),
    accessToken,
    ...(id && LoginOauthMap[method] ? { [LoginOauthMap[method]]: id } : {}),
  };
  return rpcClient<typeof params, LoginResponse, RPCThrowableError>(LoginOAuthMethodMap[method])(
    params
  );
};

type LoginWithPhoneNumberParams = VerifyAccountResponse;

export const loginWithPhoneNumberAPI = (params: LoginWithPhoneNumberParams) => {
  const loginParams = { ...getLoginParam(), ...params };
  return rpcClient<typeof loginParams, LoginResponse, RPCThrowableError>('loginWithPhone')(
    loginParams
  );
};

type LoginParamBase = ReturnType<typeof getLoginParam>;
export const loginWithAccessTokenAPI = (token: string) =>
  rpcClient<LoginParamBase, { accessToken: string; refreshToken: string }>('loginWithAccessToken', {
    headers: { Authorization: `Bearer ${token}` },
  })({ ...getLoginParam() });

interface RegisterEmailParams extends RegisterParamsBase {
  email: string;
  password: string;
}

export const registerAPI = (params: RegisterEmailParams) => {
  const loginParams = { ...getLoginParam(), ...params };
  return rpcClient<typeof loginParams, LoginResponse>('signUpWithEmail')(loginParams);
};
