interface WindowMessageBase {
  type: WindowMessageType;
}

export enum WindowMessageType {
  PurchaseComplete = 'purchaseComplete',
}

export interface PurchaseCompleteMessage extends WindowMessageBase {
  type: WindowMessageType.PurchaseComplete;

  /**
   * 모듈이 아닌 GemProduct일 경우 undefined 처리
   * @TODO 모든 상품이 모듈화되는 경우 required로 변경
   */
  productModuleType?: string;
}

export type WindowMessage = PurchaseCompleteMessage;
