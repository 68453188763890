import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import InduceRegisterModal from 'src/components/InduceRegisterModal';
import useMobileIdleTimer from 'src/hooks/useInduceRegisterModal/useMobileIdleTimer';
import useIsOpenModal from 'src/hooks/useIsOpenModal';
import useLogin from 'src/hooks/useLogin';
import { hasDeviceBeenLoggedInAtom, hasInduceRegisterBeenShownAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import getDeviceInfo from 'src/utils/device/info';

const useInduceRegisterModal = () => {
  const { isMobile } = getDeviceInfo();
  const hasDeviceBeenLoggedIn = useAtomValue(hasDeviceBeenLoggedInAtom);
  const hasInduceRegisterBeenShown = useAtomValue(hasInduceRegisterBeenShownAtom);
  const showModal = useSetAtom(showModalAtom);
  const { isLogin } = useLogin();
  const setHasDeviceBeenLoggedIn = useSetAtom(hasDeviceBeenLoggedInAtom);
  const isOpenLoginModal = useIsOpenModal(ModalType.LOGIN);

  useEffect(() => {
    if (isLogin) {
      setHasDeviceBeenLoggedIn(true);
    }
  }, [isLogin, setHasDeviceBeenLoggedIn]);

  const isEnableBouncePopup = !hasDeviceBeenLoggedIn && !hasInduceRegisterBeenShown;

  const { startIdleDetection, stopIdleDetection } = useMobileIdleTimer(20000, () => {
    if (isOpenLoginModal) return;
    showModal({
      key: ModalType.INDUCE_REGISTER_MODAL,
      component: () =>
        InduceRegisterModal({
          eventParams: {
            trigger: 'timeout',
          },
        }),
    });
  });

  // mobile idle detection
  useEffect(() => {
    if (!isMobile || !isEnableBouncePopup) return;
    if (isOpenLoginModal) return;

    startIdleDetection();

    return () => {
      stopIdleDetection();
    };
  }, [isEnableBouncePopup, isMobile, isOpenLoginModal, startIdleDetection, stopIdleDetection]);

  // desktop 마우스 이탈 이벤트 핸들러
  useEffect(() => {
    if (isMobile || !isEnableBouncePopup) return;

    const handleMouseLeave = (e: MouseEvent) => {
      const { clientY } = e;
      // 브라우저 상단 영역으로 마우스 이탈 했을 때 모달 노출
      if (Math.sign(clientY) === -1) {
        if (isOpenLoginModal) return;
        showModal({
          key: ModalType.INDUCE_REGISTER_MODAL,
          component: () =>
            InduceRegisterModal({
              eventParams: {
                trigger: 'screen_out',
                coordinate: JSON.stringify({ x: e.clientX, y: e.clientY }),
              },
            }),
        });
      }
    };

    document.body.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [isEnableBouncePopup, isMobile, isOpenLoginModal, showModal]);
};

export default useInduceRegisterModal;
