import styled from '@emotion/styled';
import RtlContainer from 'src/components/RtlContainer';
export const RootStyle = styled(RtlContainer)`
  overflow: hidden;
  display: flex;
  flex-grow: 1;
`;
export const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;
export const FullEmptyScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white;
`;