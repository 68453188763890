import { useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import useMatchPreference from 'src/components/AzarOnly/Match/Preference/useMatchPreference';
import GemLackModal from 'src/components/GemLackModal';
import { userCountryCodeAtom } from 'src/stores/auth/atoms';
import { matchFiltersAtom, matchFilterSelectionsAtom } from 'src/stores/match/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { GEM_LACK_TRIGGER, GemLackTrigger } from 'src/types/gemLack';
import { MatchFilter, MatchFilterOption, MatchFilterSelection } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { prioritizeElementInArray } from 'src/utils/array';
export const REGION_FILTER_ID = 'REGION_CHOICE';
export const COUNTRY_FILTER_ID = 'COUNTRY_GUARANTEE';
export const FREE_LOCAL_OPTION_KEY = 'LOCAL';
export const PAID_LOCAL_OPTION_KEY = 'LOCAL_PAID';
const regionFilterSelector = ({
  filterId
}: {
  filterId: string;
}) => filterId === REGION_FILTER_ID;
const countryFilterSelector = ({
  filterId
}: {
  filterId: string;
}) => filterId === COUNTRY_FILTER_ID;
const useLocationPreference = () => {
  const showModal = useSetAtom(showModalAtom);
  const matchFilters = useAtomValue(matchFiltersAtom);
  const matchFilterSelections = useAtomValue(matchFilterSelectionsAtom);
  const regionMatchFilter = matchFilters?.find(regionFilterSelector);
  const regionMatchFilterSelection = matchFilterSelections.find(regionFilterSelector);
  const regionMatchFilterOption = regionMatchFilter?.filterOptions.find(({
    key
  }) => key === regionMatchFilterSelection?.optionKey);
  const countryMatchFilter = matchFilters?.find(countryFilterSelector);
  const countryMatchFilterSelection = matchFilterSelections.find(countryFilterSelector);
  const countryMatchFilterOption = countryMatchFilter?.filterOptions.find(({
    key
  }) => key === countryMatchFilterSelection?.optionKey);
  const userCountryCode = useAtomValue(userCountryCodeAtom);
  const {
    tryApplyFilterOption
  } = useMatchPreference();
  const getLocationOptionChangedFilterSelections = useCallback((filterId: MatchFilter['filterId'], filterOption: MatchFilterOption, currentMatchFilterSelections: MatchFilterSelection[]) => {
    return currentMatchFilterSelections.filter(selection => {
      // 기존에 지역 관련에서 선택한 정보 모두 제거
      return ![REGION_FILTER_ID, COUNTRY_FILTER_ID].includes(selection.filterId);
    }).concat([{
      filterId,
      optionKey: filterOption.key,
      optionGemCost: filterOption.gemCost
    }]);
  }, []);
  const tryApplyLocationFilterOption = ({
    filterId,
    locationFilterOption,
    currentMatchFilterSelections,
    callbackIfApplicable
  }: {
    filterId: MatchFilter['filterId'];
    locationFilterOption: MatchFilterOption;
    currentMatchFilterSelections: MatchFilterSelection[];
    callbackIfApplicable: () => void;
  }) => {
    const showGemLackByGenderFilter = (lackingAmount: number, gemLackTriggers: GemLackTrigger[]) => {
      showModal({
        key: ModalType.GEM_LACK,
        component: () => <GemLackModal lackingGemAmount={lackingAmount}
        // 문구 상으로 성별 필터 관련 내용이 노출되도록 우선순위 부여
        gemLackTriggers={prioritizeElementInArray(gemLackTriggers, GEM_LACK_TRIGGER.locationFilter)} />
      });
    };
    const targetMatchFilterSelections = getLocationOptionChangedFilterSelections(filterId, locationFilterOption, currentMatchFilterSelections);
    tryApplyFilterOption({
      filterOption: locationFilterOption,
      targetMatchFilterSelections,
      callbackIfApplicable,
      callbackIfGemLack: showGemLackByGenderFilter
    });
  };
  const getFlagImageUrlByLocationOptionKey = (locationOptionKey: MatchFilterOption['key']) => {
    switch (locationOptionKey) {
      case 'DEFAULT':
        return '/images/icons/imgSystemMessageDefault.png';
      case 'GLOBAL':
        return '/images/match/icLocation.png';
      case 'LOCAL':
      case 'LOCAL_PAID':
        return `/images/flags/icFlag${userCountryCode}.png`;
      default:
        return `/images/flags/icFlag${locationOptionKey}.png`;
    }
  };
  return {
    regionMatchFilter,
    regionMatchFilterSelection,
    regionMatchFilterOption,
    countryMatchFilter,
    countryMatchFilterSelection,
    countryMatchFilterOption,
    locationFilterOption: regionMatchFilterOption || countryMatchFilterOption,
    tryApplyLocationFilterOption,
    getLocationOptionChangedFilterSelections,
    getFlagImageUrlByLocationOptionKey
  };
};
export default useLocationPreference;