import { FC } from 'react';
import { useAtomValue } from 'jotai';
import Image, { ImageProps } from 'next/image';
import { isAvifSupportedAtom, isWebpSupportedAtom } from 'src/stores/images/atoms';
import { optimizedImageLoader } from 'src/utils/imageUtils';
interface OptimizeImageProps extends Omit<ImageProps, 'src' | 'loader'> {
  src: string;
  basePath?: string;
  isOnlyLowResImage?: boolean;
}
const OptimizeImage: FC<OptimizeImageProps> = ({
  src,
  alt,
  width,
  height,
  sizes,
  isOnlyLowResImage = false,
  ...props
}) => {
  const isWebpSupported = useAtomValue(isWebpSupportedAtom);
  const isAvifSupported = useAtomValue(isAvifSupportedAtom);
  if (isWebpSupported === null || isAvifSupported === null) return null;
  return <Image loader={e => optimizedImageLoader({
    src,
    width: e.width,
    sizes,
    isOnlyLowResImage,
    isWebpSupported,
    isAvifSupported
  })} src={src} alt={alt} width={width} height={height} loading='lazy' {...props} data-sentry-element="Image" data-sentry-component="OptimizeImage" data-sentry-source-file="index.tsx" />;
};
export default OptimizeImage;