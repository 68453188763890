import { atom } from 'jotai';
import { v4 } from 'uuid';

import { ToastProps, ToastType } from 'src/types/Toast';
import { UnionOmit } from 'src/types/utils';

export const toastsAtom = atom<ToastProps[]>([]);

export const toastClearTimerIdAtom = atom<ReturnType<typeof setTimeout> | null>(null);

const MAX_TOAST_COUNT = 2;

export const showToastAtom = atom(null, (_get, set, props: UnionOmit<ToastProps, 'uuid'>) => {
  const uuid = v4();
  set(toastsAtom, (prev) => [{ ...props, uuid }, ...prev].slice(0, MAX_TOAST_COUNT));

  setTimeout(() => {
    set(toastsAtom, (prev) => prev.filter((toast) => toast.uuid !== uuid));
  }, props.duration || 3000);
});

export const showCommonErrorToastAtom = atom(null, (_get, set) => {
  set(showToastAtom, {
    type: ToastType.ERROR,
    message: 'common__problem_occurred',
  });
});
