import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { client } from 'src/utils/api';

export const useRestMutation = <TParams = void, TResponse = void, TError = void>({
  method,
  path,
  options,
}: {
  method: 'get' | 'post' | 'delete' | 'patch' | 'put';
  path: string;
  options?: UseMutationOptions<AxiosResponse<TResponse>, AxiosError<TError>, TParams>;
}) => {
  return useMutation<AxiosResponse<TResponse>, AxiosError<TError>, TParams>({
    mutationFn: (data: TParams) => client[method]<TResponse>(path, data),
    ...options,
  });
};
