import DeviceInfo from 'src/utils/device/info';

import { clearStream } from './stream';

export async function attachMediaElement(
  mediaView: HTMLMediaElement,
  stream: MediaStream,
  onCanPlay: HTMLMediaElement['oncanplay'] = null,
  onAutoPlayFailed?: () => void
) {
  mediaView.srcObject = stream;
  mediaView.oncanplay = onCanPlay;
  if (mediaView instanceof HTMLVideoElement) {
    mediaView.playsInline = true;
  }
  const trackCount = stream.getTracks().length;
  try {
    await mediaView.play();
  } catch (error) {
    if (trackCount < 1 || !onAutoPlayFailed || !mediaView.paused) return;
    if (error instanceof Error) {
      if (
        error.name === 'NotAllowedError' ||
        // 구버전 Safari에서는 NotAllowedError 대신, AbortError가 throw 된다
        (error.name === 'AbortError' && DeviceInfo().isWebKit)
      ) {
        onAutoPlayFailed?.();
        return;
      }
      // 이전 play() Promise가 resolve/reject 되기 전, 다시 play()가 불린 경우의 에러는 무시한다
      if (error.message.startsWith('The play() request was interrupted by a new load request.')) {
        return;
      }
    }
    throw error;
  }
}
export function detachMediaElement(mediaView: HTMLMediaElement) {
  if (typeof MediaStream !== 'undefined' && mediaView.srcObject instanceof MediaStream) {
    clearStream(mediaView.srcObject);
  }
  mediaView.srcObject = null;
}

export const captureVideoElement = (videoEl: HTMLVideoElement) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', { alpha: false });
  if (!ctx) {
    return;
  }
  const { height: videoHeight, width: videoWidth } = videoEl;
  const ratio = videoHeight / videoWidth;

  const height = 360;
  const width = height * ratio;

  canvas.width = width;
  canvas.height = height;

  ctx.save();
  ctx.translate(width / 2, height / 2);
  ctx.rotate((90 * Math.PI) / 180);
  ctx.scale(-1, 1);
  ctx.drawImage(videoEl, -width / 2, -height / 2, width, height);
  ctx.restore();

  const dataURL = canvas.toDataURL('image/png');
  return dataURL;
};
