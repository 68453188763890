export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONBINARY = 'NONBINARY',
}

export enum LoginType {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  SMS = 'SMS',
}

export interface RegisterParamsBase {
  birthYear: number;
  birthMonth: number;
  birthDay: number;
  gender: GENDER;
  // gender === NONBINARY이면 childGender는 필수
  childGender?: string;
  consents: Record<string, string>; // 회원가입을 위해 유저가 동의한 필수 약관
  legalNoticesConfirmed: Record<string, string>; // 회원가입을 위해 유저가 동의한 필수 법적 고지 (privacyPolicy, cookiePolicy)
}

export interface NonBinaryChildGender {
  key: string;
  localizedText: string;
}

export interface SignUpSettingsResponse {
  genderSettings: {
    nonBinaryChildGenders: Array<NonBinaryChildGender>;
    nonBinaryEnabled: boolean;
    hideGenderOptionEnabled: boolean;
    hideGenderDefaultValue: boolean;
  };
  birthdaySettings: {
    uiType: 'BIRTHDAY_WEB_B';
    blockAlertAge: number;
  };
}
