import * as Sentry from '@sentry/nextjs';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import axiosRetry from 'axios-retry';

import appConfig from 'app-config';

import { isUseKeepAliveAtom } from 'src/network/atoms';
import store from 'src/stores';
import {
  turnstileTokenAtom,
  turnstileWidgetSiteKeyAtom,
  userDataAtom,
} from 'src/stores/auth/atoms';
import { LoginWithPhoneThrowAble } from 'src/stores/phoneNumberLogin/types';
import { isAxiosError } from 'src/utils/error';

import { REST_API_HOST, RPC_API_HOST } from './endpoint';

export interface ErrorResponse<Data = unknown, Code = string> {
  error: {
    code: Code;
    data?: Data;
    message?: string;
  };
}

export interface ApiResponse<T> {
  code: number;
  result: T;
}

export interface Throwable {
  reason: string;
}

interface RPCError<E = Throwable> {
  code: number;
  data: {
    throwable: E;
    exceptionTypeName: string;
    message?: string;
  };
  message: string;
}

export interface ErrorAlertMessage {
  alertBody: string;
  alertTitle: string;
  alertType: string;
  reason: string;
  linkButtonTitle?: string;
  linkButtonUrl?: string;
}

export interface PunishedException {
  type: 'WARNING' | 'LOGIN' | 'MATCH';
  userId: string;
  guidelineUrl: string;
  title: string;
  description: string;
  suspensionContent: string;
  additionalDescription?: string;
  appealUrl?: string;
}

export type RPCThrowableError = ErrorAlertMessage | PunishedException | LoginWithPhoneThrowAble;

export interface RPCResponse<T, E> {
  result: T;
  error?: RPCError<E>;
  jsonrpc: '2.0';
  id: number;
}

const headers = {
  'Content-Type': 'application/json',
  ...appConfig.headers,
};

const timeout = 30_000;

export const getJsonRPCConfig = () => ({
  jsonrpc: '2.0',
  id: Math.ceil(Math.random() * 10_000_000),
});

const restApiConfig: AxiosRequestConfig = {
  baseURL: REST_API_HOST,
  headers,
  withCredentials: false,
  timeout,
};

const fetchApiConfig: RequestInit = {
  credentials: 'omit',
  /* FIXME: signal 을 활성화하면 E2E 테스트에서 확정적으로 타임아웃 발생 */
  // signal: AbortSignal.timeout(timeout),
  headers,
};

export const rpcAxios = axios.create({
  baseURL: RPC_API_HOST,
  headers,
  withCredentials: true,
  timeout,
});

export const client = axios.create(restApiConfig);
export const clientWithoutAuth = axios.create(restApiConfig);

export const setClientLocale = (locale?: string) => {
  client.locale = locale;
};

export const setDTID = (DTID?: string) => {
  if (DTID) {
    Sentry.setTag('DTID', DTID);
    rpcAxios.defaults.headers.common = {
      ...rpcAxios.defaults.headers.common,
      'X-Azar-DTID': DTID,
    };
    client.defaults.headers.common = {
      ...client.defaults.headers.common,
      'X-Azar-DTID': DTID,
    };
  }
};

axiosRetry(client, {
  retries: 3, // 재시도 횟수
  retryDelay: (retryCount) => {
    return retryCount * 1000; // 재시도 간격 계산 (단위: 밀리초)
  },
  retryCondition: (error) => {
    // 재시도 조건을 정의 (HTTP 상태 코드 등)
    // CORS 오류는 통상적으로 status code가 나타나지 않기 때문에, error 요청 자체를 검토합니다.
    const ret =
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error' ||
      (!!error.response && error.response.status >= 500);
    if (ret) {
      console.error('server error', error, error.response);
    }
    return ret;
  },
});

client.interceptors.request.use((req) => {
  const userData = store.get(userDataAtom);
  const turnstileToken = store.get(turnstileTokenAtom);
  req.headers = {
    ...req.headers,
    ...(userData?.accessToken && {
      Authorization: `Bearer ${userData?.accessToken}`,
    }),
    ...(turnstileToken && { 'X-Azar-TR': turnstileToken }),
  };
  return req;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!isAxiosError<ErrorResponse<RPCThrowableError>>(error)) Promise.reject(error);
    if (error.response?.data?.error?.code === 'TURNSTILE_REQUIRED') {
      store.set(turnstileWidgetSiteKeyAtom, error.response.headers['x-azar-tk']);
    }
    return Promise.reject(error);
  }
);

export function createApiCall<TParams = void, TResponse = void>(
  method: Method,
  url: string,
  instance: AxiosInstance = client
) {
  return (params: TParams | null = null): AxiosPromise<ApiResponse<TResponse>> => {
    const config: AxiosRequestConfig = {
      method,
      url,
    };

    // GET 요청인 경우 params(=URL), POST 요청인 경우 body에 파라미터 추가
    if (method.toLowerCase() === 'get') {
      config.params = params;
    } else {
      config.data = params;
    }

    return instance(config);
  };
}

/**
 * 페이지 밖에서 생존 가능한 요청 날리기 위해 사용 (beforeunload event와 함께 사용 가능)
 * 주의) keepalive 옵션은 post와만 사용 가능
 */
export function createBeaconApiCall<TParams = void>(url: string) {
  return async (
    params: TParams,
    { headers: customHeaders }: Pick<RequestInit, 'headers'> = {}
  ): Promise<Response> => {
    const userData = store.get(userDataAtom);

    // axios는 페이지 밖에서 생존가능한 요청 날리는 keepalive 옵션 미지원 -> fetch로 대체
    const response = await fetch(`${REST_API_HOST}${url}`, {
      method: 'post',
      keepalive: store.get(isUseKeepAliveAtom),
      ...fetchApiConfig,
      ...(params && { body: JSON.stringify(params) }),
      headers: {
        ...fetchApiConfig.headers,
        ...customHeaders,
        ...(userData?.accessToken && {
          Authorization: `Bearer ${userData?.accessToken}`,
        }),
      },
    });

    return response;
  };
}

export const rpcClient =
  <Req = Record<string, unknown>, Res = Record<string, unknown>, E = Record<string, unknown>>(
    method: string,
    option?: AxiosRequestConfig
  ) =>
  (params?: Req) =>
    rpcAxios.post<RPCResponse<Res, E>>(
      '/json',
      {
        ...getJsonRPCConfig(),
        method,
        //params가 배열이면 배열로 넘기고, 아니면 객체로 넘김
        params: Array.isArray(params) ? params : params ? [{ ...params }] : undefined,
      },
      option
    );
