import { FC } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import ResizingText from 'src/components/ResizingText';
import useEvent from 'src/hooks/useEvent';
import useSelectedTab from 'src/hooks/useSelectedTab';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { showPaymentPopupAtom } from 'src/stores/shop/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { GemLackTrigger, GemProductModule } from 'src/types/gemLack';
import { ModalType } from 'src/types/Modal';
import { hexToRgb } from 'src/utils/common';
import { getProductPriceTextWithCurrency } from 'src/utils/payments';
import { hoverAndActiveCss } from 'src/utils/styled';
const InfoContainer = styled.div`
  height: 103px;
  display: flex;
  padding: 12px 12px 16px 12px;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  justify-content: center;
`;
const GemAmount = styled(ResizingText)`
  margin-top: 8px;
  margin-bottom: 12px;
  color: ${({
  theme
}) => theme.color.content12};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;
const GemPriceContainer = styled.div`
  padding: 10px 14px;
  border-radius: 20px;
  font-size: 13px;
  color: ${({
  theme
}) => theme.color.content12};
  background-color: ${({
  theme
}) => theme.color.surface5};
  margin-left: auto;
  margin-right: auto;
  line-height: 16px;
`;
const GemLackCardContainer = styled.button`
  flex: 1;
  min-width: 0;
  height: 183px;
  box-shadow: 0px 2px 20px 0px rgba(${({
  theme
}) => hexToRgb(theme.color.surfaceDeepFixed)}, 0.25);
  border-radius: 16px;
  background-color: ${({
  theme
}) => theme.color.surface4};
  ${({
  theme
}) => hoverAndActiveCss(`
      ${InfoContainer} {
        background-color: ${theme.color.surface2};
        ${GemPriceContainer} {
          background-color: ${theme.color.surface4};
        }
      }
  `)}
  cursor: pointer;
  position: relative;
`;
const ImageContainer = styled.div<{
  backgroundColor: string;
}>`
  height: 80px;
  overflow: hidden;
  position: relative;
  background-color: ${({
  backgroundColor
}) => backgroundColor};
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: center;
`;
const GemImage = styled.img`
  object-fit: contain;
  position: relative;
`;
const GemVideoBackground = styled.video`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 16px 16px 0 0;
`;
const SaveLabel = styled.div`
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  background-color: ${({
  theme
}) => theme.color.accentA1};
  font-size: 12px;
  font-weight: 700;
  color: ${({
  theme
}) => theme.color.contentDeep};
`;
interface Props {
  gemProductModule: GemProductModule;
  savePercent?: number;
  isMoreExpensive?: boolean;
  gemLackTriggers: GemLackTrigger[];
}
const NormalGemProduct: FC<Props> = ({
  gemProductModule,
  gemLackTriggers,
  isMoreExpensive = false,
  savePercent
}) => {
  const {
    t
  } = useTranslation();
  const showPaymentPopup = useSetAtom(showPaymentPopupAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();
  const {
    selectedTab
  } = useSelectedTab();
  const handleClickGemProduct = () => {
    pushEvent({
      eventName: EVENT_NAME.GEM_LACK_POPUP__ITEM_CLICK,
      eventType: EVENT_TYPE.SHOP,
      eventParams: {
        isMoreExpensive,
        productId: gemProductModule.productId,
        productName: gemProductModule.productName
      }
    });
    showPaymentPopup({
      product: gemProductModule,
      origins: gemLackTriggers,
      originTab: selectedTab.name
    });
    closeModal(ModalType.GEM_LACK);
  };
  const isShowSaveLabel = !!savePercent && savePercent >= 4;
  return <GemLackCardContainer onClick={handleClickGemProduct} data-sentry-element="GemLackCardContainer" data-sentry-component="NormalGemProduct" data-sentry-source-file="NormalGemProduct.tsx">
      <ImageContainer backgroundColor={gemProductModule.content.backgroundColor} data-sentry-element="ImageContainer" data-sentry-source-file="NormalGemProduct.tsx">
        {isMoreExpensive && <GemVideoBackground autoPlay muted loop playsInline>
            <source src='/videos/shop/gem-lack-background.webp' type='video/webp' />
            <source src='/videos/shop/gem-lack-background.mp4' type='video/mp4' />
            {t('VIDEO_NOT_SUPPORTED')}
          </GemVideoBackground>}
        <GemImage src={gemProductModule.iconUrl} alt={gemProductModule.productName + 'gems'} data-sentry-element="GemImage" data-sentry-source-file="NormalGemProduct.tsx" />
      </ImageContainer>
      {isShowSaveLabel && <SaveLabel>{t('COMMON_SAVED_PERCENTAGE', {
        percent: savePercent
      })}</SaveLabel>}
      <InfoContainer data-sentry-element="InfoContainer" data-sentry-source-file="NormalGemProduct.tsx">
        <GemAmount minFontSize={13} data-sentry-element="GemAmount" data-sentry-source-file="NormalGemProduct.tsx">{gemProductModule.productName}</GemAmount>
        <GemPriceContainer data-sentry-element="GemPriceContainer" data-sentry-source-file="NormalGemProduct.tsx">
          <ResizingText minFontSize={10} data-sentry-element="ResizingText" data-sentry-source-file="NormalGemProduct.tsx">
            {getProductPriceTextWithCurrency(gemProductModule)}
          </ResizingText>
        </GemPriceContainer>
      </InfoContainer>
    </GemLackCardContainer>;
};
export default NormalGemProduct;