import { useCallback } from 'react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import Gap from 'src/components/Gap';
import GemImage from 'src/components/GemImage';
import ShopModal from 'src/components/Shop/ShopModal';
import useGemAmount from 'src/hooks/useGemAmount';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { numComma } from 'src/utils/common';
const IcChevronRight = styled(dynamic(() => import('src/assets/images/icChevronRight.svg')))`
  ${({
  theme
}) => theme.isRtl && 'transform: scaleX(-1);'}
  fill: ${({
  theme
}) => theme.color.content12};
  width: 16px;
  height: 16px;
`;
const GemAmount = styled.div`
  color: ${({
  theme
}) => theme.color.content12};
  font-size: 14px;
  display: flex;
  align-items: center;
`;
const ShopLabel = styled.div`
  display: flex;
  margin-top: 1px;
  font-size: 14px;
  color: ${({
  theme
}) => theme.color.content12};
`;
const Container = styled.button`
  padding: 16px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: flex;
  }
  :active {
    ${GemAmount} {
      color: ${({
  theme
}) => theme.color.content10};
    }
    ${ShopLabel} {
      color: ${({
  theme
}) => theme.color.content10};
      ${IcChevronRight} {
        fill: ${({
  theme
}) => theme.color.content10};
      }
    }
  }
`;
const ImgGemSquare = styled(GemImage)`
  width: 20px;
  height: 20px;
`;
const GemLackMobileFooter = () => {
  const gemAmount = useGemAmount();
  const {
    t
  } = useTranslation();
  const closeModal = useSetAtom(closeModalAtom);
  const showModal = useSetAtom(showModalAtom);
  const onClick = useCallback(() => {
    closeModal(ModalType.GEM_LACK);
    showModal({
      key: ModalType.SHOP,
      component: ShopModal
    });
  }, [closeModal, showModal]);
  return <Container onClick={onClick} data-sentry-element="Container" data-sentry-component="GemLackMobileFooter" data-sentry-source-file="GemLackMobileFooter.tsx">
      <GemAmount data-sentry-element="GemAmount" data-sentry-source-file="GemLackMobileFooter.tsx">
        <ImgGemSquare data-sentry-element="ImgGemSquare" data-sentry-source-file="GemLackMobileFooter.tsx" />
        <Gap width={5} data-sentry-element="Gap" data-sentry-source-file="GemLackMobileFooter.tsx" />
        {gemAmount && numComma(gemAmount)}
      </GemAmount>
      <ShopLabel data-sentry-element="ShopLabel" data-sentry-source-file="GemLackMobileFooter.tsx">
        {t('shop_title')}
        <IcChevronRight data-sentry-element="IcChevronRight" data-sentry-source-file="GemLackMobileFooter.tsx" />
      </ShopLabel>
    </Container>;
};
export default GemLackMobileFooter;