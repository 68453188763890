import styled from '@emotion/styled';

import { ToastBounce, ToastFadeInOut } from 'src/styles/animation';

export const ToastRootStyle = styled.div<{
  durationMs?: number;
  index: number;
}>`
  --top: 0px; // 토스트의 최대 상단 위치
  --gap: 0px; // 토스트 간 y축 간격
  --y: 0px; // 토스트 컨테이너 내 해당 토스트의 상대적 y값 (DOM 기준 런타임 할당)

  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  top: calc(var(--top) + var(--y) + var(--gap) * ${({ index }) => index});
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s;
  animation:
    ${ToastBounce} 0.3s forwards,
    ${ToastFadeInOut} ${({ durationMs }) => durationMs || 3000}ms ease-in forwards;
  border-radius: 8px;
  z-index: 61;
`;
