import { IceServerManager } from './ice-server-manager';

const EMPTY_ICE_CANDIDATE_TIMEOUT = 5000;

/**
 * ice candidate를 정상적으로 받아오는지 확인하는 클래스
 * ice candidate목록을 확인하고, 정상적인 candidate가 없으면 ice server manager에게 알림
 */
export class IceCandidateChecker {
  private srflxCandidateCreated = false;

  private relayCandidateCreated = false;

  private timerId: NodeJS.Timeout | null = null;

  private iceServerManager = IceServerManager.getInstance();

  constructor(private peerConnection: RTCPeerConnection) {
    peerConnection.addEventListener('icecandidate', this.handleIceCandidate);
  }

  private handleIceCandidate = (event: RTCPeerConnectionIceEvent) => {
    if (!event.candidate) {
      return;
    }
    const candidate = event.candidate;
    // candidate 타입에 따른 처리
    if (candidate.type === 'srflx') {
      this.srflxCandidateCreated = true;
    } else if (candidate.type === 'relay') {
      this.relayCandidateCreated = true;
    }

    // 모든 필요한 candidate가 생성되었는지 확인
    if (this.srflxCandidateCreated && this.relayCandidateCreated) {
      this.cancelEmptyIceCandidateTimer();
    }
  };

  private startEmptyIceCandidateTimer() {
    this.cancelEmptyIceCandidateTimer();
    this.timerId = setTimeout(() => {
      if (!this.relayCandidateCreated || !this.srflxCandidateCreated) {
        const errorMessage = `No ${this.relayCandidateCreated ? 'Relay' : ''} ${this.srflxCandidateCreated ? 'Srflx' : ''} CandidateAfter5Seconds`;
        this.iceServerManager.handleEmptyIceCandidateError();
        this.iceServerManager.reportConnectionFail({
          errorMessage,
          generalConnectionFailType: 'TURN',
          generalConnectionAddress: this.peerConnection
            .getConfiguration()
            ?.iceServers?.flatMap(({ urls }) => urls),
        });
      }
    }, EMPTY_ICE_CANDIDATE_TIMEOUT);
  }

  private cancelEmptyIceCandidateTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  public start() {
    this.startEmptyIceCandidateTimer();
  }

  public dispose() {
    this.cancelEmptyIceCandidateTimer();
    this.peerConnection.removeEventListener('icecandidate', this.handleIceCandidate);
  }
}
