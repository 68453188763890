import React, { FC, RefObject, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Gap from 'src/components/Gap';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { CursorOpacity } from 'src/styles/animation';
const VerifyCodeRowStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
const CodeInputStyle = styled.input`
  opacity: 0;
  position: absolute;
  height: 0px;
  caret-color: transparent;
`;
const VerityCodeInputStyle = styled.div<{
  filled: boolean;
  isFocus: boolean;
  isError: boolean;
}>`
  flex-basis: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({
  theme,
  filled
}) => filled ? theme.color.surface4 : 'transparent'};
  outline: none;
  border: 1px solid
    ${({
  theme,
  isFocus,
  isError
}) => {
  if (isError) {
    return theme.color.accentA1;
  }
  if (isFocus) {
    return theme.color.accentH1;
  }
  return theme.color.surface8;
}};
  text-align: center;
  font-size: 28px;
  color: ${({
  theme
}) => theme.color.accentH1};

  border-radius: 4px;
`;
const Cursor = styled.div`
  position: absolute;
  width: 2px;
  height: 32px;
  ${({
  theme
}) => theme.isRtl ? 'right' : 'left'}: 8px;
  top: 8px;

  background: ${({
  theme
}) => theme.color.surfaceDeep};
  border-radius: 8px;
  animation: ${CursorOpacity} 1s ease-in-out infinite;
`;
interface IVerifyCodeInputProps {
  code: string;
  onChange: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  inputRef: RefObject<HTMLInputElement>;
  numOfArray: number;
  style?: React.CSSProperties;
  isError: boolean;
  isEditable?: boolean;
  className?: HTMLDivElement['className'];
}
const VerifyCodeInput: FC<IVerifyCodeInputProps> = ({
  inputRef,
  code,
  onChange,
  onFocus,
  numOfArray,
  style,
  isError,
  isEditable = true,
  className
}) => {
  const device = useCssMediaDevice();
  const [isFocus, setIsFocus] = useState(false);
  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    }
  }, [isFocus, inputRef]);
  const handleClickRow = () => {
    inputRef.current?.focus();
  };
  return <VerifyCodeRowStyle onClick={handleClickRow} role='button' tabIndex={0} data-testid='VerifyCodeInput' data-sentry-element="VerifyCodeRowStyle" data-sentry-component="VerifyCodeInput" data-sentry-source-file="index.tsx">
      {
    // eslint-disable-next-line jsx-a11y/no-autofocus, styled-components-a11y/no-autofocus
    <CodeInputStyle value={code} onChange={e => onChange(e.target.value)} autoFocus={device !== 'mobile'} onFocus={e => {
      setIsFocus(true);
      onFocus?.(e);
    }} onBlur={() => {
      setIsFocus(false);
    }} ref={inputRef} type='number' pattern='\d*' maxLength={numOfArray}
    // disabled 설정시 focus가 풀려버리므로 readOnly 속성으로 수정만 방지
    readOnly={!isEditable} />}
      {Array.from(Array(numOfArray)).map((_, i) => <>
          <VerityCodeInputStyle style={style} key={`code-${i}`} filled={!!code[i] && isFocus} isFocus={isFocus && i === code.length} isError={isError} className={className}>
            {isFocus && i === code.length && <Cursor />}
            {code[i]}
          </VerityCodeInputStyle>
          {i < numOfArray - 1 && <Gap width={device === 'mobile' ? 8 : 12} />}
        </>)}
    </VerifyCodeRowStyle>;
};
export default React.memo(VerifyCodeInput);