import { Platform } from 'src/types/Platform';
import getDeviceId from 'src/utils/device/id';
import DeviceInfo from 'src/utils/device/info';
import { getServerSideLanguageCode } from 'src/utils/language';

export function getLoginParam() {
  const deviceInfo = DeviceInfo();
  const model = `${deviceInfo.browserName}/${deviceInfo.browserVersion}/${deviceInfo.os}`;
  const flavor = deviceInfo.isMobile ? 'mobile' : 'desktop';
  return {
    localeInfo: {
      languageCode: getServerSideLanguageCode(),
      countryCode: null,
    },
    deviceId: getDeviceId(),
    timeZoneId: Intl?.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
    versionCode: 9999,
    clientProperties: {
      platform: Platform.WebClient,
      apiLevel: 9999,
      model,
      flavor,
    },
    clientSideUserSettings: {
      browserLanguages: navigator.languages,
    },
  };
}
