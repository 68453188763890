import { useEffect, useState } from 'react';

import { useSetAtom } from 'jotai';

import { eventMutateAtom } from 'src/stores/event/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';

export const useOnline = () => {
  const [isOnline, setIsOnline] = useState(false);
  const setEventMutate = useSetAtom(eventMutateAtom);

  useEffect(() => {
    setIsOnline(window.navigator.onLine);
    setEventMutate({
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: { debug: 'window.navigator.onLine', isOnline: window.navigator.onLine },
    });
  }, [setEventMutate]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setEventMutate({
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'window.ononline' },
      });
    };
    const handleOffline = () => {
      setIsOnline(false);
      setEventMutate({
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'window.onoffline' },
      });
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [setEventMutate]);

  return { isOnline };
};
