import { useEffect, useMemo } from 'react';

import useRemainingTimer from 'src/hooks/useRemainingTimer';
import { ProductModule } from 'src/types/gemLack';

const useProductWithPurchaseLimit = (productModule: ProductModule) => {
  const {
    remainingTime,
    isTimerOver: isPurchasableTimeOver,
    start,
  } = useRemainingTimer(
    productModule.moduleEndDate ? new Date(productModule.moduleEndDate) : new Date() // 구매 기간 제한 없으면 더미 Date 전달
  );

  const isPurchasable = useMemo(() => {
    return !isPurchasableTimeOver && !productModule.purchaseLimitExceeded;
  }, [isPurchasableTimeOver, productModule.purchaseLimitExceeded]);

  useEffect(() => {
    if (productModule.moduleEndDate && isPurchasable) {
      start();
    }
  }, [productModule.moduleEndDate, start, isPurchasable]);

  return {
    remainingTime: productModule.moduleEndDate ? remainingTime : undefined,
    isPurchasable,
  };
};

export default useProductWithPurchaseLimit;
