import { atom } from 'jotai';
import { StaticRoute } from 'nextjs-routes';
import QueryString from 'qs';

import { PAYMENT_POPUP_INFO_KEY, paymentPopupInfoAtom } from 'src/stores/payments/atoms';
import { UTMParamsAtom } from 'src/stores/utm/atoms';
import { PaymentPopupInfo } from 'src/types/Payments';

export const paymentPath = atom((get) => {
  const UTMParams = get(UTMParamsAtom);
  const UTMParamsStringified = QueryString.stringify(UTMParams, {
    addQueryPrefix: true,
  });
  const url = '/payment/' + UTMParamsStringified;
  return url as StaticRoute<'/payment'>['pathname'];
});

const paymentPopupAtom = atom<WindowProxy | null>(null);

export const showPaymentPopupAtom = atom(null, async (get, set, item: PaymentPopupInfo) => {
  set(paymentPopupInfoAtom, item);
  const openedPopup = get(paymentPopupAtom);

  if (openedPopup) {
    try {
      openedPopup.sessionStorage?.setItem(PAYMENT_POPUP_INFO_KEY, JSON.stringify(item));
    } catch {
      // Stripe 등의 결제 수단은 외부모듈 제공 결제링크로 이동하기 때문에 세션스토리지 접근 불가
    }
  }

  const url = get(paymentPath);

  const popupWidth = 650;
  const popupHeight = 650;
  const screenLeft = window.screenLeft;
  const screenTop = window.screenTop;

  const left = (screenLeft >= 0 ? 1 : -1) * (screen.width / 2) - popupWidth / 2;
  const top = (screenTop >= 0 ? 1 : -1) * (screen.height / 2) - popupHeight / 2;

  const popup = window.open(
    url,
    'popup',
    `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
  );
  set(paymentPopupAtom, popup);
});

export const closePaymentPopupAtom = atom(null, (get, set) => {
  const paymentPopup = get(paymentPopupAtom);
  if (paymentPopup && !paymentPopup.closed) {
    paymentPopup.close();
  }
  set(paymentPopupAtom, null);
});

/**
 * 스타터패키지 넛징용 UI가 노출되었는지 여부 (과도한 넛징 노출 방지)
 */
export const isStarterPackageBannerShownAtom = atom(false);
