import { FC } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import Turnstile from 'react-turnstile';
import { turnstileWidgetOnSuccessAtom, turnstileWidgetSiteKeyAtom } from 'src/stores/auth/atoms';
interface Props {
  callback: () => void | Promise<void>;
}
const TurnstileWidget: FC<Props> = ({
  callback
}) => {
  const turnstileWidgetSiteKey = useAtomValue(turnstileWidgetSiteKeyAtom);
  const turnstileWidgetOnSuccess = useSetAtom(turnstileWidgetOnSuccessAtom);
  if (!turnstileWidgetSiteKey) return null;
  return <Turnstile sitekey={turnstileWidgetSiteKey} onVerify={async token => turnstileWidgetOnSuccess({
    token,
    callback
  })} data-sentry-element="Turnstile" data-sentry-component="TurnstileWidget" data-sentry-source-file="index.tsx" />;
};
export default TurnstileWidget;