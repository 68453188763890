import { useAtom, useSetAtom } from 'jotai';

import useEvent from 'src/hooks/useEvent';
import { useRestMutation } from 'src/hooks/useRestMutation';
import { turnstileWidgetSiteKeyAtom } from 'src/stores/auth/atoms';
import { PhoneNumberLoginApiList } from 'src/stores/phoneNumberLogin/apis';
import { smsPhoneNumberAtom, smsVerificationAtom } from 'src/stores/phoneNumberLogin/atoms';
import {
  SendVerifyCodeToUserRequest,
  SendVerifyCodeToUserResponse,
} from 'src/stores/phoneNumberLogin/types';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { Platform } from 'src/types/Platform';
import { ApiResponse, ErrorAlertMessage, ErrorResponse } from 'src/utils/api';
import { isAxiosError } from 'src/utils/error';
import { getServerSideLanguageCode } from 'src/utils/language';

export default function useSendSmsVerificationCode() {
  const [smsPhoneNumber, setSmsPhoneNumber] = useAtom(smsPhoneNumberAtom);
  const { verifyPreparation, phone } = smsPhoneNumber;
  const pushEvent = useEvent();
  const [smsVerification, setSmsVerification] = useAtom(smsVerificationAtom);

  const setTurnstileWidgetSiteKey = useSetAtom(turnstileWidgetSiteKeyAtom);

  const { mutateAsync, isLoading, isSuccess } = useRestMutation<
    SendVerifyCodeToUserRequest,
    ApiResponse<SendVerifyCodeToUserResponse>
  >({
    method: 'post',
    path: PhoneNumberLoginApiList.VerificationsSend,
    options: {
      onSuccess: (response) => {
        setSmsVerification({
          ...smsVerification,
          verifyCode: '',
          errorMessage: '',
          formattedPhoneNumber: response.data.result.formattedPhoneNumber,
        });
        pushEvent({
          eventType: EVENT_TYPE.SIGN_UP,
          eventName: EVENT_NAME.VERIFY_CLICK_GET_VERIFICATION_CODE,
        });
      },
      onError: (error) => {
        if (!isAxiosError<ErrorResponse<ErrorAlertMessage>>(error)) return;
        if (error.response?.data.error.code === 'TURNSTILE_REQUIRED') {
          const turnstileWidgetSiteKey = error.response.headers['x-azar-tk'];
          setTurnstileWidgetSiteKey(turnstileWidgetSiteKey);
          return;
        }
        setSmsPhoneNumber({
          ...smsPhoneNumber,
          errorMessage: error.response?.data.error?.data?.alertBody,
        });
      },
    },
  });

  const send = async () => {
    if (!verifyPreparation.countryPrefixNumber) {
      setSmsPhoneNumber({
        ...smsPhoneNumber,
        errorMessage: 'SIGNUP_PHONE_COUNTRY_CODE_REQUIRED',
      });
      return;
    }
    await mutateAsync({
      phoneNumber: phone,
      localeLanguageCode: getServerSideLanguageCode(),
      countryPrefixNumber: verifyPreparation.countryPrefixNumber,
      platform: Platform.WebClient,
    });
  };

  return {
    send,
    isLoading,
    isSuccess,
  };
}
