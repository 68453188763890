import { PurchaseType } from '@hyperconnect/azar-jsapi';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import getSymbolFromCurrency from 'currency-symbol-map';
import { i18n } from 'next-i18next';

import { GemIncludedProductModule, GemLackPopupModuleType } from 'src/types/gemLack';
import { GemProduct, PurchasableProduct } from 'src/types/Payments';

import { numComma } from './common';

const clientKey = process.env.NEXT_PUBLIC_TOSS_CLIENT_KEY || '';

export const initPayments = () => loadTossPayments(clientKey);

/**
 * 젬 관련 텍스트에서 서로 다른 젬 유형을 구분하기 위해 사용
 */
export const GEM_TEXT_SEPARATOR = '+';

export interface IProductCalcGem {
  num: number;
  base: number;
  bonus: number;
  promo: number;
  text: string;
  baseText: string;
  bonusText: string;
  promoText: string;
}

export const getProductCalcGem = (product: Partial<GemProduct> | undefined): IProductCalcGem => {
  if (!product) {
    return {
      num: 0,
      base: 0,
      bonus: 0,
      promo: 0,
      text: '',
      baseText: '',
      bonusText: '',
      promoText: '',
    };
  }
  const {
    baseGemAmount = 0,
    bonusGemAmount = 0,
    membershipGemAmount = 0,
    promoGemAmount = 0,
  } = product;

  const base = baseGemAmount;
  const bonus = membershipGemAmount;
  const promo = promoGemAmount + bonusGemAmount;
  const bonusTotal = bonus + promo;

  return {
    num: base + bonusTotal,
    text: `${numComma(base)}${bonusTotal ? ` ${GEM_TEXT_SEPARATOR} ${numComma(bonusTotal)}` : ''}`,
    base,
    baseText: numComma(base),
    bonus,
    bonusText: numComma(bonus),
    promo,
    promoText: numComma(promo),
  };
};

export const getProductCurrency = (product: PurchasableProduct) => {
  if ('moduleId' in product) {
    if (!product.pgPrice) {
      /**
       * 서버 정의상 pgPrice는 Nullable 필드이지만 웹에서는 PG 가격 정보 필드가 항상 떨어져야 함
       * 만약 필드가 비어있는 경우 에러 케이스로 간주
       */
      throw new Error('Product module should have pgPrice in web environment');
    }
    return product.pgPrice.currency;
  }
  return product.currency;
};

export const getProductPrice = (product: PurchasableProduct) => {
  if ('moduleId' in product) {
    if (!product.pgPrice) {
      /**
       * 서버 정의상 pgPrice는 Nullable 필드이지만 웹에서는 PG 가격 정보 필드가 항상 떨어져야 함
       * 만약 필드가 비어있는 경우 에러 케이스로 간주
       */
      throw new Error('Product module should have pgPrice in web environment');
    }
    return product.pgPrice.price;
  }
  return product.price;
};

/**
 * 두 상품을 젬 1개당 가격을 비교하여 상대적 절약율을 획득
 * (비교하는 두 상품은 화폐 단위가 동일하다고 가정)
 * @param targetProduct 상대적 절약율을 획득하려는 상품 대상
 * @param productToCompareWith 비교 상품
 */
export const getSaveRateByGemProductComparison = (
  targetProduct: GemIncludedProductModule,
  productToCompareWith: GemIncludedProductModule
) => {
  // 각 상품의 1젬당 가격
  const targetProductGemPrice =
    getProductPrice(targetProduct) / getProductCalcGem(targetProduct).num;
  const compareProductGemPrice =
    getProductPrice(productToCompareWith) / getProductCalcGem(productToCompareWith).num;

  return Math.floor((1 - targetProductGemPrice / compareProductGemPrice) * 100);
};

export const getProductPriceTextWithCurrency = (product: PurchasableProduct) => {
  const currency = getProductCurrency(product);
  const price = getProductPrice(product);
  return `${getSymbolFromCurrency(currency)}${numComma(price)}`;
};

export const getGemProductName = (product: Partial<GemProduct> | undefined) =>
  `${getProductCalcGem(product).text} ${i18n?.t('common__gems')}`;

/**
 * UI상으로 노출할 상품명을 추출 (상품 종류에 따라 다른 로직 적용)
 */
export const getProductName = (product: PurchasableProduct) => {
  // 일반 젬상품이 아닌 경우
  if ('moduleId' in product && product.moduleType !== GemLackPopupModuleType.GEM_PRODUCT) {
    return product.productName;
  }

  return getGemProductName(product);
};

export const getPromotion = (products: GemProduct[] | undefined) => {
  if (!products) {
    return { isPromotion: false, promoPercent: 0, promoTotalPercent: 0 };
  }

  const mostPromotionProduct = products.reduce(
    (acc, cur) => {
      const { base, bonus, promo } = getProductCalcGem(cur);

      const promoPercent = Math.floor((promo / base) * 100);
      const totalPercent = Math.floor(((promo + bonus) / base) * 100);

      return {
        promoPercent: Math.max(acc.promoPercent, promoPercent),
        totalPercent: Math.max(acc.totalPercent, totalPercent),
      };
    },
    {
      promoPercent: 0,
      totalPercent: 0,
    }
  );

  return {
    isPromotion: mostPromotionProduct.promoPercent > 0,
    promoPercent: mostPromotionProduct.promoPercent,
    promoTotalPercent: mostPromotionProduct.totalPercent,
  };
};

export const startPurchase = (productId: string, type?: PurchaseType) => {
  const platform = window.AzarJs?.getPlatform();
  if (platform) {
    if (platform === 'ANDROID') {
      window.AzarJs?.startPurchaseV2?.(productId, type || 'inapp');
    } else {
      window.AzarJs?.startPurchase(productId);
    }
  }
};

export default getGemProductName;
